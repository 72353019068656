import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header.jsx";

const Alert = ({ message, onClose }) => (
  <div className="fixed inset-0  flex items-start justify-center z-[9999999] top-20 alert-animation group">
    <div class="bg-red-100 dark:bg-red-900 border-l-4 border-red-500 text-red-900 p-2 rounded-r-lg flex items-center transition duration-300 ease-in-out hover:bg-red-200 transform  scale-100 hover:scale-105 justify-between">
      <div className="flex items-center justify-start">
        <svg
          height="24"
          viewBox="0 0 32 32"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
          id="fi_3756712"
        >
          <g id="_48_Warning" data-name="48 Warning">
            <path
              d="m29.764 23.453-11.188-17.933a3.027 3.027 0 0 0 -5.146-.009l-11.206 17.963a3.027 3.027 0 0 0 2.583 4.526h22.386a3.028 3.028 0 0 0 2.571-4.547z"
              fill="#FF4F4F"
            ></path>
          </g>
          <g id="Layer_52" data-name="Layer 52">
            <path d="m27.193 28h-22.386a3.027 3.027 0 0 1 -2.583-4.526l11.206-17.963a3.027 3.027 0 0 1 5.146.009l11.188 17.933a3.028 3.028 0 0 1 -2.571 4.547zm-12.054-21.451-11.205 17.963a1.008 1.008 0 0 0 .873 1.488h22.386a1.009 1.009 0 0 0 .861-1.509s-11.188-17.936-11.193-17.942a1.02 1.02 0 0 0 -1.722 0z"></path>
            <path d="m15.087 18.624-.266-3.976c-.052-.8-.325-2.093.286-2.743.465-.5 1.566-.586 1.9.107a4.873 4.873 0 0 1 .182 2.536l-.356 4.093a3.221 3.221 0 0 1 -.249 1.12.708.708 0 0 1 -1.254.013 3.763 3.763 0 0 1 -.243-1.15zm.921 5.463a1.24 1.24 0 0 1 -.142-2.471 1.24 1.24 0 1 1 .142 2.471z"></path>
          </g>
        </svg>
        <p class="pl-2 text-xs font-semibold">{message}</p>
      </div>
      <button onClick={onClose} className="flex items-center ml-3">
        <svg
          className="fill-gray-900 hover:fill-red-500 scale-100 hover:scale-90 duration-200 ease-in-out"
          height="20"
          viewBox="0 0 32 32"
          width="20"
          xmlns="http://www.w3.org/2000/svg"
          id="fi_2734822"
        >
          <g id="Layer_22" data-name="Layer 22">
            <path d="m21 12.46-3.59 3.54 3.59 3.54a1 1 0 0 1 0 1.46 1 1 0 0 1 -.71.29 1 1 0 0 1 -.7-.29l-3.59-3.59-3.54 3.59a1 1 0 0 1 -.7.29 1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l3.54-3.59-3.54-3.54a1 1 0 0 1 1.41-1.41l3.54 3.54 3.54-3.54a1 1 0 0 1 1.46 1.41zm4.9 13.44a14 14 0 1 1 0-19.8 14 14 0 0 1 0 19.8zm-1.41-18.39a12 12 0 1 0 0 17 12 12 0 0 0 0-17z"></path>
          </g>
        </svg>
      </button>
    </div>
  </div>
);

const DynamicForm = () => {
  const [isIndian, setIsIndian] = useState(true); // State to track if user is Indian
  const [gstin, setGSTIN] = useState("");
  const [cin, setCIN] = useState(""); // State for CIN input
  const [certificate, setCertificate] = useState(""); // State for Certificate input
  const [IEC, setIEC] = useState(""); // State for Certificate input
  const [companyInfo, setCompanyInfo] = useState(null); // Pre-filled data
  const [cinInfo, setCINInfo] = useState(null); // Pre-filled data
  const [certificateInfo, setCertificateInfo] = useState(null); // Pre-filled data
  const [IECInfo, setIECInfo] = useState(null); // Pre-filled data
  const [isModalOpen, setIsModalOpen] = useState(true); // State for modal visibility
  const [showAlert1, setShowAlert1] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const [showAlert3, setShowAlert3] = useState(false);
  const [showAlert4, setShowAlert4] = useState(false);
  const [showAlert5, setShowAlert5] = useState(false);
  const [showAlert6, setShowAlert6] = useState(false);
  const [showAlert7, setShowAlert7] = useState(false);

  // setShowAlert1(true);
  //     setTimeout(() => {
  //       setShowAlert1(false);
  //     }, 10000);

  // Handle selection change
  const handleCountryChange = (country) => {
    setIsIndian(country === "India");
    setCompanyInfo(null); // Reset company info if the country changes
    setCINInfo(null); // Reset company info if the country changes
    setCertificateInfo(null); // Reset company info if the country changes
    setIsModalOpen(false); // Close the modal after selection
  };

  // Automatically open the modal when the component mounts
  useEffect(() => {
    setIsModalOpen(true);
  }, []);

  const [entityType, setEntityType] = useState("");
  const [showCINInput, setShowCINInput] = useState(false);
  const [showSHOPCERTIFICATEInput, setShowSHOPCERTIFICATEInput] =
    useState(false);
  const [showIECnput, setShowIECnput] = useState(false);

  const handleEntityChange = (e) => {
    const selectedEntity = e.target.value;
    setEntityType(selectedEntity);

    if (["HUF", "AOP", "trust"].includes(selectedEntity)) {
      alert("You have joined the waiting list. Please wait for 12 hours.");
      setShowCINInput(false);
      setShowSHOPCERTIFICATEInput(false);
    } else if (selectedEntity === "company") {
      setShowCINInput(true);
      setShowSHOPCERTIFICATEInput(false);
    } else if (selectedEntity === "partnership") {
      setShowCINInput(false);
      setShowSHOPCERTIFICATEInput(true);
    } else {
      setShowCINInput(false);
      setShowSHOPCERTIFICATEInput(false);
    }
  };

  const [isValidGSTIN, setIsValidGSTIN] = useState(true);
  const [isValidCIN, setIsValidCIN] = useState(true);
  const [isValidCertificate, setIsValidCertificate] = useState(true);
  const [isValidIEC, setIsValidIEC] = useState(true);
  const [verifiedGST, setVerifiedGST] = useState(false);
  const [verifiedCIN, setVerifiedCIN] = useState(false);
  const [verifiedCertificate, setVerifiedCertificate] = useState(false);
  const [verifiedIEC, setVerifiedIEC] = useState(false);
  const [status, setStatus] = useState("neutral");
  const handleSubmit = () => {
    // Logic to submit the form can go here, and then based on response change the status
    if ("success condition") {
      setStatus("success");
    } else if ("failure condition") {
      setStatus("failure");
    } else if ("waiting condition") {
      setStatus("waiting");
    } else {
      setStatus("neutral");
    }
  };

  const validateGSTIN = (value) => {
    const gstinRegex =
      /^(0[1-9]|1[0-9]|2[0-7]|29|3[0-7]|97|99)[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}Z[0-9A-Z]{1}$/;
    return gstinRegex.test(value);
  };

  const validateCIN = (value) => {
    const cinRegex = /^[A-Z][0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/;
    return cinRegex.test(value);
  };

  const validateCertificate = (value) => {
    const certificateRegex = /^[0-9A-Z]{10}$/;
    return certificateRegex.test(value);
  };
  const validateIEC = (value) => {
    const certificateRegex = /^[0-9]{10}$/;
    return certificateRegex.test(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const uppercasedValue = value.toUpperCase(); // Ensure it's uppercase

    if (name === "gstin") {
      setGSTIN(uppercasedValue);
      setIsValidGSTIN(validateGSTIN(uppercasedValue));
      setVerifiedGST(false); // Reset verified status if the input changes
    } else if (name === "cin") {
      setCIN(uppercasedValue);
      setIsValidCIN(validateCIN(uppercasedValue));
      setVerifiedCIN(false); // Reset verified status if the input changes
    } else if (name === "certificate") {
      setCertificate(uppercasedValue);
      setIsValidCertificate(validateCertificate(uppercasedValue));
      setVerifiedCertificate(false); // Reset verified status if the input changes
    } else if (name === "IEC") {
      setIEC(uppercasedValue);
      setIsValidIEC(validateIEC(uppercasedValue));
      setVerifiedIEC(false); // Reset verified status if the input changes
    }
  };

  // Mock GSTIN verification function
  const handleVerifyGSTIN = () => {
    // Mocking pre-filled company info after verifying GSTIN
    setCompanyInfo({
      legalName: "Example Pvt Ltd",
      tradeName: "Example Pvt Ltd",
      status: "Active",
      entity: "Company",
      address: "123 Greater Noida, Uttar Pradesh",
    });

    if (isValidGSTIN && gstin.length === 15) {
      setVerifiedGST(true); // Set as verified if GSTIN is valid
    }
  };

  // Mock CIN verification function
  const handleVerifyCIN = () => {
    setCINInfo({
      data: "Random Data 0123",
    });
    if (isValidCIN && cin.length === 21) {
      setVerifiedCIN(true); // Set as verified if CIN is valid
    }
  };

  // Mock Certificate verification function
  const handleVerifyCertificate = () => {
    setCertificateInfo({
      partnerName: "Rohit Kashyap",
    });

    setVerifiedCertificate(true); // Set as verified if CIN is valid
  };

  // Mock IEC verification function
  const handleVerifyIEC = () => {
    setIECInfo({
      data: "Random Data 0123",
    });
    if (isValidIEC && IEC.length === 10) {
      setVerifiedIEC(true); // Set as verified if IEC is valid
    }
  };

  const [noCertificate, setNoCertificate] = useState(false);
  const [noIEC, setNoIEC] = useState(false);

  const handleNoCertificateChange = (event) => {
    setNoCertificate(event.target.checked);
  };
  const handleNoIECChange = (event) => {
    setNoIEC(event.target.checked);
  };

  return (
    <>
      <Header />
      <div className="after-header">
        {status === "neutral" && (
          <div className="max-w-3xl mx-auto py-4 bg-white  ">
            <h1 className="text-2xl font-bold text-center text-black mb-4">
              Business Information
            </h1>

            {/* Country Selection Button (Now hidden) */}
            <div className="mb-4">
              <label className="block text-sm font-semibold leading-4 text-[#212121] mb-1">
                Are you from India?
              </label>
              <button
                onClick={() => setIsModalOpen(true)}
                className="px-2 block w-full  py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-sm sm:leading-6 "
              >
                {isIndian
                  ? "Yes, I am from India."
                  : "No, I am not from India."}
              </button>
            </div>

            {/* Modal for Country Selection */}
            {isModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div class="max-md:scale-80 md:scale-125  card relative flex h-40 w-80 items-start justify-between overflow-clip rounded-[1.5em] bg-[#fffbef] px-[1em] py-[1.5em] shadow-[0px_1px_1px_1px_#d69e2e]">
                  <div class="max-w-md text-center text-lg font-bold text-[#e6bc40]">
                    Are you an Indian-based company?
                  </div>
                  <div class="group absolute bottom-[-1em] left-0 flex max-h-[6em] w-80 items-center justify-center gap-52">
                    <label
                      for="yes"
                      class="yes peer relative cursor-pointer"
                      onClick={() => handleCountryChange("India")}
                    >
                      <input
                        type="checkbox"
                        name="yes"
                        id="yes"
                        class="peer appearance-none"
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="43"
                        height="90"
                        fill="none"
                        viewBox="0 0 43 90"
                        class="absolute bottom-0 left-1/2 h-[6rem] w-[6rem] origin-bottom -translate-x-1/2 rotate-[45deg] hover:rotate-[20deg] duration-300 peer-checked:rotate-[0deg]"
                      >
                        <path
                          fill="#1269EF"
                          d="M24.87 4.21a3.52 3.52 0 0 0-7.04 0v80.96a3.52 3.52 0 1 0 7.04 0V4.21Z"
                        ></path>
                        <path
                          fill="#231F20"
                          d="M21.35 89.34a4.17 4.17 0 0 1-4.17-4.17v-81a4.17 4.17 0 1 1 8.34 0v81a4.17 4.17 0 0 1-4.17 4.17Zm0-88a2.88 2.88 0 0 0-2.87 2.87v81a2.87 2.87 0 0 0 5.74 0v-81a2.88 2.88 0 0 0-2.87-2.87Z"
                        ></path>
                        <path
                          fill="#000"
                          d="M21.35 44.69c11.427 0 20.69-8.117 20.69-18.13 0-10.013-9.263-18.13-20.69-18.13C9.923 8.43.66 16.547.66 26.56c0 10.013 9.263 18.13 20.69 18.13Z"
                        ></path>
                        <path
                          fill="#231F20"
                          d="M21.35 45.34C9.58 45.34 0 36.92 0 26.56 0 16.2 9.58 7.78 21.35 7.78c11.77 0 21.34 8.42 21.34 18.78 0 10.36-9.57 18.78-21.34 18.78Zm0-36.26c-11 0-20 7.84-20 17.48s9 17.48 20 17.48 20-7.84 20-17.48-8.95-17.48-20-17.48Z"
                        ></path>
                        <path
                          fill="#ED487E"
                          d="M.66 26.56v-4.99h41.38v3.92L.66 26.56Z"
                        ></path>
                        <path
                          fill="#231F20"
                          d="M0 27.23v-6.31h42.69v5.21L0 27.23Zm1.31-5v3.66l40.08-1v-2.67l-40.08.01Z"
                        ></path>
                        <path
                          fill="#ED487E"
                          d="M21.35 40.6c11.427 0 20.69-8.117 20.69-18.13 0-10.013-9.263-18.13-20.69-18.13C9.923 4.34.66 12.457.66 22.47c0 10.013 9.263 18.13 20.69 18.13Z"
                        ></path>
                        <path
                          fill="#231F20"
                          d="M21.35 41.26C9.58 41.26 0 32.83 0 22.47S9.58 3.69 21.35 3.69c11.77 0 21.34 8.43 21.34 18.78 0 10.35-9.57 18.79-21.34 18.79Zm0-36.26c-11 0-20 7.84-20 17.47 0 9.63 9 17.48 20 17.48s20-7.84 20-17.48S32.4 5 21.35 5Z"
                        ></path>
                        <path
                          fill="#fff"
                          d="M9.76 27.76v-4.28l-3.73-5.9h2.41l2.4 4 2.34-4h2.37l-3.74 5.91v4.27H9.76Zm7.59 0V17.58h7.55v1.72h-5.5v2.26h5.11v1.71H19.4v2.78h5.69v1.71h-7.74Zm9.941-3.31 2-.2a2.44 2.44 0 0 0 .73 1.44 2.21 2.21 0 0 0 1.49.48 2.28 2.28 0 0 0 1.5-.42 1.25 1.25 0 0 0 .51-1 .93.93 0 0 0-.17-.56 1.59 1.59 0 0 0-.74-.44c-.24-.09-.79-.23-1.64-.45a5.64 5.64 0 0 1-2.32-1 2.68 2.68 0 0 1-.5-3.51 2.79 2.79 0 0 1 1.27-1 5.061 5.061 0 0 1 2-.35 4.29 4.29 0 0 1 2.88.84 2.929 2.929 0 0 1 1 2.24l-2.06.09a1.72 1.72 0 0 0-.56-1.13 2.08 2.08 0 0 0-1.3-.34 2.34 2.34 0 0 0-1.41.37.71.71 0 0 0-.32.63.8.8 0 0 0 .3.62 5.679 5.679 0 0 0 1.89.68c.766.154 1.512.4 2.22.73a3 3 0 0 1 1.13 1 3.06 3.06 0 0 1-1.46 4.4 5.719 5.719 0 0 1-2.22.37 4.38 4.38 0 0 1-3-.89 3.89 3.89 0 0 1-1.22-2.6Z"
                        ></path>
                      </svg>
                    </label>

                    <label
                      for="no"
                      class="no relative cursor-pointer origin-bottom duration-300 peer-has-[:checked]:rotate-[-160deg]"
                      onClick={() => handleCountryChange("International")}
                    >
                      <input
                        type="checkbox"
                        name="no"
                        id="no"
                        class="peer appearance-none"
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="42"
                        height="89"
                        fill="none"
                        viewBox="0 0 42 89"
                        class="absolute bottom-0 right-1/2 h-[6rem] w-[6rem] origin-bottom translate-x-1/2 rotate-[-45deg] duration-300 hover:rotate-[-20deg] peer-checked:rotate-0"
                      >
                        <path
                          fill="#ED487E"
                          d="M21.481.49h-.48a3.35 3.35 0 0 0-3.35 3.35V85.1c0 1.85 1.5 3.35 3.35 3.35h.48c1.85 0 3.35-1.5 3.35-3.35V3.84c0-1.85-1.5-3.35-3.35-3.35Z"
                        ></path>
                        <path
                          fill="#FC932D"
                          d="M23.131 45.03c10.118 0 18.32-8.202 18.32-18.32S33.25 8.39 23.132 8.39c-10.117 0-18.32 8.202-18.32 18.32s8.203 18.32 18.32 18.32Z"
                        ></path>
                        <path
                          fill="#FDD259"
                          d="M18.801 45.03c10.118 0 18.32-8.202 18.32-18.32S28.92 8.39 18.801 8.39C8.684 8.39.481 16.592.481 26.71s8.203 18.32 18.32 18.32Z"
                        ></path>
                        <path
                          fill="#000"
                          d="M16.431 33.05a1.4 1.4 0 0 1-1.1-.52l-4.34-5.29v4.38a1.44 1.44 0 0 1-2.87 0v-8.37a1.44 1.44 0 0 1 1-1.35 1.42 1.42 0 0 1 1.59.44l4.28 5.28v-4.66a1.44 1.44 0 1 1 2.87 0v8.66a1.44 1.44 0 0 1-1 1.35 1.62 1.62 0 0 1-.43.08Zm8.221-.39a5.3 5.3 0 1 1 5.3-5.3 5.31 5.31 0 0 1-5.3 5.3Zm0-7.73a2.43 2.43 0 1 0 .019 4.859 2.43 2.43 0 0 0-.02-4.859Z"
                        ></path>
                        <path
                          fill="#231F20"
                          d="M21.541 89h-.49a3.86 3.86 0 0 1-3.86-3.86v-40a.519.519 0 0 1 1 0v40a2.84 2.84 0 0 0 2.83 2.84h.49a2.84 2.84 0 0 0 2.83-2.84V45a.52.52 0 1 1 1 0v40.14a3.86 3.86 0 0 1-3.8 3.86Zm3.34-78.97a.51.51 0 0 1-.51-.51V3.87a.52.52 0 0 1 1 0v5.62a.51.51 0 0 1-.49.54Zm-7.18-1.21a.51.51 0 0 1-.51-.51V3.87a3.84 3.84 0 0 1 3.85-3.86h.5a.51.51 0 1 1 0 1h-.49a2.83 2.83 0 0 0-2 .84 2.78 2.78 0 0 0-.82 2v4.44a.511.511 0 0 1-.53.53Z"
                        ></path>
                        <path
                          fill="#231F20"
                          d="M23.171 45.59a.52.52 0 0 1 0-1 17.81 17.81 0 0 0 14.89-27.6.51.51 0 1 1 .85-.56 18.83 18.83 0 0 1-15.74 29.16Zm10.25-33.52a.52.52 0 0 1-.29-.09 17.64 17.64 0 0 0-9.93-3 .51.51 0 0 1-.51-.51.54.54 0 0 1 .54-.51 18.75 18.75 0 0 1 10.51 3.21.53.53 0 0 1 .14.72.541.541 0 0 1-.46.18Z"
                        ></path>
                        <path
                          fill="#231F20"
                          d="M18.861 45.61a18.84 18.84 0 0 1-16.87-27.27 18.85 18.85 0 0 1 31-4.09.51.51 0 0 1-.05.73.5.5 0 0 1-.72 0 17.82 17.82 0 1 0 3.57 6.18.526.526 0 1 1 1-.33 18.87 18.87 0 0 1-17.93 24.78Z"
                        ></path>
                        <path
                          fill="#231F20"
                          d="M16.48 32.17a.51.51 0 0 1-.39-.19l-6-7.26v6.94a.52.52 0 0 1-1 0v-8.37a.51.51 0 0 1 .34-.48.5.5 0 0 1 .57.15l6 7.27V23a.52.52 0 1 1 1 0v8.66a.52.52 0 0 1-.52.51Zm8.221-.39a4.38 4.38 0 1 1 4.38-4.38 4.39 4.39 0 0 1-4.38 4.38Zm0-7.73a3.35 3.35 0 1 0 .02 6.7 3.35 3.35 0 0 0-.02-6.7Z"
                        ></path>
                      </svg>
                    </label>
                  </div>
                </div>
              </div>
            )}

            {/* Form for Indian Users */}
            {isIndian ? (
              <div>
                {/* GSTIN */}
                <div className="mb-4">
                  <div className="flex items-center justify-between">
                    <label className="block text-sm font-semibold leading-4 text-[#212121] mb-1">
                      GSTIN
                    </label>
                    {!isValidGSTIN && gstin.length > 0 && (
                      <p className="text-red-500 text-sm font-semibold">
                        Invalid GSTIN
                      </p>
                    )}
                  </div>
                  <div className="flex items-center">
                    <input
                      type="text"
                      name="gstin"
                      value={gstin}
                      onChange={handleChange} // Handles GSTIN input change
                      placeholder="Enter GSTIN"
                      className={`w-full px-2 py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 hover:ring-2 hover:ring-[#eebd2d] focus:ring-2 focus:ring-[#eebd2d] transition-colors focus:outline-none ${
                        isValidGSTIN ? "" : "ring-red-500 ring-2"
                      }`}
                    />

                    <button
                      onClick={handleVerifyGSTIN} // Verification function for GSTIN
                      className={`px-4 py-1.5 font-semibold transition-colors ${
                        verifiedGST
                          ? "bg-green-500 text-[#ffffff]"
                          : "bg-[#FFC94A] text-[#ffffff] hover:bg-[#eeb23a]"
                      }`}
                    >
                      {verifiedGST ? <span>Verified</span> : "Verify"}
                    </button>
                  </div>
                </div>

                {/* Company Info (Pre-filled after GSTIN verification) */}
                {companyInfo && (
                  <div className="bg-white border-2 border-[#FFC94A] p-4 rounded-md mb-4 ">
                    <h3 className="font-bold text-lg text-[#473a10] mb-4 text-left">
                      Verified Company Information:
                    </h3>
                    <div className="flex flex-wrap gap-2 ">
                      <p className="text-[#313131] mb-1 flex flex-col items-start border-l-4 border-[#eebd2d] pl-2 w-[49%] h-max">
                        <span className="text-xs font-bold uppercase text-[#eebd2d]">
                          Legal Name
                        </span>
                        <span className="text-base font-semibold ">
                          {companyInfo.legalName}
                        </span>
                      </p>
                      <p className="text-[#313131] mb-1 flex flex-col items-start border-l-4 border-[#eebd2d] pl-2 w-[49%] h-max">
                        <span className="text-xs font-bold uppercase text-[#eebd2d]">
                          Trade Name
                        </span>
                        <span className="text-base font-semibold ">
                          {companyInfo.tradeName}
                        </span>
                      </p>
                      <p className="text-[#313131] mb-1 flex flex-col items-start border-l-4 border-[#eebd2d] pl-2 w-[49%] h-max">
                        <span className="text-xs font-bold uppercase text-[#eebd2d]">
                          Status
                        </span>
                        <span className="text-base font-semibold ">
                          {companyInfo.status}
                        </span>
                      </p>
                      <p className="text-[#313131] mb-1 flex flex-col items-start border-l-4 border-[#eebd2d] pl-2 w-[49%] h-max">
                        <span className="text-xs font-bold uppercase text-[#eebd2d]">
                          Entity Type
                        </span>
                        <span className="text-base font-semibold ">
                          {companyInfo.entity}
                        </span>
                      </p>
                      <p className="text-[#313131] mb-1 flex flex-col items-start border-l-4 border-[#eebd2d] pl-2 w-[49%] h-max">
                        <span className="text-xs font-bold uppercase text-[#eebd2d]">
                          Pricipal Place of Business
                        </span>
                        <span className="text-base font-semibold ">
                          {companyInfo.address}
                        </span>
                      </p>
                    </div>
                  </div>
                )}
                {/* Entity Type */}
                <div className="mb-4">
                  <label className="block text-sm font-semibold leading-4 text-[#212121] mb-1">
                    Entity Type
                  </label>
                  <select
                    className="px-2 block w-full  py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-sm sm:leading-6 "
                    value={entityType}
                    onChange={handleEntityChange}
                  >
                    <option value="">Select</option>
                    <option value="company">Company</option>
                    <option value="partnership">Partnership</option>
                    <option value="proprietorship">Proprietorship</option>
                    <option value="HUF">HUF</option>
                    <option value="AOP">AOP</option>
                    <option value="trust">Trust</option>
                  </select>
                  {showCINInput && (
                    <div className="mt-3">
                      <div className="flex items-center justify-between">
                        <label
                          className="block text-sm font-semibold leading-4 text-[#212121] mb-1"
                          htmlFor="cin"
                        >
                          Corporate Identification Number
                        </label>
                        {!isValidCIN && cin.length > 0 && (
                          <p className="text-red-500 text-sm font-semibold">
                            Invalid CIN
                          </p>
                        )}
                      </div>
                      <div className="flex items-center">
                        <input
                          type="text"
                          id="cin"
                          name="cin"
                          placeholder="Enter CIN"
                          value={cin}
                          onChange={handleChange}
                          className={`w-full px-2 py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 hover:ring-2 hover:ring-[#eebd2d] focus:ring-2 focus:ring-[#eebd2d] transition-colors focus:outline-none ${
                            isValidCIN ? "" : "ring-red-500 ring-2"
                          }`}
                        />
                        <button
                          onClick={handleVerifyCIN}
                          className={`px-4 py-1.5 font-semibold transition-colors ${
                            verifiedCIN
                              ? "bg-green-500 text-[#ffffff]"
                              : "bg-[#FFC94A] text-[#ffffff] hover:bg-[#eeb23a]"
                          }`}
                        >
                          {verifiedCIN ? <span>Verified</span> : "Verify"}
                        </button>
                      </div>
                    </div>
                  )}
                  {cinInfo && (
                    <div className="bg-white border-2 border-[#FFC94A] p-4 rounded-md mt-3 ">
                      <h3 className="font-bold text-lg text-[#473a10] mb-4 text-left">
                        CIN Information:
                      </h3>
                      <div className="flex flex-wrap gap-2 ">
                        <p className="text-[#313131] mb-1 flex flex-col items-start border-l-4 border-[#eebd2d] pl-2 w-[49%] h-max">
                          <span className="text-xs font-bold uppercase text-[#eebd2d]">
                            Signatory
                          </span>
                          <span className="text-base font-semibold ">
                            {cinInfo.data}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                  {showSHOPCERTIFICATEInput && (
                    <div className="mt-3">
                      <div className="flex items-center justify-between">
                        <label
                          className="block text-sm font-semibold leading-4 text-[#212121] mb-1"
                          htmlFor="shopcertificate"
                        >
                          Certificate/Registration Number
                        </label>
                        {/* {!isValidCertificate && certificate.length > 0 && (
                        <p className="text-red-500 text-sm font-semibold">
                          Invalid Number
                        </p>
                      )} */}
                      </div>

                      <div className="flex items-center">
                        <input
                          type="text"
                          id="certificate"
                          name="certificate"
                          placeholder="Enter Certificate/Registration Number"
                          value={certificate}
                          onChange={handleChange}
                          disabled={noCertificate} // Disable input when checkbox is selected
                          className={`w-full px-2 py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 
                           
                        ${
                          noCertificate
                            ? "bg-gray-200 cursor-not-allowed"
                            : "hover:ring-2 hover:ring-[#eebd2d] focus:ring-2 focus:ring-[#eebd2d] transition-colors focus:outline-none"
                        }`} // Add styles when disabled
                        />
                        <button
                          onClick={handleVerifyCertificate}
                          disabled={noCertificate} // Disable verify button when checkbox is selected
                          className={`px-4 py-1.5 font-semibold transition-colors ${
                            verifiedCertificate
                              ? "bg-green-500 text-[#ffffff]"
                              : "bg-[#FFC94A] text-[#ffffff] hover:bg-[#eeb23a]"
                          } ${
                            noCertificate
                              ? "bg-gray-300 cursor-not-allowed"
                              : ""
                          }`} // Add disabled styles
                        >
                          {verifiedCertificate ? (
                            <span>Verified</span>
                          ) : (
                            "Verify"
                          )}
                        </button>
                      </div>
                      <label
                        for="noCertificate"
                        class="flex items-start gap-x-2.5 text-[#212121] font-medium mt-3 leading-5"
                      >
                        <input
                          type="checkbox"
                          id="noCertificate"
                          name="noCertificate"
                          checked={noCertificate}
                          onChange={handleNoCertificateChange}
                          className="mr-2 peer hidden"
                        />
                        <div
                          for="noCertificate"
                          class="h-4 w-4 mt-0.5 flex rounded-[3px] border-[1px] border-[#313131] peer-checked:bg-[#eebd2d] transition cursor-pointer "
                        >
                          <svg
                            fill="none"
                            viewBox="0 0 24 24"
                            class="w-5 h-5 light:stroke-[#e8e8e8] "
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4 12.6111L8.92308 17.5L20 6.5"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </div>
                        Don't have a Shop & Establishment Certificate? Please
                        give our team 12-24 hours to verify your credentials.
                      </label>
                    </div>
                  )}

                  {certificateInfo &&
                    !noCertificate && ( // Only show if certificate is present and checkbox is not selected
                      <div className="bg-white border-2 border-[#FFC94A] p-4 rounded-md mt-3">
                        <h3 className="font-bold text-lg text-[#473a10] mb-4 text-left">
                          Certificate/Registration Information:
                        </h3>
                        <div className="flex flex-wrap gap-2">
                          <p className="text-[#313131] mb-1 flex flex-col items-start border-l-4 border-[#eebd2d] pl-2 w-[49%] h-max">
                            <span className="text-xs font-bold uppercase text-[#eebd2d]">
                              Partner Name
                            </span>
                            <span className="text-base font-semibold">
                              {certificateInfo.partnerName}
                            </span>
                          </p>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            ) : (
              // Form for International Users
              <div>
                {/* Firm Name */}
                <div className="mb-3">
                  <label className="block text-sm font-semibold leading-4 text-[#212121] mb-1">
                    Firm Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Firm Name"
                    className="px-2 block w-full  py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-sm sm:leading-6 "
                  />
                </div>

                {/* CIN for Verification */}
                <div className="mt-3">
                  <div className="flex items-center justify-between">
                    <label
                      className="block text-sm font-semibold leading-4 text-[#212121] mb-1"
                      htmlFor="IEC"
                    >
                      Import-Export Code
                    </label>
                    {!isValidIEC && IEC.length > 0 && (
                      <p className="text-red-500 text-sm font-semibold">
                        Invalid Number
                      </p>
                    )}
                  </div>

                  <div className="flex items-center">
                    <input
                      type="text"
                      id="IEC"
                      name="IEC"
                      placeholder="Enter IEC"
                      value={IEC}
                      onChange={handleChange}
                      disabled={noIEC} // Disable input when checkbox is selected
                      className={`w-full px-2 py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400  ${
                        isValidIEC ? "" : "ring-red-500 ring-2"
                      } ${
                        noIEC
                          ? "bg-gray-200 cursor-not-allowed"
                          : "hover:ring-2 hover:ring-[#eebd2d] focus:ring-2 focus:ring-[#eebd2d] transition-colors focus:outline-none"
                      }`} // Add styles when disabled
                    />
                    <button
                      onClick={handleVerifyIEC}
                      disabled={noIEC} // Disable verify button when checkbox is selected
                      className={`px-4 py-1.5 font-semibold transition-colors ${
                        verifiedIEC
                          ? "bg-green-500 text-[#ffffff]"
                          : "bg-[#FFC94A] text-[#ffffff] hover:bg-[#eeb23a]"
                      } ${noIEC ? "bg-gray-300 cursor-not-allowed" : ""}`} // Add disabled styles
                    >
                      {verifiedIEC ? <span>Verified</span> : "Verify"}
                    </button>
                  </div>
                  <label
                    for="noIEC"
                    class="flex items-start gap-x-2.5 text-[#212121] font-medium mt-3 leading-5"
                  >
                    <input
                      type="checkbox"
                      id="noIEC"
                      name="noIEC"
                      checked={noIEC}
                      onChange={handleNoIECChange}
                      className="mr-2 peer hidden"
                    />
                    <div
                      for="noIEC"
                      class="h-4 w-4 mt-0.5 flex rounded-[3px] border-[1px] border-[#313131] peer-checked:bg-[#eebd2d] transition cursor-pointer "
                    >
                      <svg
                        fill="none"
                        viewBox="0 0 24 24"
                        class="w-5 h-5 light:stroke-[#e8e8e8] "
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 12.6111L8.92308 17.5L20 6.5"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </div>
                    Don't have a Import-Export Code? Please give our team 12-24
                    hours to verify your credentials.
                  </label>
                </div>
                {IECInfo &&
                  !noIEC && ( // Only show if IEC is present and checkbox is not selected
                    <div className="bg-white border-2 border-[#FFC94A] p-4 rounded-md mt-3">
                      <h3 className="font-bold text-lg text-[#473a10] mb-4 text-left">
                        Import-Export Code Information:
                      </h3>
                      <div className="flex flex-wrap gap-2">
                        <p className="text-[#313131] mb-1 flex flex-col items-start border-l-4 border-[#eebd2d] pl-2 w-[49%] h-max">
                          <span className="text-xs font-bold uppercase text-[#eebd2d]">
                            Signatory
                          </span>
                          <span className="text-base font-semibold">
                            {IECInfo.data}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
              </div>
            )}

            {/* Submit Button */}
            <div className="mt-6">
              <button className="w-full py-2 bg-[#FFC94A] text-[#ffffff] font-semibold  hover:bg-[#eeb23a] transition-colors">
                Submit
              </button>
            </div>
          </div>
        )}

        {status === "success" && (
          <div className="h-[80vh] flex items-center justify-center">
            <div className="max-w-3xl mx-auto p-4  text-green-900 text-center rounded-lg">
              <div class="flex justify-center">
                <img
                  className="w-[180px] h-[180px] "
                  src="/images/success.gif"
                  alt=""
                />
              </div>
              {/* <h1 className="text-2xl font-bold mb-4">Success</h1> */}
              <p className="font-semibold text-base">
                Congratulations! You can now access all our specialized B-2-B
                technology and puchase products at wholesale prices from BUWCH
                web portal.
              </p>
            </div>
          </div>
        )}

        {status === "waiting" && (
          <div className="h-[80vh] flex items-center justify-center">
            <div className="max-w-3xl mx-auto p-4  text-yellow-900 text-center rounded-lg">
              <div class="flex justify-center">
                <img
                  className="w-[180px] h-[180px] "
                  src="/images/sand-clock.gif"
                  alt=""
                />
              </div>
              {/* <h1 className="text-2xl font-bold mb-4">Waiting</h1> */}
              <p className="font-semibold text-base">
                Our back-office is working on verifying your business profile
                from the data you have provided. Please wait 12-24 hours for
                confirmation. Thank you for working with BUWCH.
              </p>
            </div>
          </div>
        )}

        {status === "failure" && (
          <div className="h-[80vh] flex items-center justify-center">
            <div className="max-w-3xl mx-auto p-4  text-red-900 text-center rounded-lg">
              <div class="flex justify-center">
                <img
                  className="w-[180px] h-[180px] "
                  src="/images/fail.gif"
                  alt=""
                />
              </div>
              {/* <h1 className="text-2xl font-bold mb-4">Failure</h1>  */}

              <p className="font-semibold text-base">
                Sorry your back-office profile verification has failed due to
                incomplete or inconsistent data. Please reinitiate. Otherwise,
                you can continue using our "Individual User" facilities.
              </p>
            </div>
          </div>
        )}
      </div>

      {/* alerts */}
      {showAlert1 && (
        // Proprietorship
        <Alert
          message="Your name as per PAN records doesn't match with your Profile Name."
          onClose={() => setShowAlert1(false)}
        />
      )}
      {showAlert2 && (
        // Company
        <Alert
          message="Only MCA recorded signatories can set-up business profile."
          onClose={() => setShowAlert2(false)}
        />
      )}
      {showAlert3 && (
        // Partnership
        <Alert
          message="Your Firm name as per Shop & Establishment Certificate doesn't match your GST Legal Name."
          onClose={() => setShowAlert3(false)}
        />
      )}
      {showAlert4 && (
        // Partnership
        <Alert
          message="Only authorised Partner's can set-up business profile."
          onClose={() => setShowAlert4(false)}
        />
      )}
      {showAlert5 && (
        // Company
        <Alert
          message="Your Company Name as per MCA master data doesn't match your GST Legal Name."
          onClose={() => setShowAlert5(false)}
        />
      )}
      {showAlert6 && (
        // IEC
        <Alert
          message="Only MCA recorded signatories can set-up business profile."
          onClose={() => setShowAlert6(false)}
        />
      )}
      {showAlert7 && (
        // IEC
        <Alert
          message="Your Company Name as per MCA master data doesn't match your IEC Legal Name."
          onClose={() => setShowAlert7(false)}
        />
      )}
    </>
  );
};

export default DynamicForm;
