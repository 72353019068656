import { useState, useEffect } from "react";

const EdgeSelection = ({ onNext }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [selectedEdges, setSelectedEdges] = useState([]); // Array to store selected edges
  const [selectedEyelets, setSelectedEyelets] = useState([]); // Array to store selected edges
  const [selectedShoelaces, setSelectedShoelaces] = useState([]); // Array to store selected edges

  const edgeTypes = ["Single sole", "Double sole", "Triple sole"];
  const eyeletsTypes = ["Blind Eyelets", "Eyelets 7mm", "Eyelets 9mm"];
  const shoelacesTypes = ["Flat 5mm", "Flat 3mm", "Round 3mm", "Round 7mm"];
  const colors = [
    "Red",
    "Blue",
    "Green",
    "Violet",
    "Black",
    "Brown",
    "Yellow",
    "Purple",
    "White",
  ];

  const handleEdgeSelect = (type) => {
    setSelectedEdges(
      (prevSelectedEdges) =>
        prevSelectedEdges.includes(type)
          ? prevSelectedEdges.filter((edge) => edge !== type) // Deselect if already selected
          : [...prevSelectedEdges, type] // Select if not already selected
    );
  };

  const handleEyeletsSelect = (type) => {
    setSelectedEyelets(
      (prevSelectedEyelets) =>
        prevSelectedEyelets.includes(type)
          ? prevSelectedEyelets.filter((eyelets) => eyelets !== type) // Deselect if already selected
          : [...prevSelectedEyelets, type] // Select if not already selected
    );
  };
  const handleShoelacesSelect = (type) => {
    setSelectedShoelaces(
      (prevSelectedShoelaces) =>
        prevSelectedShoelaces.includes(type)
          ? prevSelectedShoelaces.filter((shoelaces) => shoelaces !== type) // Deselect if already selected
          : [...prevSelectedShoelaces, type] // Select if not already selected
    );
  };

  return (
    <div className="bg-white max-md:p-2 md:p-4 2xl:p-6 rounded-lg shadow-md">
      <div className="flex flex-col gap-y-8">
        <div className="border-2 rounded-lg p-4 max-w-5xl relative">
          <div className="absolute border rounded-lg w-32 py-1 bg-white text-center text-sm md:text-base font-semibold -top-4 -left-0.5">
            <p>EDGE</p>
          </div>
          {/* Edge Type Selection */}
          <div className=" flex max-md:flex-col md:flex-row max-md:items-start md:items-center justify-start">
            <label
              htmlFor="edgeType"
              className="block w-20 text-sm xl:text-base font-semibold leading-4 text-[#212121]"
            >
              Type:
            </label>
            <div className="flex flex-wrap gap-x-2  max-md:mt-2">
              {edgeTypes.map((type) => (
                <button
                  key={type}
                  onClick={() => handleEdgeSelect(type)}
                  className={`md:px-3 py-2 rounded-md flex items-center gap-1.5 font-medium w-28 md:w-44 lg:w-48 text-sm xl:text-base ${
                    selectedEdges.includes(type) ? "bg-yellow-100" : "bg-white"
                  }`}
                >
                  <svg
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    id="fi_15469814"
                    className={`md:w-5 w-3 h-3 md:h-5 ${
                      selectedEdges.includes(type)
                        ? "fill-yellow-500 bg-yellow-500 rounded-full"
                        : "fill-yellow-500"
                    }`}
                  >
                    <g>
                      <path d="m8 0c-4.41113 0-8 3.58887-8 8s3.58887 8 8 8 8-3.58887 8-8-3.58887-8-8-8zm0 14c-3.30859 0-6-2.69141-6-6s2.69141-6 6-6 6 2.69141 6 6-2.69141 6-6 6z"></path>
                    </g>
                  </svg>
                  {type}
                </button>
              ))}
            </div>
          </div>
          {/* Colors Section */}
          <div className="mt-3 flex md:flex-row max-md:flex-col items-start  max-xl:gap-3 xl:gap-5">
            <label
              htmlFor="edgecolor"
              className="block  text-sm xl:text-base font-semibold leading-4 text-[#212121] mt-2 "
            >
              Colors:
            </label>

            <div className="flex flex-wrap gap-2 ">
              {colors.map((color) => (
                <div
                  className="flex justify-center items-center gap-2"
                  key={color}
                >
                  <div className="flex flex-col items-center justify-center">
                    <label className="text-slate-400">
                      <input
                        type="checkbox"
                        className="h-[1px] opacity-0 overflow-hidden absolute whitespace-nowrap w-[1px] peer"
                      />
                      <span className="peer-checked:border-[#eebd2d] peer-checked:text-[#eebd2d] peer-checked:before:border-[#eebd2d] peer-checked:before:bg-[#eebd2d] peer-checked:before:opacity-100 peer-checked:before:scale-100  flex flex-col items-center justify-center w-14 h-14 xl:w-[86px] xl:h-[86px] rounded-lg  transition-all duration-200 cursor-pointer relative border-[#fff] border-2 bg-[#fff] before:absolute before:block max-xl:before:w-1 xl:before:w-2 max-xl:before:h-1 xl:before:h-2 before:border-[3px]  before:rounded-full before:top-1 before:left-1 before:opacity-0 before:transition-transform before:scale-0 before:text-[#fff] before:text-xs  before:items-center before:justify-center hover:border-[#eebd2d] hover:before:scale-100 hover:before:opacity-100">
                        <div
                          className="w-10 h-10 xl:w-[70px] xl:h-[70px] rounded-full  flex items-center justify-center border-[1px]"
                          style={{ backgroundColor: color.toLowerCase() }}
                        ></div>
                      </span>
                    </label>
                    <p className="font-semibold text-xs xl:text-sm">{color}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="border-2 rounded-lg p-4 max-w-5xl relative">
          <div className="absolute border rounded-lg w-32 py-1 bg-white text-center text-sm md:text-base font-semibold -top-4 -left-0.5">
            <p>EYELETS</p>
          </div>
          {/* Eyelets Type Selection */}
          <div className=" flex max-md:flex-col md:flex-row max-md:items-start md:items-center justify-start">
            <label
              htmlFor="eyeletsType"
              className="block w-20 text-sm xl:text-base font-semibold leading-4 text-[#212121]"
            >
              Type:
            </label>
            <div className="flex flex-wrap gap-x-2  max-md:mt-2">
              {eyeletsTypes.map((type) => (
                <button
                  key={type}
                  onClick={() => handleEyeletsSelect(type)}
                  className={`md:px-3 py-2 rounded-md flex items-center gap-1.5 font-medium w-28 md:w-44 lg:w-48 text-sm xl:text-base ${
                    selectedEyelets.includes(type)
                      ? "bg-yellow-100"
                      : "bg-white"
                  }`}
                >
                  <svg
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    id="fi_15469814"
                    className={`md:w-5 w-3 h-3 md:h-5 ${
                      selectedEyelets.includes(type)
                        ? "fill-yellow-500 bg-yellow-500 rounded-full"
                        : "fill-yellow-500"
                    }`}
                  >
                    <g>
                      <path d="m8 0c-4.41113 0-8 3.58887-8 8s3.58887 8 8 8 8-3.58887 8-8-3.58887-8-8-8zm0 14c-3.30859 0-6-2.69141-6-6s2.69141-6 6-6 6 2.69141 6 6-2.69141 6-6 6z"></path>
                    </g>
                  </svg>
                  {type}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="border-2 rounded-lg p-4 max-w-5xl relative">
          <div className="absolute border rounded-lg w-32 py-1 bg-white text-center text-sm md:text-base font-semibold -top-4 -left-0.5">
            <p>LINING</p>
          </div>
          {/* Colors Section */}
          <div className="mt-3 flex md:flex-row max-md:flex-col items-start  max-xl:gap-3 xl:gap-5">
            <label
              htmlFor="edgecolor"
              className="block text-sm xl:text-base font-semibold leading-4 text-[#212121] mt-2 "
            >
              Colors:
            </label>

            <div className="flex flex-wrap gap-2 ">
              {colors.map((color) => (
                <div
                  className="flex justify-center items-center gap-2"
                  key={color}
                >
                  <div className="flex flex-col items-center justify-center">
                    <label className="text-slate-400">
                      <input
                        type="checkbox"
                        className="h-[1px] opacity-0 overflow-hidden absolute whitespace-nowrap w-[1px] peer"
                      />
                      <span className="peer-checked:border-[#eebd2d] peer-checked:text-[#eebd2d] peer-checked:before:border-[#eebd2d] peer-checked:before:bg-[#eebd2d] peer-checked:before:opacity-100 peer-checked:before:scale-100  flex flex-col items-center justify-center w-14 h-14 xl:w-[86px] xl:h-[86px] rounded-lg  transition-all duration-200 cursor-pointer relative border-[#fff] border-2 bg-[#fff] before:absolute before:block max-xl:before:w-1 xl:before:w-2 max-xl:before:h-1 xl:before:h-2 before:border-[3px]  before:rounded-full before:top-1 before:left-1 before:opacity-0 before:transition-transform before:scale-0 before:text-[#fff] before:text-xs  before:items-center before:justify-center hover:border-[#eebd2d] hover:before:scale-100 hover:before:opacity-100">
                        <div
                          className="w-10 h-10 xl:w-[70px] xl:h-[70px] rounded-full  flex items-center justify-center border-[1px]"
                          style={{ backgroundColor: color.toLowerCase() }}
                        ></div>
                      </span>
                    </label>
                    <p className="font-semibold text-xs xl:text-sm">{color}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="border-2 rounded-lg p-4 max-w-5xl relative">
          <div className="absolute border rounded-lg w-32 py-1 bg-white text-center text-sm md:text-base font-semibold -top-4 -left-0.5">
            <p>SHOELACES</p>
          </div>
          {/* Edge Type Selection */}
          <div className=" flex max-md:flex-col md:flex-row max-md:items-start md:items-center justify-start">
            <label
              htmlFor="edgeType"
              className="block w-20 text-sm xl:text-base font-semibold leading-4 text-[#212121]"
            >
              Type:
            </label>
            <div className="flex flex-wrap gap-x-2  max-md:mt-2">
              {shoelacesTypes.map((type) => (
                <button
                  key={type}
                  onClick={() => handleShoelacesSelect(type)}
                  className={`md:px-3 py-2 rounded-md flex items-center gap-1.5 font-medium w-28 md:w-44 lg:w-48 text-sm xl:text-base ${
                    selectedShoelaces.includes(type) ? "bg-yellow-100" : "bg-white"
                  }`}
                >
                  <svg
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    id="fi_15469814"
                    className={`md:w-5 w-3 h-3 md:h-5 ${
                      selectedShoelaces.includes(type)
                        ? "fill-yellow-500 bg-yellow-500 rounded-full"
                        : "fill-yellow-500"
                    }`}
                  >
                    <g>
                      <path d="m8 0c-4.41113 0-8 3.58887-8 8s3.58887 8 8 8 8-3.58887 8-8-3.58887-8-8-8zm0 14c-3.30859 0-6-2.69141-6-6s2.69141-6 6-6 6 2.69141 6 6-2.69141 6-6 6z"></path>
                    </g>
                  </svg>
                  {type}
                </button>
              ))}
            </div>
          </div>
          {/* Colors Section */}
          <div className="mt-3 flex md:flex-row max-md:flex-col items-start  max-xl:gap-3 xl:gap-5">
            <label
              htmlFor="edgecolor"
              className="block text-sm xl:text-base font-semibold leading-4 text-[#212121] mt-2 "
            >
              Colors:
            </label>

            <div className="flex flex-wrap gap-2 ">
              {colors.map((color) => (
                <div
                  className="flex justify-center items-center gap-2"
                  key={color}
                >
                  <div className="flex flex-col items-center justify-center">
                    <label className="text-slate-400">
                      <input
                        type="checkbox"
                        className="h-[1px] opacity-0 overflow-hidden absolute whitespace-nowrap w-[1px] peer"
                      />
                      <span className="peer-checked:border-[#eebd2d] peer-checked:text-[#eebd2d] peer-checked:before:border-[#eebd2d] peer-checked:before:bg-[#eebd2d] peer-checked:before:opacity-100 peer-checked:before:scale-100  flex flex-col items-center justify-center w-14 h-14 xl:w-[86px] xl:h-[86px] rounded-lg  transition-all duration-200 cursor-pointer relative border-[#fff] border-2 bg-[#fff] before:absolute before:block max-xl:before:w-1 xl:before:w-2 max-xl:before:h-1 xl:before:h-2 before:border-[3px]  before:rounded-full before:top-1 before:left-1 before:opacity-0 before:transition-transform before:scale-0 before:text-[#fff] before:text-xs  before:items-center before:justify-center hover:border-[#eebd2d] hover:before:scale-100 hover:before:opacity-100">
                        <div
                          className="w-10 h-10 xl:w-[70px] xl:h-[70px] rounded-full  flex items-center justify-center border-[1px]"
                          style={{ backgroundColor: color.toLowerCase() }}
                        ></div>
                      </span>
                    </label>
                    <p className="font-semibold text-xs xl:text-sm">{color}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Save Button */}
      <div className="flex items-center justify-end w-full mt-3">
        <button
          className="bg-white text-center w-20 lg:w-32 rounded-lg h-9 lg:h-11 relative text-[#313131] text-xl font-semibold group border"
          type="button"
          onClick={onNext}
        >
          <div className="bg-[#FFC94A] rounded-md h-[26px] lg:h-[34px] w-1/3 flex items-center justify-center absolute left-1 top-[4px] group-hover:w-[118px] z-10 duration-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1024 1024"
              className="rotate-180 w-4 lg:w-6 h-4 lg:h-6"
            >
              <path
                d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
                fill="#313131"
              ></path>
              <path
                d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
                fill="#313131"
              ></path>
            </svg>
          </div>
          <p className="max-lg:translate-x-4 lg:translate-x-5 text-xs lg:text-sm">
            Finish
          </p>
        </button>
      </div>
    </div>
  );
};

export default EdgeSelection;
