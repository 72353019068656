import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./Filter.css";

function Filter({ onFilter }) {
  const [selectedItems, setSelectedItems] = useState(-1);
  const [selectedLeathers, setSelectedLeathers] = useState([]);
  const [selectedSoles, setSelectedSoles] = useState([]);
  const [selectedConstructions, setSelectedConstructions] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [shoes, setShoes] = useState([]);
  const filterRef = useRef(null);
  const [filteredShoes, setFilteredShoes] = useState([]);
  const { Category } = useParams();

  useEffect(() => {
    fetch(`http://127.0.0.1:8000/api/product-page/`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setShoes(data.products);
        setFilteredShoes(
          data.products?.filter((shoe) => shoe.Article.includes(Category))
        );
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }, []);

  useEffect(() => {
    onFilter({
      colors: selectedColors,
      leathers: selectedLeathers,
      soles: selectedSoles,
      constructions: selectedConstructions,
    });
  }, [selectedColors, selectedLeathers, selectedSoles, selectedConstructions]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setSelectedItems(null); // close the filter
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterRef]);

  const getUniqueValues = (keyPath) => {
    const values = new Set();
    shoes?.forEach((shoe) => {
      const keys = keyPath?.split(".");
      let value = shoe;
      keys?.forEach((key) => {
        if (value[key]) {
          value = value[key];
        } else {
          value = null;
        }
      });
      if (value && typeof value === "object") {
        Object.keys(value)?.forEach((item) => {
          if (item !== "Consumption") values.add(item);
        });
      }
    });
    return Array.from(values);
  };

  const getUniqueSubKeys = (keyPath) => {
    const subKeys = new Set();
    shoes?.forEach((shoe) => {
      const keys = keyPath?.split(".");
      let value = shoe;
      keys?.forEach((key) => {
        if (value[key]) {
          value = value[key];
        } else {
          value = null;
        }
      });

      if (value && typeof value === "object") {
        Object.values(value)?.forEach((soleType) => {
          if (soleType && typeof soleType === "object") {
            Object.values(soleType)?.forEach((constructionObj) => {
              if (constructionObj && typeof constructionObj === "object") {
                Object.keys(constructionObj)?.forEach((subKey) => {
                  if (subKey !== "Price_INR") subKeys.add(subKey);
                });
              }
            });
          }
        });
      }
    });
    return Array.from(subKeys);
  };

  const colors = getUniqueValues("Images");
  const leathers = getUniqueValues("Leather");
  const soles = getUniqueValues("Sole");
  const constructions = getUniqueSubKeys("Sole");

  const handleReset = () => {
    setSelectedItems(-1);
    setSelectedColors([]);
    setSelectedLeathers([]);
    setSelectedConstructions([]);
    setSelectedSoles([]);
  };

  const handleColorChange = (color) => {
    setSelectedColors((prevSelected) =>
      prevSelected.includes(color)
        ? prevSelected?.filter((c) => c !== color)
        : [...prevSelected, color]
    );
  };

  const handleLeatherChange = (leather) => {
    setSelectedLeathers((prevSelected) =>
      prevSelected.includes(leather)
        ? prevSelected?.filter((l) => l !== leather)
        : [...prevSelected, leather]
    );
  };

  const handleConstructionChange = (construction) => {
    setSelectedConstructions((prevSelected) =>
      prevSelected.includes(construction)
        ? prevSelected?.filter((l) => l !== construction)
        : [...prevSelected, construction]
    );
  };

  const handleSoleChange = (sole) => {
    setSelectedSoles((prevSelected) =>
      prevSelected.includes(sole)
        ? prevSelected?.filter((s) => s !== sole)
        : [...prevSelected, sole]
    );
  };

  function getItems() {
    return filteredShoes.reduce((total, pro) => {
      const colors = Object.keys(pro.Images);
      return total + colors.length;
    }, 0);
  }

  return (
    <>
      <div className="relative  w-full">
        <div className="flex flex-col justify-center items-center  w-full">
          <div className="flex justify-center items-center  w-full">
            <div className="w-[15%] flex justify-center items-center ">
              <p>{getItems()} items</p>
            </div>
            <div className="flex justify-center items-center bg-slate-200  w-[70%]">
              <div className="">
                <button
                  className="p-3 text-start hover:bg-slate-100 w-full"
                  onClick={() => {
                    setSelectedItems(0);
                    setSelectedLeathers([]);
                    setSelectedConstructions([]);
                    setSelectedSoles([]);
                  }}
                >
                  Colors
                </button>
              </div>

              <div className="">
                <button
                  className="p-3 text-start hover:bg-slate-100 w-full"
                  onClick={() => {
                    setSelectedItems(1);
                    setSelectedColors([]);
                    setSelectedConstructions([]);
                    setSelectedSoles([]);
                  }}
                >
                  {" "}
                  Leathers
                </button>
              </div>

              <div className="">
                <button
                  className="p-3 text-start hover:bg-slate-100 w-full"
                  onClick={() => {
                    setSelectedItems(2);
                    setSelectedColors([]);
                    setSelectedLeathers([]);
                    setSelectedConstructions([]);
                  }}
                >
                  {" "}
                  Soles
                </button>
              </div>

              <div className="">
                <button
                  className="p-3 text-start hover:bg-slate-100 w-full"
                  onClick={() => {
                    setSelectedItems(3);
                    setSelectedColors([]);
                    setSelectedLeathers([]);
                    setSelectedSoles([]);
                  }}
                >
                  {" "}
                  Constructions
                </button>
              </div>
            </div>
            <div className="w-[15%] flex justify-center items-center">
              <p>{getItems()} items</p>
            </div>
          </div>
          <div className="w-full h-44 flex justify-start items-start py-5 px-40 bg-slate-100">
            {selectedItems === 0 && (
              <div className=" flex flex-wrap  ">
                {colors?.map((color) => (
                  <div
                    key={color}
                    className="w-48 flex items-center radio-button"
                  >
                    <input
                      type="checkbox"
                      className="radio-button__input flex items-center"
                      id={color}
                      name="radio-group"
                      checked={selectedColors.includes(color)}
                      onChange={() => handleColorChange(color)}
                    />
                    <label
                      className="radio-button__label flex items-center font-semibold"
                      htmlFor={color}
                      for={color}
                    >
                      {" "}
                      <span class="radio-button__custom"></span>
                      {color.split("_")[0]}
                    </label>
                    <br />
                  </div>
                ))}
              </div>
            )}
            {selectedItems === 1 && (
              <div className="flex flex-wrap ">
                {leathers?.map((leather) => (
                  <div
                    className="w-48 flex items-center radio-button"
                    key={leather}
                  >
                    <input
                      className="radio-button__input flex items-center"
                      type="checkbox"
                      id={leather}
                      checked={selectedLeathers.includes(leather)}
                      onChange={() => handleLeatherChange(leather)}
                    />
                    <label
                      className="radio-button__label flex items-center font-semibold"
                      htmlFor={leather}
                    >
                      {" "}
                      <span class="radio-button__custom"></span>
                      {leather.replace(/_/g, " ")}
                    </label>
                    <br />
                  </div>
                ))}
              </div>
            )}
            {selectedItems === 2 && (
              <div className="flex flex-wrap ">
                {soles?.map((sole) => (
                  <div
                    className="w-48 flex items-center radio-button"
                    key={sole}
                  >
                    <input
                      className="radio-button__input flex items-center"
                      type="checkbox"
                      id={sole}
                      checked={selectedSoles.includes(sole)}
                      onChange={() => handleSoleChange(sole)}
                    />
                    <label
                      className="radio-button__label flex items-center font-semibold"
                      htmlFor={sole}
                    >
                      {" "}
                      <span class="radio-button__custom"></span>
                      {sole.replace(/_/g, " ")}
                    </label>
                    <br />
                  </div>
                ))}
              </div>
            )}
            {selectedItems === 3 && (
              <div className="flex flex-wrap ">
                {constructions?.map((construction) => (
                  <div
                    className="w-48 flex items-center radio-button"
                    key={construction}
                  >
                    <input
                      className="radio-button__input flex items-center"
                      type="checkbox"
                      id={construction}
                      checked={selectedConstructions.includes(construction)}
                      onChange={() => handleConstructionChange(construction)}
                    />
                    <label
                      className="radio-button__label flex items-center font-semibold"
                      htmlFor={construction}
                    >
                      {" "}
                      <span class="radio-button__custom"></span>
                      {construction.replace(/_/g, " ")}
                    </label>
                    <br />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Filter;
