import { useState, useEffect } from "react";

const SoleSelection = ({ onNext }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [selectedSole, setSelectedSole] = useState("Leather"); // Set the default to the first sole type
  const [soleSelections, setSoleSelections] = useState({});
  const [fadeEffect, setFadeEffect] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null); // To store the image URL or path
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [constructionType, setConstructionType] = useState("");
  const [showWeltsPopup, setShowWeltsPopup] = useState(false);
  const [soleType, setSoleType] = useState("");

  const soleTypes = [
    "Leather",
    "Rubber",
    "Synthetic",
    "Crepe",
    "Wedge",
    "Platform",
    "Espadrille",
  ];
  const constructions = ["Goodyear Welt", "Blake Stitch", "Cemented"];
  const colors = [
    "Red",
    "Blue",
    "Green",
    "Violet",
    "Black",
    "Brown",
    "Yellow",
    "Purple",
    "White",
  ];

  const handleSoleSelect = (type) => {
    setSelectedSole(type);
    setFadeEffect(true);
    setIsMenuOpen(false); // Close the sidebar upon selection
    if (!soleSelections[type]) {
      setSoleSelections((prevSelections) => ({
        ...prevSelections,
        [type]: { constructions: [], colors: [] },
      }));
    }
    setTimeout(() => setFadeEffect(false), 500);
  };

  const handleConstructionToggle = (construction) => {
    setSoleSelections((prevSelections) => {
      const currentConstructions =
        prevSelections[selectedSole]?.constructions || [];
      const newConstructions = currentConstructions.includes(construction)
        ? currentConstructions.filter((c) => c !== construction)
        : [...currentConstructions, construction];
      return {
        ...prevSelections,
        [selectedSole]: {
          ...prevSelections[selectedSole],
          constructions: newConstructions,
        },
      };
    });
  };

  const handleSoleTypeChange = (e) => {
    setSoleType(e.target.value);
  };

  const handleConstructionChange = (e) => {
    const selectedConstruction = e.target.value;
    setConstructionType(selectedConstruction);

    // Show the welts pop-up only if "Goodyear" construction is selected
    if (selectedConstruction === "Goodyear Welt") {
      setShowWeltsPopup(true);
    } else {
      setShowWeltsPopup(false);
    }
  };

  const handleColorToggle = (color) => {
    setSoleSelections((prevSelections) => {
      const currentColors = prevSelections[selectedSole]?.colors || [];
      const newColors = currentColors.includes(color)
        ? currentColors.filter((c) => c !== color)
        : [...currentColors, color];
      return {
        ...prevSelections,
        [selectedSole]: {
          ...prevSelections[selectedSole],
          colors: newColors,
        },
      };
    });
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const handleClickOutside = (e) => {
    const modal = document.getElementById("modal");
    if (modal && !modal.contains(e.target)) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const currentSelection = soleSelections[selectedSole] || {};

  return (
    <div className="bg-white max-md:p-2 md:p-4 2xl:p-6 rounded-lg shadow-md">
      <div className="lg:hidden max-lg:block">
        <button
          className="lg:hidden block text-[#212121] py-2 focus:outline-none"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>

        <div
          className={`fixed top-0 left-0 h-full w-64 bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-50 ${
            isMenuOpen ? "translate-x-0" : "-translate-x-full"
          } lg:translate-x-0 lg:relative lg:top-auto lg:left-auto lg:h-auto lg:shadow-none lg:bg-transparent lg:block`}
        >
          <div className="mt-4 max-w-5xl px-4">
            <label
              htmlFor="soleType"
              className="block text-sm xl:text-base font-semibold leading-4 text-[#212121]"
            >
              Type of Sole:
            </label>
            <div className="flex flex-wrap gap-2 mt-3">
              {soleTypes.map((type) => {
                const isSelected =
                  soleSelections[type]?.constructions?.length > 0 ||
                  soleSelections[type]?.colors?.length > 0;

                return (
                  <button
                    key={type}
                    onClick={() => handleSoleSelect(type)}
                    className={`px-3 py-2 rounded-md flex items-center gap-1.5 font-medium w-full text-left text-sm xl:text-base ${
                      selectedSole === type ? "bg-yellow-100" : "bg-white"
                    }`}
                  >
                    <svg
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      className={`w-5 h-5 ${
                        isSelected
                          ? "fill-yellow-500 bg-yellow-500 rounded-full"
                          : "fill-yellow-500"
                      }`}
                    >
                      <g>
                        <path d="m8 0c-4.41113 0-8 3.58887-8 8s3.58887 8 8 8 8-3.58887 8-8-3.58887-8-8-8zm0 14c-3.30859 0-6-2.69141-6-6s2.69141-6 6-6 6 2.69141 6 6-2.69141 6-6 6z"></path>
                      </g>
                    </svg>
                    {type}
                  </button>
                );
              })}
            </div>
          </div>
        </div>

        {isMenuOpen && (
          <div
            className="fixed inset-0 bg-black opacity-50 z-40"
            onClick={() => setIsMenuOpen(false)}
          />
        )}
      </div>
      <div className=" max-w-5xl lg:block max-lg:hidden">
        <label
          htmlFor="soleType"
          className="block text-sm xl:text-base font-semibold leading-4 text-[#212121]"
        >
          Type of Sole:
        </label>
        <div className="flex flex-wrap gap-2 mt-3 ">
          {soleTypes.map((type) => {
            const isSelected =
              soleSelections[type]?.constructions?.length > 0 ||
              soleSelections[type]?.colors?.length > 0;

            return (
              <button
                key={type}
                onClick={() => handleSoleSelect(type)}
                className={`px-3 py-2 rounded-md flex items-center gap-1.5 font-medium w-48 text-sm xl:text-base ${
                  selectedSole === type ? "bg-yellow-100" : "bg-white"
                }`}
              >
                <svg
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fi_15469814"
                  className={`w-5 h-5 ${
                    isSelected
                      ? "fill-yellow-500 bg-yellow-500 rounded-full"
                      : "fill-yellow-500"
                  }`}
                >
                  <g>
                    <path d="m8 0c-4.41113 0-8 3.58887-8 8s3.58887 8 8 8 8-3.58887 8-8-3.58887-8-8-8zm0 14c-3.30859 0-6-2.69141-6-6s2.69141-6 6-6 6 2.69141 6 6-2.69141 6-6 6z"></path>
                  </g>
                </svg>
                {type}
              </button>
            );
          })}
        </div>
      </div>

      <div className=" max-lg:mt-0 lg:mt-3 max-w-4xl">
        <div
          className={`mb-4 flex md:flex-row max-md:flex-col items-start max-xl:gap-3 xl:gap-5 ${
            fadeEffect ? "fade-effect" : ""
          }`}
        >
          <label
            htmlFor="soleConstruction"
            className="block text-sm xl:text-base font-semibold leading-4 text-[#212121] mt-2"
          >
            Construction:
          </label>
          <div className="flex max-xl:gap-x-2 xl:gap-x-5 flex-wrap">
            {constructions.map((construction) => (
              <button
                key={construction}
                onClick={() => handleConstructionToggle(construction)}
                className={`rounded-md flex items-center text-sm xl:text-base gap-1.5 font-medium px-2 py-2 hover:bg-yellow-100 ${
                  currentSelection.constructions?.includes(construction)
                    ? ""
                    : ""
                }`}
              >
                <svg
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fi_15469814"
                  className={` w-3 md:w-5 h-3 md:h-5 ${
                    currentSelection.constructions?.includes(construction)
                      ? "fill-yellow-500 bg-yellow-500 rounded-full"
                      : "fill-yellow-500"
                  }`}
                >
                  <g>
                    <path d="m8 0c-4.41113 0-8 3.58887-8 8s3.58887 8 8 8 8-3.58887 8-8-3.58887-8-8-8zm0 14c-3.30859 0-6-2.69141-6-6s2.69141-6 6-6 6 2.69141 6 6-2.69141 6-6 6z"></path>
                  </g>
                </svg>
                {construction}
              </button>
            ))}
          </div>
        </div>
      </div>
      {/* Welts Pop-up */}
      {showWeltsPopup && (
        <div className="popup">
          <h3>Select Type of Welts</h3>
          <p>Choose the type of welts you prefer for Goodyear construction.</p>
          {/* Add welts options here, such as radio buttons or checkboxes */}
          <button onClick={() => setShowWeltsPopup(false)}>Close</button>
        </div>
      )}

      {/* Colors Section */}
      <div className="mt-4 flex md:flex-row max-md:flex-col items-start  max-xl:gap-3 xl:gap-5">
        <label
          htmlFor="solecolor"
          className="block text-sm xl:text-base font-semibold leading-4 text-[#212121] mt-2 "
        >
          Colors:
        </label>

        <div className="flex flex-wrap  gap-2 ">
          {colors.map((color) => (
            <div class="flex  justify-center items-center   gap-2 ">
              <div className="flex flex-col items-center justify-center">
                <label class="text-slate-400">
                  <input
                    type="checkbox"
                    class="h-[1px] opacity-0 overflow-hidden absolute whitespace-nowrap w-[1px] peer"
                  />
                  <span class="peer-checked:border-[#eebd2d]  peer-checked:text-[#eebd2d] peer-checked:before:border-[#eebd2d] peer-checked:before:bg-[#eebd2d] peer-checked:before:opacity-100 peer-checked:before:scale-100  flex flex-col items-center justify-center w-14 h-14 xl:w-[86px] xl:h-[86px] rounded-lg  transition-all duration-200 cursor-pointer relative border-[#fff] border-2 bg-[#fff] before:absolute before:block max-xl:before:w-1 xl:before:w-2 max-xl:before:h-1 xl:before:h-2 before:border-[3px]  before:rounded-full before:top-1 before:left-1 before:opacity-0 before:transition-transform before:scale-0 before:text-[#fff] before:text-xs  before:items-center before:justify-center hover:border-[#eebd2d] hover:before:scale-100 hover:before:opacity-100">
                    {" "}
                    <div
                      key={color}
                      className="w-10 h-10 xl:w-[70px] xl:h-[70px] rounded-full  flex items-center justify-center border-[1px]"
                      style={{ backgroundColor: color.toLowerCase() }}
                    ></div>
                  </span>
                </label>

                <p className="font-semibold text-xs xl:text-sm">{color}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Save Button */}
      <div className="flex items-center justify-end w-full mt-3">
        <button
          class="bg-white text-center w-20 lg:w-32 rounded-lg h-9 lg:h-11 relative text-[#313131] text-xl font-semibold group border "
          type="button"
          onClick={onNext}
        >
          <div class="bg-[#FFC94A] rounded-md h-[26px] lg:h-[34px] w-1/3 flex items-center justify-center absolute left-1 top-[4px] group-hover:w-[118px] z-10 duration-500 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1024 1024"
              className="rotate-180 w-4 lg:w-6 h-4 lg:h-6"
            >
              <path
                d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
                fill="#313131"
              ></path>
              <path
                d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
                fill="#313131"
              ></path>
            </svg>
          </div>
          <p class="max-lg:translate-x-4 lg:translate-x-5 text-xs lg:text-sm">
            Save
          </p>
        </button>
      </div>
    </div>
  );
};

export default SoleSelection;
