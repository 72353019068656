import { useState, useEffect } from "react";

const LeatherSelection = ({ onNext }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [selectedLeather, setSelectedLeather] = useState("Suede"); // Set the default to the first leather type
  const [leatherSelections, setLeatherSelections] = useState({});
  const [fadeEffect, setFadeEffect] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null); // To store the image URL or path
  const [isModalOpen, setIsModalOpen] = useState(false);

  const leatherTypes = [
    "Suede",
    "Boxcalf",
    "Vegano",
    "Museum",
    "Alligator",
    "Lizard",
    "Crocodile",
    "Vegano2",
    "Museum2",
    "123456789012345",
  ];
  const finishings = ["Patina", "Burnish", "High-Gloss"];
  const colors = [
    "Red",
    "Blue",
    "Green",
    "Violet",
    "Black",
    "Brown",
    "Yellow",
    "Purple",
    "White",
  ];

  const handleLeatherSelect = (type) => {
    setSelectedLeather(type);
    setFadeEffect(true);
    setIsMenuOpen(false); // Close the sidebar upon selection
    if (!leatherSelections[type]) {
      setLeatherSelections((prevSelections) => ({
        ...prevSelections,
        [type]: { finishings: [], colors: [] },
      }));
    }
    // Remove the fade effect after the animation completes (500ms)
    setTimeout(() => setFadeEffect(false), 500);
  };

  const handleFinishingToggle = (finish) => {
    setLeatherSelections((prevSelections) => {
      const currentFinishings =
        prevSelections[selectedLeather]?.finishings || [];
      const newFinishings = currentFinishings.includes(finish)
        ? currentFinishings.filter((f) => f !== finish)
        : [...currentFinishings, finish];
      return {
        ...prevSelections,
        [selectedLeather]: {
          ...prevSelections[selectedLeather],
          finishings: newFinishings,
        },
      };
    });
  };

  const handleColorToggle = (color) => {
    setLeatherSelections((prevSelections) => {
      const currentColors = prevSelections[selectedLeather]?.colors || [];
      const newColors = currentColors.includes(color)
        ? currentColors.filter((c) => c !== color)
        : [...currentColors, color];
      return {
        ...prevSelections,
        [selectedLeather]: {
          ...prevSelections[selectedLeather],
          colors: newColors,
        },
      };
    });
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const handleClickOutside = (e) => {
    const modal = document.getElementById("modal");
    if (modal && !modal.contains(e.target)) {
      setIsModalOpen(false);
    }
  };

  // Add event listener for closing the modal when clicking outside
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const currentSelection = leatherSelections[selectedLeather] || {};

  return (
    <div className="bg-white max-md:p-2 md:p-4 2xl:p-6 rounded-lg shadow-md">
      <div className="lg:hidden max-lg:block">
        {/* Burger Icon for Small Screens */}
        <button
          className="lg:hidden block text-[#212121] py-2 focus:outline-none"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>

        {/* Sidebar for Small Screens */}
        <div
          className={`fixed top-0 left-0 h-full w-64 bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-50 ${
            isMenuOpen ? "translate-x-0" : "-translate-x-full"
          } lg:translate-x-0 lg:relative lg:top-auto lg:left-auto lg:h-auto lg:shadow-none lg:bg-transparent lg:block`}
        >
          <div className="mt-4 max-w-5xl px-4">
            <label
              htmlFor="leatherType"
              className="block text-sm xl:text-base font-semibold leading-4 text-[#212121]"
            >
              Type of Leather:
            </label>
            <div className="flex flex-wrap gap-2 mt-3">
              {leatherTypes.map((type) => {
                const isSelected =
                  leatherSelections[type]?.finishings?.length > 0 ||
                  leatherSelections[type]?.colors?.length > 0;

                return (
                  <button
                    key={type}
                    onClick={() => handleLeatherSelect(type)}
                    className={`px-3 py-2 rounded-md flex items-center gap-1.5 font-medium w-full text-left text-sm xl:text-base ${
                      selectedLeather === type ? "bg-yellow-100" : "bg-white"
                    }`}
                  >
                    {/* Leather SVG Icon */}
                    <svg
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      className={`w-5 h-5 ${
                        isSelected
                          ? "fill-yellow-500 bg-yellow-500 rounded-full"
                          : "fill-yellow-500"
                      }`}
                    >
                      <g>
                        <path d="m8 0c-4.41113 0-8 3.58887-8 8s3.58887 8 8 8 8-3.58887 8-8-3.58887-8-8-8zm0 14c-3.30859 0-6-2.69141-6-6s2.69141-6 6-6 6 2.69141 6 6-2.69141 6-6 6z"></path>
                      </g>
                    </svg>
                    {type}
                  </button>
                );
              })}
            </div>
          </div>
        </div>

        {/* Overlay for Small Screens */}
        {isMenuOpen && (
          <div
            className="fixed inset-0 bg-black opacity-50 z-40"
            onClick={() => setIsMenuOpen(false)}
          />
        )}
      </div>

      {/* Leather Types */}
      <div className="mt-4 max-w-5xl lg:block max-lg:hidden">
        <label
          htmlFor="leatherType"
          className="block text-sm xl:text-base font-semibold leading-4 text-[#212121]"
        >
          Type of Leather:
        </label>
        <div className="flex flex-wrap gap-2 mt-3 ">
          {leatherTypes.map((type) => {
            const isSelected =
              leatherSelections[type]?.finishings?.length > 0 ||
              leatherSelections[type]?.colors?.length > 0;

            return (
              <button
                key={type}
                onClick={() => handleLeatherSelect(type)}
                className={`px-3 py-2 rounded-md flex items-center gap-1.5 font-medium w-48 text-sm xl:text-base ${
                  selectedLeather === type ? "bg-yellow-100" : "bg-white"
                }`}
              >
                {/* Leather SVG Icon */}
                <svg
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fi_15469814"
                  className={`w-5 h-5 ${
                    isSelected
                      ? "fill-yellow-500 bg-yellow-500 rounded-full"
                      : "fill-yellow-500"
                  }`}
                >
                  <g>
                    <path d="m8 0c-4.41113 0-8 3.58887-8 8s3.58887 8 8 8 8-3.58887 8-8-3.58887-8-8-8zm0 14c-3.30859 0-6-2.69141-6-6s2.69141-6 6-6 6 2.69141 6 6-2.69141 6-6 6z"></path>
                  </g>
                </svg>
                {type}
              </button>
            );
          })}
        </div>
      </div>

      <div className="rounded-xl border-2 border-gray-200 max-xl:p-2.5 xl:p-4 max-lg:mt-0 lg:mt-3 max-w-4xl">
        {/* Finishing Types */}
        <div
          className={`mb-4 flex md:flex-row max-md:flex-col items-start max-xl:gap-3 xl:gap-5 ${
            fadeEffect ? "fade-effect" : ""
          }`}
        >
          <label
            htmlFor="leatherFinishing"
            className="block text-sm xl:text-base font-semibold leading-4 text-[#212121] mt-2"
          >
            Finishing:
          </label>
          <div className="flex max-xl:gap-x-2 xl:gap-x-5 flex-wrap">
            {finishings.map((finish) => (
              <button
                key={finish}
                onClick={() => handleFinishingToggle(finish)}
                className={` rounded-md flex items-center text-sm xl:text-base gap-1.5 font-medium px-2 py-2 hover:bg-yellow-100 ${
                  currentSelection.finishings?.includes(finish) ? "" : ""
                }`}
              >
                <svg
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fi_15469814"
                  className={` w-3 md:w-5 h-3 md:h-5 ${
                    currentSelection.finishings?.includes(finish)
                      ? "fill-yellow-500 bg-yellow-500 rounded-full"
                      : "fill-yellow-500"
                  }`}
                >
                  <g>
                    <path d="m8 0c-4.41113 0-8 3.58887-8 8s3.58887 8 8 8 8-3.58887 8-8-3.58887-8-8-8zm0 14c-3.30859 0-6-2.69141-6-6s2.69141-6 6-6 6 2.69141 6 6-2.69141 6-6 6z"></path>
                  </g>
                </svg>
                {finish}
                {/* Eye Icon for Image Preview */}
                <button
                  onClick={
                    () => handleImageClick(`/images/logo3.png`) // Correct the image URL path
                  }
                  className="ml-1 text-blue-500"
                >
                  <svg
                    id="fi_2874780"
                    enable-background="new 0 0 519.644 519.644"
                    className="w-3 md:w-5 h-3 md:h-5"
                    viewBox="0 0 519.644 519.644"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m259.822 427.776c-97.26 0-190.384-71.556-251.854-145.843-10.623-12.839-10.623-31.476 0-44.314 15.455-18.678 47.843-54.713 91.108-86.206 108.972-79.319 212.309-79.472 321.492 0 50.828 36.997 91.108 85.512 91.108 86.206 10.623 12.838 10.623 31.475 0 44.313-61.461 74.278-154.572 145.844-251.854 145.844zm0-304c-107.744 0-201.142 102.751-227.2 134.243-.842 1.018-.842 2.496 0 3.514 26.059 31.492 119.456 134.243 227.2 134.243s201.142-102.751 227.2-134.243c1.519-1.837-.1-3.514 0-3.514-26.059-31.492-119.456-134.243-227.2-134.243z"></path>
                    <path d="m259.822 371.776c-61.757 0-112-50.243-112-112s50.243-112 112-112 112 50.243 112 112-50.243 112-112 112zm0-192c-44.112 0-80 35.888-80 80s35.888 80 80 80 80-35.888 80-80-35.888-80-80-80z"></path>
                  </svg>
                </button>
              </button>
            ))}
          </div>
        </div>

        {/* Image Modal (Popup) */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex flex-col justify-center items-center z-50">
            <div className="group flex flex-col items-center justify-center ">
              <button
                onClick={handleCloseModal}
                className="group-hover:-translate-y-4  translate-y-12 duration-300 ease-in-out z-40"
              >
                <svg
                  height="24pt"
                  viewBox="0 0 512 512"
                  width="24pt"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fi_1828945"
                  className=" hover:fill-red-500 hover:bg-red-200 rounded-full duration-300 ease-in-out"
                >
                  <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path>
                  <path d="m176.8125 351.1875c-4.097656 0-8.195312-1.554688-11.308594-4.691406-6.25-6.25-6.25-16.382813 0-22.632813l158.398438-158.402343c6.253906-6.25 16.386718-6.25 22.636718 0s6.25 16.382812 0 22.636718l-158.402343 158.398438c-3.15625 3.136718-7.25 4.691406-11.324219 4.691406zm0 0"></path>
                  <path d="m335.1875 351.1875c-4.09375 0-8.191406-1.554688-11.304688-4.691406l-158.398437-158.378906c-6.253906-6.25-6.253906-16.382813 0-22.632813 6.25-6.253906 16.382813-6.253906 22.632813 0l158.398437 158.398437c6.253906 6.25 6.253906 16.382813 0 22.632813-3.132813 3.117187-7.230469 4.671875-11.328125 4.671875zm0 0"></path>
                </svg>
              </button>
              <div className=" bg-white p-5 rounded-lg z-50">
                <img
                  src={selectedImage}
                  alt="Finishing preview"
                  className="max-w-full max-h-[30vh] object-cover"
                />
              </div>
            </div>
          </div>
        )}

        {/* Colors Section */}
        <div
          className={`mb-4 flex md:flex-row max-md:flex-col items-start  max-xl:gap-3 xl:gap-5  ${
            fadeEffect ? "fade-effect" : ""
          }`}
        >
          <label
            htmlFor="leathercolor"
            className="block text-sm xl:text-base font-semibold leading-4 text-[#212121] mt-2 "
          >
            Colors:
          </label>
          <div className="flex flex-wrap  gap-2 ">
            {/* {colors.map((color) => (
              <button
                key={color}
                onClick={() => handleColorToggle(color)}
                className={`w-12 h-12 rounded-full border-2 flex items-center justify-center ${
                  currentSelection.colors?.includes(color)
                    ? "border-black"
                    : "border-transparent"
                }`}
                style={{ backgroundColor: color.toLowerCase() }}
              >
                {currentSelection.colors?.includes(color) && (
                  <svg
                    className="w-5 h-5 text-white"
                    height="512pt"
                    viewBox="0 0 512 512"
                    width="512pt"
                    xmlns="http://www.w3.org/2000/svg"
                    id="fi_1617543"
                  >
                    <path d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0"></path>
                    <path d="m347.429688 365.714844c-4.679688 0-9.359376-1.785156-12.929688-5.359375l-182.855469-182.855469c-7.144531-7.144531-7.144531-18.714844 0-25.855469 7.140625-7.140625 18.714844-7.144531 25.855469 0l182.855469 182.855469c7.144531 7.144531 7.144531 18.714844 0 25.855469-3.570313 3.574219-8.246094 5.359375-12.925781 5.359375zm0 0"></path>
                    <path d="m164.570312 365.714844c-4.679687 0-9.355468-1.785156-12.925781-5.359375-7.144531-7.140625-7.144531-18.714844 0-25.855469l182.855469-182.855469c7.144531-7.144531 18.714844-7.144531 25.855469 0 7.140625 7.140625 7.144531 18.714844 0 25.855469l-182.855469 182.855469c-3.570312 3.574219-8.25 5.359375-12.929688 5.359375zm0 0"></path>
                  </svg>
                )}
              </button>
            ))} */}
            {colors.map((color) => (
              <div class="flex  justify-center items-center   gap-2 ">
                <div className="flex flex-col items-center justify-center">
                  <label class="text-slate-400">
                    <input
                      type="checkbox"
                      class="h-[1px] opacity-0 overflow-hidden absolute whitespace-nowrap w-[1px] peer"
                      checked={currentSelection.colors?.includes(color)}
                      onChange={() => handleColorToggle(color)}
                    />
                    <span class="peer-checked:border-[#eebd2d]  peer-checked:text-[#eebd2d] peer-checked:before:border-[#eebd2d] peer-checked:before:bg-[#eebd2d] peer-checked:before:opacity-100 peer-checked:before:scale-100  flex flex-col items-center justify-center w-14 h-14 xl:w-[86px] xl:h-[86px] rounded-lg  transition-all duration-200 cursor-pointer relative border-[#fff] border-2 bg-[#fff] before:absolute before:block max-xl:before:w-1 xl:before:w-2 max-xl:before:h-1 xl:before:h-2 before:border-[3px]  before:rounded-full before:top-1 before:left-1 before:opacity-0 before:transition-transform before:scale-0 before:text-[#fff] before:text-xs  before:items-center before:justify-center hover:border-[#eebd2d] hover:before:scale-100 hover:before:opacity-100">
                      {" "}
                      <div
                        key={color}
                        className="w-10 h-10 xl:w-[70px] xl:h-[70px] rounded-full  flex items-center justify-center border-[1px]"
                        style={{ backgroundColor: color.toLowerCase() }}
                      ></div>
                    </span>
                  </label>

                  <p className="font-semibold text-xs xl:text-sm">{color}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="group   flex items-center gap-2 mt-3 px-1">
        <label
          htmlFor="leatherConsumption"
          className="block text-sm xl:text-base font-semibold leading-4 text-[#212121]"
        >
          Leather Consumption:
        </label>
        <div className=" relative ">
          {/* <input
            name="leatherConsumption"
            className="px-2 block w-full  rounded-md  py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 group-hover:ring-2 group-hover:ring-[#eebd2d] group-hover:outline-none group-hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-sm sm:leading-6 "
            id="leatherConsumption"
            type="number"
            placeholder=""
            required
          /> */}
          <input
            id="leatherConsumption"
            name="leatherConsumption"
            type="number"
            class="ml-1 border-b-2 block w-28 md:w-40 xl:w-full border-gray-300 group focus:border-b-2 hover:border-[#eebd2d] focus:border-[#eebd2d] transition-colors focus:outline-none bg-inherit"
          />
          <div class=" absolute text-sm xl:text-base max-lg:right-3 lg:right-4 max-xl:top-1 xl:top-0 font-semibold ">
            dm
          </div>
        </div>
      </div>

      {/* Save Button */}
      <div className="flex items-center justify-end w-full mt-3">
        <button
          class="bg-white text-center w-20 lg:w-32 rounded-lg h-9 lg:h-11 relative text-[#313131] text-xl font-semibold group border "
          type="button"
          onClick={onNext}
        >
          <div class="bg-[#FFC94A] rounded-md h-[26px] lg:h-[34px] w-1/3 flex items-center justify-center absolute left-1 top-[4px] group-hover:w-[118px] z-10 duration-500 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1024 1024"
              className="rotate-180 w-4 lg:w-6 h-4 lg:h-6"
            >
              <path
                d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
                fill="#313131"
              ></path>
              <path
                d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
                fill="#313131"
              ></path>
            </svg>
          </div>
          <p class="max-lg:translate-x-4 lg:translate-x-5 text-xs lg:text-sm">
            Save
          </p>
        </button>
      </div>
    </div>
  );
};

export default LeatherSelection;
