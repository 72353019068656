import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { RiUploadCloudFill } from "react-icons/ri";
import secureLocalStorage from "react-secure-storage";

function Sole() {
  const storedData = secureLocalStorage.getItem("materialCost");
  const materialCost = JSON.parse(storedData);
  const [selectedSole, setselectedSole] = useState("Italian_leather");
  const [selectedLace, setSelectedLace] = useState("");
  const [selectedOne, setSelectedOne] = useState("Constructions_Add");
  const [dataFetched, setDataFetched] = useState(false);
  const [itemExist, setItemExist] = useState(false);
  const [materialState, setMaterialState] = useState({});
  const [soleOptions, setsoleOptions] = useState([]);
  const [weltOptions, setWeltOptions] = useState([]);
  const [constructionOptions, setConstructionOptions] = useState([]);
  const [edgeOptions, setEdgeOptions] = useState([]);
  const [isOpen, setIsOpen] = useState("");
  const [isDrop, setIsDrop] = useState("");
  const popupRef = useRef(null);

  // Function to handle clicks outside the popup
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen("");
      setMaterialState({});
    }
  };

  // Effect to add and clean up the event listener
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (!dataFetched && storedData === null) {
      fetch("http://127.0.0.1:8000/api/get-material-cost/", {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((data) => {
          secureLocalStorage.setItem(
            "materialCost",
            JSON.stringify(data.materials)
          );
          setDataFetched(true);
        })
        .catch((error) => console.error("Error:", error));
    } else {
      materialCost.forEach((item) => {
        if (item.Soles) {
          setsoleOptions(item.Soles);
        }
        if (item.Constructions) {
          setConstructionOptions(item.Constructions);
        }
        if (item.Edges) {
          setEdgeOptions(item.Edges);
        }
        if (item.Welts) {
          setWeltOptions(item.Welts);
        }
      });
      setDataFetched(true);
    }
  }, [dataFetched]);

  const scrollContainerRef = useRef(null);

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 200; // Adjust the scroll amount as needed
      scrollContainerRef.current.scrollBy({
        left: direction === "right" ? scrollAmount : -scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const EditIcon = () => (
    <svg
      viewBox="0 0 492.49284 492"
      className="w-3 h-3"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M304.14 82.47L33.16 353.47c-1.36 1.37-2.35 3.09-2.82 4.95L0.3 479c-.9 3.63.17 7.49 2.82 10.14a10.01 10.01 0 007.53 3.11c.85 0 1.73-.11 2.58-.32l120.55-30.04c1.88-.47 3.59-1.45 4.95-2.81l271-271zM476.87 45.52l-30.16-30.16c-20.16-20.16-55.3-20.14-75.43 0l-36.95 36.95 105.6 105.6 36.95-36.95c10.07-10.07 15.62-23.47 15.62-37.71s-5.55-27.65-15.62-37.72z" />
    </svg>
  );

  const MeasurementIcon = () => (
    <svg
      viewBox="0 0 401.998 401.998"
      className="w-3 h-3"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M326.62 91.08c-1.71-1.71-3.9-2.57-6.56-2.57h-48.82c-3.24-15.79-9.33-29.5-18.27-41.11h66.52c2.67 0 4.85-.86 6.57-2.57 1.7-1.71 2.56-3.9 2.56-6.57V9.14c0-2.67-.85-4.85-2.56-6.57-1.71-1.71-3.9-2.57-6.57-2.57H81.94c-2.67 0-4.85.86-6.57 2.57-1.71 1.71-2.57 3.9-2.57 6.57v37.97c0 2.47.9 4.61 2.71 6.42 1.81 1.81 3.95 2.71 6.42 2.71h41.4c40.16 0 65.67 10.75 76.51 32.26H81.94c-2.67 0-4.86.86-6.57 2.57-1.71 1.71-2.57 3.9-2.57 6.57v29.12c0 2.66.86 4.85 2.57 6.56s3.9 2.57 6.57 2.57h121.91c-4.19 15.61-13.94 27.5-29.27 35.69-15.32 8.18-35.54 12.28-60.67 12.28H81.94c-2.47 0-4.62.91-6.42 2.71s-2.71 3.95-2.71 6.42v36.26c0 2.48.86 4.57 2.57 6.28 36.54 38.83 83.94 93.17 142.18 163.03 1.71 2.29 4.09 3.43 7.14 3.43h55.67c4 0 6.76-1.71 8.28-5.14 1.9-3.43 1.53-6.66-1.14-9.71-55.57-68.14-99.26-119.15-131.05-153.03 32.36-3.81 58.63-14.28 78.8-31.4 20.17-17.13 32.45-39.4 36.83-66.81h47.97c2.66 0 4.85-.86 6.56-2.57s2.57-3.9 2.57-6.56V97.65c0-2.67-.86-4.85-2.57-6.56z" />
    </svg>
  );

  const handleAddOREdit = (item) => {
    if (item.split("_")[1] === "Add") {
      let hasKey = false;
      if (item === "Soles_Add") {
        if (
          materialState.type &&
          materialState.img &&
          materialState.days &&
          materialState.price
        ) {
          const matchedKey = Object?.keys(soleOptions).find(
            (data) => data === selectedSole
          );
          if (matchedKey) {
            hasKey = !!Object?.entries(soleOptions[matchedKey]).find(
              ([key, value]) =>
                materialState.type.toLowerCase() === value.Color.toLowerCase()
            );
          }
        } else {
          alert("Please fill out all the fields before adding.");
          return;
        }
      } else if (
        item === "Constructions_Add" ||
        item === "Welts_Add" ||
        item === "Edges_Add"
      ) {
        const Options =
          item === "Constructions_Add"
            ? constructionOptions
            : "Welts_Add"
            ? weltOptions
            : item === "Edges_Add" && edgeOptions;
        if (materialState.type && materialState.img && materialState.price) {
          hasKey = !!Object?.keys(Options).find(
            (data) =>
              data.toLowerCase().replace(/_/g, " ") ===
              materialState.type.toLowerCase()
          );
        } else {
          alert("Please fill out all the fields before adding.");
          return;
        }
      }

      if (hasKey) {
        setItemExist(true);
      } else {
        setItemExist(false);
        if (item === "Soles_Add") {
          const itemObj = materialCost.find(
            (element) => element.item === "Soles"
          );
          if (itemObj && itemObj["Soles"]) {
            if (itemObj["Soles"][materialState.var]) {
              const uId = Object.keys(itemObj["Soles"][materialState.var]);
              let newEntry = null;
              if (uId.length > 0) {
                uId.sort((a, b) => {
                  const numA = parseInt(a.replace("lmus", ""), 10);
                  const numB = parseInt(b.replace("lmus", ""), 10);
                  return numA - numB;
                });
                const highestNum = parseInt(
                  uId[uId.length - 1].replace("lmus", ""),
                  10
                );
                newEntry = `lmus${highestNum + 1}`;
              } else {
                newEntry = `lmus1`;
              }
              itemObj["Soles"][materialState.var][newEntry] = {
                Color: materialState.type,
                Price: parseFloat(materialState.price),
                Img: materialState.img,
                Delivery_time: parseInt(materialState.days),
              };
            }
          }
        } else if (
          item === "Constructions_Add" ||
          item === "Welts_Add" ||
          item === "Edges_Add"
        ) {
          const itemObj = materialCost.find(
            (element) => element.item === item.split("_")[0]
          );
          if (itemObj && itemObj[item.split("_")[0]]) {
            if (item === "Edges_Add") {
              itemObj[item.split("_")[0]][materialState.type] = {
                Price: parseFloat(materialState.price),
                Img: materialState.img,
                Color: {},
              };
            } else {
              itemObj[item.split("_")[0]][materialState.type] = {
                Price: parseFloat(materialState.price),
                Img: materialState.img,
              };
            }
          }
        } else if (item === "Edge Color_Add") {
          const itemObj = materialCost.find(
            (element) => element.item === "Edges"
          );
          if (itemObj && itemObj["Edges"]) {
            itemObj["Edges"][materialState.var]["Color"][materialState.type] =
              materialState.img;
          }
        }
        secureLocalStorage.setItem(
          "materialCost",
          JSON.stringify(materialCost)
        );
        setIsOpen("");
        setMaterialState({});
      }
    } else {
      if (item === "Soles_Edit") {
        const itemObj = materialCost.find(
          (element) => element.item === "Soles"
        );
        if (itemObj && itemObj["Soles"]) {
          if (itemObj["Soles"][materialState.var]) {
            if (itemObj["Soles"][materialState.var][materialState.type]) {
              itemObj["Soles"][materialState.var][materialState.type].Price =
                parseFloat(materialState.price);
              itemObj["Soles"][materialState.var][
                materialState.type
              ].Delivery_time = parseInt(materialState.days);
            }
          }
        }
      } else if (
        item === "Constructions_Edit" ||
        item === "Welts_Edit" ||
        item === "Edges_Edit"
      ) {
        const itemObj = materialCost.find(
          (element) => element.item === materialState.var
        );
        if (itemObj && itemObj[materialState.var]) {
          if (itemObj[materialState.var][materialState.type]) {
            itemObj[materialState.var][materialState.type].Price = parseFloat(
              materialState.price
            );
          }
        }
      }
      secureLocalStorage.setItem("materialCost", JSON.stringify(materialCost));
      setIsOpen("");
    }
    materialCost.forEach((item) => {
      if (item.Soles) {
        setsoleOptions(item.Soles);
      }
      if (item.Constructions) {
        setConstructionOptions(item.Constructions);
      }
      if (item.Edges) {
        setEdgeOptions(item.Edges);
      }
      if (item.Welts) {
        setWeltOptions(item.Welts);
      }
    });
  };

  const handleSave = (item) => {
    fetch(`http://127.0.0.1:8000/api/set-material-cost/`, {
      method: "POST",
      body: JSON.stringify({
        material: item,
        data: materialCost,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        alert(data.message);
        secureLocalStorage.setItem(
          "materialCost",
          JSON.stringify(data.materials)
        );
        materialCost.forEach((item) => {
          if (item.Soles) {
            setsoleOptions(item.Soles);
          }
          if (item.Constructions) {
            setConstructionOptions(item.Constructions);
          }
          if (item.Edges) {
            setEdgeOptions(item.Edges);
          }
          if (item.Welts) {
            setWeltOptions(item.Welts);
          }
        });
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  return (
    <div className="flex justify-between gap-4 w-full">
      {/* soles */}
      <div className="flex flex-col gap-3 w-[48%] text-black">
        {/* Header Section */}
        <div className="flex items-center  duration-300 rounded-md justify-between w-[100%] mx-auto bg-white shadow-md ">
          {/* Left Arrow */}
          <button
            className="bg-[#FFD61F] hover:bg-[#FEC23D] duration-200 h-full flex items-center justify-center text-lg p-2 rounded-l-md cursor-pointer  shadow-[4px_0px_50px_1px_#00000024]"
            onClick={() => handleScroll("left")}
          >
            <svg
              version="1.1"
              id="fi_45206"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsHlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="541.07px"
              height="541.071px"
              viewBox="0 0 541.07 541.071"
              style={{ enableBackground: "new 0 0 541.07 541.071" }}
              className="w-4 h-4"
              xmlSpace="preserve"
            >
              <g>
                <g>
                  <path
                    d="M1.792,277.575l142.611,162.657c2.39,4.779,8.66,8.653,14.002,8.653H290.32c5.343,0,7.738-3.874,5.349-8.653
			L153.058,277.575c-2.39-4.78-2.39-12.531,0-17.311l142.611-159.432c2.39-4.78-0.006-8.654-5.349-8.654H158.406
			c-10.019,0-16.181,10.891-21.934,17.531C128.859,118.498,23.148,236.387,1.79,260.264C-0.597,265.047-0.597,272.794,1.792,277.575
			z"
                  ></path>
                  <path
                    d="M540.145,100.835c2.39-4.78-0.006-8.654-5.349-8.654c0,0-131.914,0-131.917,0c-12.213,0.003-28.259,24.593-36.228,33.504
			c-14.021,15.673-119.618,133.052-120.383,134.585c-2.39,4.78-2.39,12.531,0,17.311l142.611,162.657
			c2.391,4.78,8.66,8.654,14.003,8.654h131.914c5.343,0,7.738-3.874,5.349-8.654L397.533,277.581c-2.39-4.779-2.39-12.531,0-17.311
			L540.145,100.835z"
                  ></path>
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </button>
          {/* Scrollable Header Container */}
          <div
            ref={scrollContainerRef}
            className="flex gap-2 overflow-x-scroll no-scrollbar w-full p-2 border-y"
          >
            {Object?.keys(soleOptions)?.map((sole) => (
              <button
                key={sole}
                href="#"
                className={`rounded-md px-3 py-1 text-sm font-medium text-black text-center whitespace-nowrap ${
                  selectedSole === sole
                    ? `bg-[#FFD61F]`
                    : "hover:bg-[#FFF4B5] duration-200"
                }`}
                onClick={() => {
                  setselectedSole(sole);
                  setMaterialState((prev) => ({}));
                }}
              >
                {sole.replace(/_/g, " ")}
              </button>
            ))}
          </div>
          {/* Right Arrow */}
          <button
            className="bg-[#FFD61F] hover:bg-[#FEC23D] duration-200 h-full flex items-center justify-center text-lg p-2 rounded-r-md cursor-pointer"
            onClick={() => handleScroll("right")}
          >
            <svg
              version="1.1"
              id="fi_724927"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsHlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 480.026 480.026"
              style={{ enablebackground: "new 0 0 480.026 480.026" }}
              className="w-4 h-4"
              xmlSpace="preserve"
            >
              <g>
                <g>
                  <path
                    d="M475.922,229.325l-144-160c-3.072-3.392-7.36-5.312-11.904-5.312h-96c-6.304,0-12.032,3.712-14.624,9.472
			c-2.56,5.792-1.504,12.544,2.72,17.216l134.368,149.312l-134.368,149.28c-4.224,4.704-5.312,11.456-2.72,17.216
			c2.592,5.792,8.32,9.504,14.624,9.504h96c4.544,0,8.832-1.952,11.904-5.28l144-160
			C481.394,244.653,481.394,235.373,475.922,229.325z"
                  ></path>
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M267.922,229.325l-144-160c-3.072-3.392-7.36-5.312-11.904-5.312h-96c-6.304,0-12.032,3.712-14.624,9.472
			c-2.56,5.792-1.504,12.544,2.72,17.216l134.368,149.312L4.114,389.293c-4.224,4.704-5.312,11.456-2.72,17.216
			c2.592,5.792,8.32,9.504,14.624,9.504h96c4.544,0,8.832-1.952,11.904-5.28l144-160
			C273.394,244.653,273.394,235.373,267.922,229.325z"
                  ></path>
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </button>
        </div>

        {/* Table Section */}

        <div className="rounded-lg border-[1px] shadow-md  mt-2 flex flex-col text-sm ">
          {/* price & Save Button */}
          <div className="w-full flex items-end justify-between p-2">
            <button
              className="px-3 py-2 font-semibold rounded-md bg-black text-white text-sm flex items-center gap-2 shadow-2xl hover:shadow-blue-500/20 duration-200"
              onClick={() => {
                setIsOpen("Soles_Add");
              }}
            >
              Add
              <svg
                version="1.1"
                id="fi_992651"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsHlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                className="w-4 h-4  duration-300 group-hover:rotate-180"
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512", fill: "white" }}
                xmlSpace="preserve"
              >
                <g>
                  <g>
                    <path
                      d="M256,0C114.833,0,0,114.833,0,256s114.833,256,256,256s256-114.853,256-256S397.167,0,256,0z M256,472.341
			c-119.275,0-216.341-97.046-216.341-216.341S136.725,39.659,256,39.659S472.341,136.705,472.341,256S375.295,472.341,256,472.341z
			"
                    ></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M355.148,234.386H275.83v-79.318c0-10.946-8.864-19.83-19.83-19.83s-19.83,8.884-19.83,19.83v79.318h-79.318
			c-10.966,0-19.83,8.884-19.83,19.83s8.864,19.83,19.83,19.83h79.318v79.318c0,10.946,8.864,19.83,19.83,19.83
			s19.83-8.884,19.83-19.83v-79.318h79.318c10.966,0,19.83-8.884,19.83-19.83S366.114,234.386,355.148,234.386z"
                    ></path>
                  </g>
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </button>
            <button
              className="border-2 border-gray-400 text-center w-[140px] rounded-lg h-10 relative text-black font-semibold group"
              type="button"
              onClick={() => handleSave("Soles")}
            >
              <div className="bg-white rounded-lg h-[30px] w-[25%] flex items-center justify-center absolute left-1 top-[4px] group-hover:w-[130px] z-10 duration-500">
                <RiUploadCloudFill className="text-black" />
              </div>
              <p className="translate-x-2 text-sm">Save</p>
            </button>
          </div>

          <div className="flex flex-col items-center justify-between gap-2 w-full overflow-y-scroll no-scrollbar mt-1 p-3 border-t">
            {Object?.keys(soleOptions)
              .filter((item) => item === selectedSole)
              ?.map((item, index) => {
                const soleData = soleOptions[item];
                return Object?.keys(soleData).length > 0 ? (
                  Object?.entries(soleData)?.map(([key, value], subIndex) => (
                    <div
                      key={`${index}-${subIndex}`}
                      className="flex items-center justify-between px-2 py-2 w-full border-b duration-200 hover:bg-zinc-100"
                    >
                      <span className="flex items-center gap-1.5 border rounded-full pl-1 pr-2 py-0.5 w-1/3">
                        <img
                          src={value.Img}
                          className="w-8 rounded-full"
                          alt={value.Color}
                        />
                        <p className="">{value.Color.replace(/_/g, " ")}</p>
                      </span>

                      <p className="text-center w-1/3">#{key}</p>
                      <p className="text-center w-1/3">
                        {value.Delivery_time} days
                      </p>
                      <p className="flex items-center justify-start w-1/3">
                        <span className="mx-auto flex items-center justify-start gap-1">
                          <span>
                            <MeasurementIcon />
                          </span>
                          {value.Price}
                        </span>
                      </p>
                      <button
                        className="bg-[#FFF4B5] hover:bg-[#FFD61F] duration-200 p-2.5 flex items-center justify-center rounded-lg cursor-pointer"
                        onClick={() => {
                          setIsOpen("Soles_Edit");
                          setMaterialState((prevState) => ({
                            ...prevState,
                            var: selectedSole,
                            type: key,
                            days: value.Delivery_time,
                            price: value.Price,
                          }));
                        }}
                      >
                        <EditIcon />
                      </button>
                    </div>
                  ))
                ) : (
                  <p>No soles Added.</p>
                );
              })}
          </div>
        </div>
      </div>

      {/* Others */}
      <div className="flex flex-col gap-3 w-[48%] text-black">
        {/* Header Section */}
        <div className="flex items-center gap-2 duration-300 justify-between border rounded-md w-full mx-auto bg-white shadow-md ">
          {/* Scrollable Header Container */}
          <div className="flex items-center justify-between gap-2 p-2 w-full">
            <button
              className={`rounded-md px-3 py-1 text-sm font-medium text-black text-center whitespace-nowrap ${
                selectedOne === "Constructions_Add" ? "bg-[#FFD61F]" : ""
              } hover:bg-[#FFF4B5] duration-200`}
              onClick={() => {
                setSelectedOne("Constructions_Add");
                setMaterialState((prev) => ({}));
              }}
            >
              Construction
            </button>
            <button
              className={`rounded-md px-3 py-1 text-sm font-medium text-black text-center whitespace-nowrap ${
                selectedOne === "Welts_Add" ? "bg-[#FFD61F]" : ""
              } hover:bg-[#FFF4B5] duration-200`}
              onClick={() => {
                setSelectedOne("Welts_Add");
                setMaterialState((prev) => ({}));
              }}
            >
              Welt
            </button>
            <button
              className={`rounded-md px-3 py-1 text-sm font-medium text-black text-center whitespace-nowrap ${
                selectedOne === "Edges_Add" ? "bg-[#FFD61F]" : ""
              } hover:bg-[#FFF4B5] duration-200`}
              onClick={() => {
                setSelectedOne("Edges_Add");
                setMaterialState((prev) => ({}));
              }}
            >
              Edge
            </button>
          </div>
        </div>

        {/* Table Section */}

        <div className="rounded-lg border-[1px] shadow-md  mt-2 flex flex-col text-sm ">
          {/* price & Save Button */}
          <span className="w-full flex items-end justify-between p-2">
            <button
              className="px-3 py-2 font-semibold rounded-md bg-[#7741FB] text-white text-sm flex items-center gap-2 shadow-2xl hover:shadow-blue-500/20 duration-200"
              onClick={() => {
                if (selectedOne === "Constructions_Add")
                  setIsOpen("Constructions_Add");
                else if (selectedOne === "Welts_Add") setIsOpen("Welts_Add");
                else if (selectedOne === "Edges_Add") setIsOpen("Edges_Add");
              }}
            >
              Add
              <svg
                version="1.1"
                id="fi_992651"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsHlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                className="w-4 h-4  duration-300 group-hover:rotate-180"
                viewBox="0 0 512 512"
                style={{ enableBackground: "new 0 0 512 512", fill: "white" }}
                xmlSpace="preserve"
              >
                <g>
                  <g>
                    <path
                      d="M256,0C114.833,0,0,114.833,0,256s114.833,256,256,256s256-114.853,256-256S397.167,0,256,0z M256,472.341
c-119.275,0-216.341-97.046-216.341-216.341S136.725,39.659,256,39.659S472.341,136.705,472.341,256S375.295,472.341,256,472.341z
"
                    ></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M355.148,234.386H275.83v-79.318c0-10.946-8.864-19.83-19.83-19.83s-19.83,8.884-19.83,19.83v79.318h-79.318
c-10.966,0-19.83,8.884-19.83,19.83s8.864,19.83,19.83,19.83h79.318v79.318c0,10.946,8.864,19.83,19.83,19.83
s19.83-8.884,19.83-19.83v-79.318h79.318c10.966,0,19.83-8.884,19.83-19.83S366.114,234.386,355.148,234.386z"
                    ></path>
                  </g>
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </button>

            <button
              className="border text-center w-[140px] rounded-lg h-10 relative text-black font-semibold group"
              type="button"
              onClick={() => handleSave("Soles_Parts")}
            >
              <div className="bg-[#7741FB] rounded-lg h-[30px] w-[25%] flex items-center justify-center absolute left-1 top-[4px] group-hover:w-[130px] z-10 duration-500">
                <RiUploadCloudFill className="text-white" />
              </div>
              <p className="translate-x-2 text-sm">Save</p>
            </button>
          </span>
          {selectedOne !== "" && (
            <div className="flex flex-col items-center justify-between gap-2 w-full overflow-y-scroll no-scrollbar mt-1 p-3 border-t">
              {Object?.keys(
                selectedOne === "Constructions_Add"
                  ? constructionOptions
                  : selectedOne === "Welts_Add"
                  ? weltOptions
                  : selectedOne === "Edges_Add" && edgeOptions
              ).length === 0 ? (
                <p>No material added.</p>
              ) : (
                Object?.entries(
                  selectedOne === "Constructions_Add"
                    ? constructionOptions
                    : selectedOne === "Welts_Add"
                    ? weltOptions
                    : selectedOne === "Edges_Add" && edgeOptions
                )?.map(([key, value], index) => {
                  return (
                    <>
                      <div
                        key={index}
                        className="flex items-center justify-between px-2 py-2 w-full border-b duration-200 hover:bg-zinc-100"
                      >
                        <span className="flex items-center gap-1.5 border rounded-full pl-1 pr-2 py-0.5 w-1/3">
                          {selectedOne !== "Overheads_Add" && (
                            <img
                              src={value.Img}
                              className="w-8 rounded-full"
                              alt={value.Color || key}
                            />
                          )}
                          <p>{key.replace(/_/g, " ")}</p>
                        </span>
                        <p className="flex items-center justify-start w-1/3">
                          <span className="mx-auto flex items-center justify-start gap-1">
                            <span>
                              <MeasurementIcon />
                            </span>
                            {value.Price}
                          </span>
                        </p>
                        <button
                          className="bg-[#FFF4B5] hover:bg-[#FFD61F] duration-200 p-2.5 flex items-center justify-center rounded-lg cursor-pointer"
                          onClick={() => {
                            setIsOpen(selectedOne.replace("Add", "Edit"));
                            setMaterialState((prevState) => {
                              return {
                                ...prevState,
                                var: selectedOne.split("_")[0],
                                type: key,
                                price: value.Price,
                              };
                            });
                          }}
                        >
                          <EditIcon />
                        </button>
                        {selectedOne === "Edges_Add" && (
                          <button
                            className={`${
                              isDrop === key && "rotate-180"
                            } duration-200 p-1 flex items-center justify-center rounded-lg cursor-pointer`}
                            onClick={() => {
                              if (isDrop === key) setIsDrop("");
                              else setIsDrop(key);
                            }}
                          >
                            <IoIosArrowDropdownCircle size={20} />
                          </button>
                        )}
                      </div>
                      {isDrop === key && (
                        <>
                          <div className="grid grid-cols-4 gap-2 px-2 py-2 border-b w-full rounded-md duration-200 hover:bg-zinc-100">
                            {Object?.keys(value?.Color)?.length > 0 ? (
                              <>
                                {Object?.entries(value?.Color)?.map(
                                  ([key2, value2]) => (
                                    <span
                                      key={key2}
                                      className="flex items-center gap-1.5 w-full border rounded-full pl-1 pr-2 py-0.5"
                                    >
                                      <img
                                        src={value2}
                                        className="w-8 rounded-full"
                                        alt={key2}
                                      />
                                      <p>{key2.replace(/_/g, " ")}</p>
                                    </span>
                                  )
                                )}
                                <button
                                  className="bg-[#FFF4B5] hover:bg-[#FFD61F] duration-200 p-2.5 flex items-center justify-center rounded-lg cursor-pointer"
                                  onClick={() => {
                                    setIsOpen(
                                      selectedOne === "Edges_Add" &&
                                        "Edge Color_Add"
                                    );
                                    setSelectedLace(key);
                                  }}
                                >
                                  <EditIcon />
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="bg-[#FFF4B5] hover:bg-[#FFD61F] duration-200 p-2.5 flex items-center justify-center rounded-lg cursor-pointer"
                                  onClick={() => {
                                    setIsOpen(
                                      selectedOne === "Edges_Add" &&
                                        "Edge Color_Add"
                                    );
                                    setSelectedLace(key);
                                  }}
                                >
                                  <EditIcon />
                                </button>
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  );
                })
              )}
            </div>
          )}
        </div>
      </div>

      {/* Add / Edit popup */}
      <>
        {isOpen !== "" && (
          <div className="demo1-container">
            <div
              className="mt-4 p-4 w-[400px] bg-white rounded-md shadow-md"
              ref={popupRef}
            >
              <h2 className="font-bold text-lg mb-3">
                {isOpen.split("_")[1]}&nbsp;
                {isOpen.split("_")[0]}
              </h2>
              <form>
                {itemExist && (
                  <span className="text-red-400 text-sm text-center">
                    Item exists already
                  </span>
                )}
                {isOpen.split("_")[1] !== "Edit" && (
                  <input
                    type="text"
                    value={materialState["type"]}
                    onChange={(e) => {
                      if (
                        isOpen === "Soles_Add" ||
                        isOpen === "Edge Color_Add"
                      ) {
                        setMaterialState((prevState) => ({
                          ...prevState,
                          var: isOpen.includes("Color_Add")
                            ? selectedLace
                            : selectedSole,
                          type: e.target.value.replace(/\s+/g, "_"),
                        }));
                      } else {
                        setMaterialState((prevState) => ({
                          ...prevState,
                          type: e.target.value.replace(/\s+/g, "_"),
                        }));
                      }
                    }}
                    placeholder={isOpen === "Soles_Add" ? "Color" : "Type"}
                    className="border p-2 mb-2 w-full"
                  />
                )}
                {(isOpen === "Soles_Add" || isOpen === "Soles_Edit") && (
                  <input
                    type="number"
                    value={materialState["days"]}
                    onChange={(e) => {
                      setMaterialState((prevState) => ({
                        ...prevState,
                        days: e.target.value,
                      }));
                    }}
                    placeholder="Duration"
                    className="border p-2 mb-2 w-full"
                  />
                )}
                {!(isOpen === "Edge Color_Add") && (
                  <input
                    type="text"
                    value={materialState["price"]}
                    onChange={(e) => {
                      setMaterialState((prevState) => ({
                        ...prevState,
                        price: e.target.value.replace(/\s+/g, "_"),
                      }));
                    }}
                    placeholder="Price"
                    className="border p-2 mb-2 w-full"
                  />
                )}
                {isOpen.split("_")[1] !== "Edit" && (
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          setMaterialState((prevState) => ({
                            ...prevState,
                            img: reader.result,
                          }));
                        };
                        reader.readAsDataURL(file);
                      }
                    }}
                    className="border p-2 mb-2 w-full"
                  />
                )}
                <button
                  type="button"
                  className="bg-[#FFD61F] hover:bg-[#FEC23D] duration-200 text-white p-2 rounded-md w-full mt-2"
                  onClick={() => {
                    handleAddOREdit(isOpen);
                  }}
                >
                  {isOpen.split("_")[1]}
                </button>
              </form>
            </div>
          </div>
        )}
      </>
    </div>
  );
}

export default Sole;
