import React, { useState, useEffect, useRef } from "react";

const Images = ({ onNext }) => {
  const [showMaterialCost, setShowMaterialCost] = useState([]);
  const [product, setProduct] = useState({
    article: "",
    category: "",
    images: [
      {
        color: "",
        images: { Main: "", Back: "", Front: "", Side1: "", Side2: "" },
      },
    ],
    colors: [{ color: "" }],
    leathers: [{ leather: "", finishes: [] }],
    leatherConsumption: null,
    soles: [{ sole: "", constructions: [{ construction: "", welt: [] }] }],
  });
  // Fetching material cost data from API
  useEffect(() => {
    fetch(`http://127.0.0.1:8000/api/get-material-cost/`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setShowMaterialCost(data.materials);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }, []);

  // Handle adding more sections (Images, Colors, Leathers, Soles)
  const handleAddMore = (type) => {
    setProduct((prevState) => {
      let newItem;
      switch (type) {
        case "Image":
          newItem = {
            color: "",
            images: { Main: "", Back: "", Front: "", Side1: "", Side2: "" },
          };
          return { ...prevState, images: [...prevState.images, newItem] };
        case "Colors":
          newItem = { color: "" };
          return { ...prevState, colors: [...prevState.colors, newItem] };
        case "Leathers":
          newItem = { leather: "", finishes: [] };
          return { ...prevState, leathers: [...prevState.leathers, newItem] };
        case "Soles":
          newItem = {
            sole: "",
            constructions: [{ construction: "", welt: [] }],
          }; // Allow multiple constructions
          return { ...prevState, soles: [...prevState.soles, newItem] };
        case "Constructions":
          newItem = { construction: "", welt: [] }; // Add construction under selected sole
          const updatedSoles = [...prevState.soles];
          updatedSoles[prevState.soles.length - 1].constructions.push(newItem); // Push to the last added sole
          return { ...prevState, soles: updatedSoles };
        default:
          return prevState;
      }
    });
  };

  // Handle removing a section
  const handleRemove = (type, soleIndex, constructionIndex = null) => {
    setProduct((prevState) => {
      let updatedState = { ...prevState };

      if (type === "constructions" && constructionIndex !== null) {
        // Handle removing constructions within soles
        const updatedSoles = [...prevState.soles];
        updatedSoles[soleIndex].constructions = updatedSoles[
          soleIndex
        ].constructions.filter((_, i) => i !== constructionIndex);
        updatedState.soles = updatedSoles;
      } else {
        // Handle removing colors, leathers, or soles
        updatedState[type] = updatedState[type].filter(
          (_, i) => i !== soleIndex
        );
      }

      return updatedState;
    });
  };

  // Handle image uploads
  const handleImageUpload = (e, position, index) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProduct((prevState) => {
          const updatedImages = [...prevState.images];
          updatedImages[index].images[position] = reader.result;
          return { ...prevState, images: updatedImages };
        });
      };
      reader.readAsDataURL(file);
    }
  };

  // Submit the data to the catalogue collection
  const handleSubmit = () => {
    fetch(`http://127.0.0.1:8000/api/list-products/`, {
      method: "POST",
      body: JSON.stringify({ product }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        alert(data.success);
        if (data.success === "Product is listed successfully.") {
          // setProduct({
          //   article: "",
          //   category: "",
          //   images: [
          //     {
          //       color: "",
          //       images: { Main: "", Back: "", Front: "", Side1: "", Side2: "" },
          //     },
          //   ],
          //   colors: [{ color: "" }],
          //   leathers: [{ leather: "", finishes: [] }],
          //   leatherConsumption: null,
          //   soles: [
          //     { sole: "", constructions: [{ construction: "", welt: [] }] },
          //   ],
          // });
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  const colors = [
    { name: "Red", hex: "#FF0000", hexbg: "#FF000010" },
    { name: "Blue", hex: "#0000FF" },
    { name: "Green", hex: "#008000" },
    { name: "Yellow", hex: "#FFFF00" },
    { name: "Purple", hex: "#800080" },
    { name: "Orange", hex: "#FFA500" },
    { name: "Pink", hex: "#FFC0CB" },
    { name: "Gray", hex: "#808080" },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedColor, setSelectedColor] = useState(null);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(null);
    }
  };

  useEffect(() => {
    if (isOpen !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // Filter colors based on search query
  const filteredColors = colors.filter((color) =>
    color.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handle color selection
  const handleColorSelect = (color, index) => {
    setProduct((prevState) => {
      const updatedImages = [...prevState.images];
      updatedImages[index].color = color; // Set color for the specific image section
      return { ...prevState, images: updatedImages };
    });
    setIsOpen(null); // Close dropdown after selecting
  };

  return (
    <div className="bg-white max-md:p-2 md:p-4 2xl:p-6 rounded-lg shadow-md ">
      <div className="group   flex items-center gap-2">
        <label
          htmlFor="subCategoryName"
          className="block text-xs md:text-base font-semibold leading-4 text-[#212121]"
        >
          Sub-category Name:
        </label>
        <div className=" relative ">
          {/* <input
            name="leatherConsumption"
            className="px-2 block w-full  rounded-md  py-1.5 text-[#212121] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 group-hover:ring-2 group-hover:ring-[#eebd2d] group-hover:outline-none group-hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-sm sm:leading-6 "
            id="leatherConsumption"
            type="number"
            placeholder=""
            required
          /> */}
          <input
            id="subCategoryName"
            name="subCategoryName"
            type="text"
            class="ml-1 border-b-2 font-semibold block w-36 md:w-full border-gray-300 group focus:border-b-2 hover:border-[#eebd2d] focus:border-[#eebd2d] transition-colors focus:outline-none bg-inherit"
          />
        </div>
      </div>
      <div className=" flex flex-wrap max-2xl:gap-x-6 2xl:gap-x-12 gap-y-4 mt-3">
        {product.images?.map((upload, index) => (
          <div key={upload.id} className=" mb-2 relative">
            {/* If there are more color sections, show a remove button */}
            {index > 0 && (
              <>
                <button className="absolute top-2 right-2 z-50">
                  <img
                    onClick={() => handleRemove("images", index)}
                    src="/images/delete.svg"
                    alt=""
                    className="w-7 h-7 bg-slate-50 border-[1px] border-gray-800 hover:border-red-600 hover:bg-red-200 duration-200 ease-in-out  rounded-full p-1"
                  />
                </button>
              </>
            )}

            <div>
              <div className="relative w-24 md:w-36 group" ref={dropdownRef}>
                <button
                  onClick={() => setIsOpen(isOpen === index ? null : index)}
                  className="w-full px-2 py-2 mt-2 text-center text-xs md:text-sm  rounded-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 group-hover:ring-2 group-hover:ring-[#eebd2d] group-hover:outline-none group-hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset  sm:leading-6  font-semibold"
                  style={{ backgroundColor: upload.color.hexbg }}
                >
                  {upload.color ? (
                    <span className="flex items-center gap-2 ">
                      <span
                        className="w-4 h-4 rounded-full text-xs md:text-sm"
                        style={{ backgroundColor: upload.color.hex }}
                      ></span>
                      {upload.color.name}
                    </span>
                  ) : (
                    "Select Color"
                  )}
                </button>

                {isOpen === index && (
                  <div className="absolute z-10 w-36   md:w-full bg-white border border-gray-300 rounded-sm shadow-lg mt-1 overflow-hidden">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder="Search color..."
                      className="w-full max-md:px-2 md:px-4 py-2 border-b border-gray-300 focus:outline-none text-xs md:text-sm"
                    />
                    <ul className="max-h-48 overflow-y-auto">
                      {colors
                        .filter((color) =>
                          color.name
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        )
                        .map((color) => (
                          <li
                            key={color.name}
                            onClick={() => handleColorSelect(color, index)}
                            className="flex text-xs md:text-sm items-center gap-2 max-md:px-2 md:px-4 py-2 cursor-pointer hover:bg-gray-100"
                          >
                            <span
                              className="w-4 h-4 rounded-full"
                              style={{ backgroundColor: color.hex }}
                            ></span>
                            {color.name}
                          </li>
                        ))}
                      {filteredColors.length === 0 && (
                        <li className="px-4 py-2 text-gray-500 text-xs md:text-sm">
                          No results found
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="flex  justify-start items-start mt-2">
              <div className="flex flex-col ">
                <div>
                  <div className="cursor-pointer w-36 h-36 md:w-60 md:h-60 rounded-sm flex overflow-hidden items-center justify-center border-[1px] border-gray-300 hover:border-gray-400 duration-200 ease-in-out group">
                    <input
                      type="file"
                      accept="image/*"
                      className="absolute w-28 h-28 md:w-44 md:h-44 opacity-0 cursor-pointer "
                      onChange={(e) => handleImageUpload(e, "Main", index)}
                    />
                    {upload?.images?.Main ? (
                      <img
                        src={upload?.images?.Main}
                        alt="Main preview"
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <span className="pencil-icon flex flex-col items-center  justify-center text-[#313131] opacity-[65%] group-hover:opacity-[100%] duration-200 ease-in-out">
                        <span>
                          <img
                            src="/images/addimage.svg"
                            alt=""
                            className="w-7 h-7 "
                          />
                        </span>
                        <span className="text-sm font-semibold">
                          Main Image
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="">
                <div className="flex items-center flex-wrap w-36 md:w-60 justify-between gap-y-4 ml-4">
                  {["Back", "Front", "Side1", "Side2"]?.map((position) => (
                    <div
                      key={position}
                      className="cursor-pointer rounded-sm mx-0 relative profile-img-container overflow-hidden w-16 h-16 md:w-28 md:h-28 flex items-center justify-center border-[1px] border-gray-300 hover:border-gray-400 duration-200 ease-in-out group"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        className="absolute w-full h-full opacity-0 cursor-pointer "
                        onChange={(e) => handleImageUpload(e, position, index)}
                      />
                      {upload?.images?.[position] ? (
                        <img
                          src={upload?.images?.[position]}
                          alt={`${position} preview`}
                          className="w-full h-full object-cover"
                        />
                      ) : (
                        <span className="pencil-icon flex flex-col items-center justify-center text-[#313131] opacity-[65%] group-hover:opacity-[100%] duration-200 ease-in-out">
                          <span>
                            <img
                              src="/images/addimage.svg"
                              alt=""
                              className="w-5 h-5 "
                            />
                          </span>
                          <span className="text-[9px] md:text-xs font-medium">
                            {position} Image
                          </span>
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}

        {/* Add More Colors Button */}
        <div className="flex justify-end items-end mb-2 ">
          <button
            class="rounded-lg relative w-28 md:w-36 h-10 cursor-pointer flex items-center border-[3px]  border-[#ffe4ad] bg-[#FFC94A] group hover:bg-[#FFC94A] active:bg-[#FFC94A] active:border-[#FFC94A] overflow-hidden"
            onClick={() => handleAddMore("Image")}
          >
            <span class="text-white text-xs md:text-sm font-semibold max-md:ml-2 md:ml-6 transform group-hover:translate-x-20 transition-all duration-300">
              Add Color
            </span>
            <span class="absolute right-0 h-full w-7 md:w-10 rounded-lg bg-[#FFC94A] flex items-center justify-center transform group-hover:translate-x-0 group-hover:w-full transition-all duration-300">
              <svg
                class="svg w-4 h-4 md:w-6 md:h-6 text-white"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line x1="12" x2="12" y1="5" y2="19"></line>
                <line x1="5" x2="19" y1="12" y2="12"></line>
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div className="flex items-center justify-end w-full mt-3">
        <button
          class="bg-white text-center w-20 lg:w-32 rounded-lg h-9 lg:h-11 relative text-[#313131] text-xl font-semibold group border "
          type="button"
          onClick={onNext}
        >
          <div class="bg-[#FFC94A] rounded-md h-[26px] lg:h-[34px] w-1/3 flex items-center justify-center absolute left-1 top-[4px] group-hover:w-[118px] z-10 duration-500 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1024 1024"
              className="rotate-180 w-4 lg:w-6 h-4 lg:h-6"
            >
              <path
                d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"
                fill="#313131"
              ></path>
              <path
                d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"
                fill="#313131"
              ></path>
            </svg>
          </div>
          <p class="max-lg:translate-x-4 lg:translate-x-5 text-xs lg:text-sm">
            Save
          </p>
        </button>
      </div>
    </div>
  );
};

export default Images;
