import React, { useState } from "react";
import { IoMdArrowDropleft } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import "./admin.css";
import ProductListing from "./ProductListing/ProductListing.jsx";
import "../components/PopUps/PopUp.css";
import Upper from "./MaterialCost/Upper.jsx";
import Sole from "./MaterialCost/Sole.jsx";
import Header from "../components/Header/Header.jsx";
import { GoDotFill } from "react-icons/go";

function Admin() {
  const [menuItem, setMenuItem] = useState("Upper"); // Default selected menu item
  const [isOpen, setIsOpen] = useState(true); // Sidebar open/close state
  const [aboutOpen, aboutIsOpen] = useState(false);
  const [openSection, setOpenSection] = useState(null); // Track open section
  const [activeId, setActiveId] = useState(0); //use for bottom sidebar
  const [isOptionOpen, setIsOptionOpen] = useState(false);

  const handleSectionToggle = (sectionIndex) => {
    setOpenSection((prevIndex) =>
      prevIndex === sectionIndex ? null : sectionIndex
    );
  };

  const userType = "admin";

  const sidebarData = [
    {
      usertype: "admin",
      option: [
        {
          id: 0,
          title: "Material Cost",
          icon: <img src="/images/adminImages/Material.svg" />,
          items: [
            {
              label: "Upper",
            },
            {
              label: "Sole",
            },
            {
              label: "Accessories",
            },
          ],
        },
        {
          id: 1,
          title: "Product Listing",
          icon: <img src="/images/adminImages/product listing.svg" />,
          items: [
            {
              label: "Boots",
            },
            {
              label: "Oxford",
            },
            {
              label: "Brogue",
            },
            {
              label: "Monk",
            },
            {
              label: "Shipon",
            },
            {
              label: "Sneakers",
            },
            {
              label: "Sandals",
            },
          ],
        },
        {
          id: 2,
          title: "Order History",
          icon: <img src="/images/adminImages/orderhistory.svg" />,
          items: [
            {
              label: "Completed Orders",
            },
            {
              label: "Ledgers & Invoice",
            },
            {
              label: "Loyalty Benefits",
            },
          ],
        },
        {
          id: 3,
          title: "Production Stages",
          icon: <img src="/images/adminImages/productionstages.svg" />,
        },
        {
          id: 4,
          title: "eCommerce API",
          icon: <img src="/images/adminImages/ecommerceapi.svg" />,
          items: [
            {
              label: "Sandbox",
            },
            {
              label: "Console Log",
            },
            {
              label: "SKU selection",
            },

            {
              label: "Inventory Manager",
            },
            {
              label: "Membership",
            },
          ],
        },
        {
          id: 5,
          title: "Connect",
          icon: <img src="/images/adminImages/connect.svg" />,
          items: [
            {
              label: "Video Call",
            },
            {
              label: "WhatsApp Bot",
            },
            {
              label: "Feedback",
            },

            {
              label: "FAQ & Guide",
            },
          ],
        },
        {
          id: 6,
          title: "Sales Report",
          icon: <img src="/images/adminImages/sales Report.svg" />,
          items: [
            {
              label: "Leads Status",
            },
            {
              label: "Employee KPI",
            },
            {
              label: "Revenue Tracker",
            },

            {
              label: "Budget Allocation",
            },
            {
              label: "Calender",
            },
          ],
        },
      ],
    },
    {
      usertype: "supplier",
      option: [
        {
          id: 0,
          title: "Production Stages",
          icon: <img src="/images/adminImages/productionstages.svg" />,
        },
        {
          id: 1,
          title: "Order History",
          icon: <img src="/images/adminImages/orderhistory.svg" />,
          items: [
            {
              label: "Completed Orders",
            },
            {
              label: "Ledgers & Invoice",
            },
            {
              label: "Loyalty Benefits",
            },
          ],
        },
        {
          id: 2,
          title: "Accessories",
          icon: <img src="/images/adminImages/accessories.svg" />,
        },
        {
          id: 3,
          title: "Tech Pack",
          icon: <img src="/images/adminImages/connect.svg" />,
          items: [
            {
              label: "Box Label",
            },
            {
              label: "Tags",
            },
            {
              label: "Branding",
            },
          ],
        },
        {
          id: 4,
          title: "Photoshoot",
          icon: <img src="/images/adminImages/photoshoot.svg" />,
          items: [
            {
              label: "Angles & setup",
            },
            {
              label: "Creative & Video",
            },
            {
              label: "Resource Library",
            },
          ],
        },
        {
          id: 5,
          title: "Purchase Order",
          icon: <img src="/images/adminImages/Purchase order.svg" />,
          items: [
            {
              label: "Spec sheet",
            },
            {
              label: "Raise bills",
            },
            {
              label: "Supply chain",
            },

            {
              label: "Sampling",
            },
          ],
        },
        {
          id: 6,
          title: "Connect",
          icon: <img src="/images/adminImages/connect.svg" />,
          items: [
            {
              label: "Video Call",
            },
            {
              label: "WhatsApp Bot",
            },
            {
              label: "Feedback",
            },

            {
              label: "FAQ & Guide",
            },
          ],
        },
      ],
    },
    {
      usertype: "individual",
      option: [
        {
          id: 0,
          title: "Customize 3D",
          icon: <img src="/images/adminImages/Customize 3D.svg" />,
          items: [
            {
              label: "Boots",
            },
            {
              label: "Oxford",
            },
            {
              label: "Brogue",
            },
            {
              label: "Monk",
            },
            {
              label: "Shipon",
            },
            {
              label: "Sneakers",
            },
            {
              label: "Sandals",
            },
          ],
        },
        {
          id: 1,
          title: "Production Stages",
          icon: <img src="/images/adminImages/productionstages.svg" />,
        },
        {
          id: 2,
          title: "Order History",
          icon: <img src="/images/adminImages/orderhistory.svg" />,
          items: [
            {
              label: "Completed Orders",
            },
            {
              label: "Ledgers & Invoice",
            },
            {
              label: "Loyalty Benefits",
            },
          ],
        },
        {
          id: 3,
          title: "Shipping",
          icon: <img src="/images/adminImages/shipping.svg" />,
          items: [
            {
              label: "Request-for-quote",
            },
            {
              label: "Logistics payment",
            },
            {
              label: "Shipment tracking",
            },
          ],
        },
        {
          id: 4,
          title: "Connect",
          icon: <img src="/images/adminImages/connect.svg" />,
          items: [
            {
              label: "Video Call",
            },
            {
              label: "WhatsApp Bot",
            },
            {
              label: "Feedback",
            },

            {
              label: "FAQ & Guide",
            },
          ],
        },
      ],
    },
    {
      usertype: "businessUser",
      option: [
        {
          id: 0,
          title: "Customize 3D",
          icon: <img src="/images/adminImages/Customize 3D.svg" />,
          items: [
            {
              label: "Boots",
            },
            {
              label: "Oxford",
            },
            {
              label: "Brogue",
            },
            {
              label: "Monk",
            },
            {
              label: "Slipon",
            },
            {
              label: "Sneakers",
            },
            {
              label: "Sandals",
            },
          ],
        },
        {
          id: 1,
          title: "Production Stages",
          icon: <img src="/images/adminImages/productionstages.svg" />,
        },
        {
          id: 2,
          title: "Order History",
          icon: <img src="/images/adminImages/orderhistory.svg" />,
          items: [
            {
              label: "Completed Orders",
            },
            {
              label: "Ledgers & Invoice",
            },
            {
              label: "Loyalty Benefits",
            },
          ],
        },
        {
          id: 3,
          title: "Accessories",
          icon: <img src="/images/adminImages/accessories.svg" />,
        },
        {
          id: 4,
          title: "AI studio",
          icon: <img src="/images/adminImages/aistudio.svg" />,
        },
        {
          id: 5,
          title: "eCommerce API",
          icon: <img src="/images/adminImages/ecommerceapi.svg" />,
          items: [
            {
              label: "Sandbox",
            },
            {
              label: "Console Log",
            },
            {
              label: "SKU selection",
            },

            {
              label: "Inventory Manager",
            },
            {
              label: "Membership",
            },
          ],
        },
        {
          id: 6,
          title: "Photoshoot",
          icon: <img src="/images/adminImages/photoshoot.svg" />,
          items: [
            {
              label: "Angles & setup",
            },
            {
              label: "Creative & Video",
            },
            {
              label: "Resource Library",
            },
          ],
        },
        {
          id: 7,
          title: "Shipping",
          icon: <img src="/images/adminImages/shipping.svg" />,
          items: [
            {
              label: "Request-for-quote",
            },
            {
              label: "Logistics payment",
            },
            {
              label: "Shipment tracking",
            },
          ],
        },
        {
          id: 8,
          title: "Connect",
          icon: <img src="/images/adminImages/connect.svg" />,
          items: [
            {
              label: "Video Call",
            },
            {
              label: "WhatsApp Bot",
            },
            {
              label: "Feedback",
            },

            {
              label: "FAQ & Guide",
            },
          ],
        },
      ],
    },
  ];

  // About us API
  const options = ["Shoemaking", "Guide", "Blogs"];

  const handleItemClick = (label) => {
    setMenuItem(label); // Set the menu item by label
  };

  const toggleSidebar = () => {
    setIsOpen((prev) => !prev); // Toggle sidebar state
  };

  // Filter sidebar data based on user type
  const userSidebar = sidebarData.find((data) => data.usertype === userType);
  const productItems = [
    "Boots",
    "Oxford",
    "Brogue",
    "Monk",
    "Shipon",
    "Sneakers",
    "Sandals",
  ];

  return (
    <>
      <Header />
      {/* Mobile video top Nav bar */}
      {userSidebar?.option.some((data) => data.id === activeId) && (
        <div
          key={activeId} // Changing key forces component to re-render and restart animation
          className="sidebar-content animate-slide lg:hidden"
        >
          {userSidebar?.option
            .filter((data) => data.id === activeId)
            .map((section) => (
              <div
                key={section.id}
                className="text-xs flex overflow-x-scroll px-2 "
              >
                {section.items?.map((item, itemIndex) => (
                  <p
                    key={itemIndex}
                    onClick={() => setActiveId(section.id)}
                    className={`px-2 py-0.5 text-white ml-2 my-2 whitespace-nowrap flex items-center  ${
                      activeId === section.id ? "w-fit" : ""
                    }`}
                  >
                    <span className="w-4">
                      <GoDotFill />
                    </span>
                    {item.label}
                  </p>
                ))}
              </div>
            ))}
        </div>
      )}

      <div className="bg-grey-800 flex items-start justify-between relative bg-[#F5F9FF] overflow-x-hidden">
        {/* Sidebar Lg */}
        <div
          className={`p-2 z-10 transition-all duration-500 ease-in-out hidden lg:inline-flex ${
            isOpen ? "lg:w-[25%] xl:w-[24%] 2xl:w-[18%]" : "w-[95px]"
          }`}
        >
          <div
            className={`p-3 text-base text-black flex flex-col justify-between gap-4 relative h-[800px] rounded-xl bg-[#ffffff8b] shadow transition-all duration-500 ease-in-out ${
              isOpen
                ? "opacity-100 overflow-y-scroll no-scrollbar"
                : "pointer-events-auto"
            }`}
          >
            {/* Toggle Arrow */}
            <span
              className={`absolute top-[50%] -translate-y-[50%] p-1 text-3xl text-black right-0 bg-[#FFF4B5] hover:bg-[#FFD61F] hover:text-white duration-200  cursor-pointer hover:shadow-md z-10 ${
                isOpen ? "rounded-l-full" : "rotate-180 rounded-r-full"
              }`}
              onClick={toggleSidebar}
            >
              <IoMdArrowDropleft />
            </span>

            {/* Render Sidebar Options */}
            <div className="flex flex-col gap-3">
              {userSidebar?.option.map((section, sectionIndex) => (
                <div
                  key={sectionIndex}
                  className={`${isOpen ? "rounded-lg " : "rounded-lg "}${
                    openSection === sectionIndex ? "" : ""
                  }`}
                >
                  {section.title === "Production Stages" ||
                  section.title === "Accessories" ||
                  section.title === "AI studio" ? (
                    <>
                      <h2 className="relative">
                        <div
                          className={`flex items-center justify-between w-full text-sm 2xl:text-base p-2 cursor-pointer ${
                            openSection === sectionIndex
                              ? "text-black bg-slate-100 shadow-sm"
                              : "text-gray-600"
                          } ${
                            isOpen
                              ? "rounded-md hover:shadow-sm"
                              : "rounded-md hover:shadow-sm"
                          }`}
                          onClick={() => {
                            handleItemClick(sectionIndex);
                            setOpenSection(null);
                          }}
                        >
                          <div className="flex items-center gap-3">
                            {/* Icon Always Visible */}
                            <span className={`w-8 h-8 `}>{section.icon}</span>

                            {/* Title Visibility Based on Sidebar State */}
                            <span
                              className={`transition-all duration-500 font-semibold ${
                                isOpen ? "inline-flex" : "hidden"
                              }`}
                            >
                              {section.title}
                            </span>
                          </div>
                        </div>
                      </h2>
                    </>
                  ) : (
                    <>
                      <h2 className="relative">
                        <div
                          className={`flex items-center justify-between w-full text-sm 2xl:text-base p-2 cursor-pointer ${
                            openSection === sectionIndex
                              ? "text-black bg-slate-100 shadow-sm "
                              : "text-gray-600"
                          } ${
                            isOpen
                              ? "rounded-md hover:shadow-sm"
                              : "rounded-md hover:shadow-sm"
                          }`}
                          onClick={() => handleSectionToggle(sectionIndex)}
                        >
                          <div className="flex items-center gap-3">
                            {/* Icon Always Visible */}
                            <span className={`w-6 h-6 xl:w-7 xl:h-7`}>
                              {section.icon}
                            </span>

                            {/* Title Visibility Based on Sidebar State */}
                            <span
                              className={`transition-all duration-500 font-semibold ${
                                isOpen ? "inline-flex" : "hidden"
                              }`}
                            >
                              {section.title}
                            </span>
                          </div>
                          <span
                            className={`transform duration-500 ${
                              isOpen ? "inline-flex" : "hidden"
                            } ${
                              openSection === sectionIndex ? "rotate-180" : ""
                            } `}
                          >
                            <IoIosArrowDown />
                          </span>
                        </div>

                        {/* option */}
                        <div
                          className={`overflow-hidden  flex flex-col gap-2 px-2  ${
                            openSection === sectionIndex
                              ? "max-h-screen  py-2"
                              : "max-h-0"
                          } ${
                            isOpen
                              ? "transition-all ease-in-out duration-500"
                              : "absolute top-0 left-12 z-50 shadow-md bg-white rounded-lg w-[180px]"
                          }`}
                        >
                          {section.items.map((item, itemIndex) => (
                            <button
                              key={itemIndex}
                              className={`flex items-center p-2 duration-200 cursor-pointer rounded-r-md w-full gap-2 text-sm border-l-2 border-[#FFD61F] ${
                                menuItem === item.label
                                  ? "bg-[#FFF4B5]"
                                  : "hover:bg-[#FFF4B5]"
                              }`}
                              onClick={() => handleItemClick(item.label)}
                            >
                              {item.label}
                            </button>
                          ))}
                        </div>
                      </h2>
                    </>
                  )}
                </div>
              ))}
            </div>

            {/* About Option */}
            <div className="relative">
              {/* Toggle Button */}
              <button
                onClick={() => aboutIsOpen((prev) => !prev)} // Toggle state
                className={`flex items-center justify-between p-2 duration-200 cursor-pointer rounded-md w-full gap-2 text-base bg-slate-50 shadow-sm`}
              >
                <span className="flex items-center gap-3">
                  <img
                    src="/images/adminImages/about.svg"
                    className="w-6 h-6 xl:w-7 xl:h-7"
                    alt="About"
                  />
                  <span className={`${isOpen ? "inline-flex" : "hidden"}`}>
                    About us
                  </span>
                </span>
                <span
                  className={`transform duration-500  ${
                    isOpen ? "inline-flex -rotate-90" : "hidden"
                  }`}
                >
                  <IoIosArrowDown />
                </span>
              </button>

              {/* Dropdown Content with Smooth Transition */}
              <div
                className={`${
                  isOpen
                    ? "flex flex-col gap-2 transition-all duration-500 ease-in-out overflow-hidden bg-white absolute -top-[150px] p-2 rounded-md shadow-md w-fit z-[200] "
                    : "flex flex-col gap-2 transition-all duration-500 ease-in-out bg-white absolute -top-[100px] left-12 p-2 rounded-md shadow-md w-fit z-[20000] "
                } ${aboutOpen ? "inline-flex" : "hidden"}`}
              >
                {options.map((option, index) => (
                  <button
                    key={index}
                    className="flex items-center p-2 duration-200 cursor-pointer rounded-r-md w-full gap-2 text-sm  min-w-[180px] border-l-2 border-[#FFD61F] hover:bg-[#FFF4B5]"
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Bottom sidebar */}
        <div className="fixed bottom-0 left-0 right-0 bg-white px-2.5 pb-2.5 w-full text-[10px] justify-between items-end z-[20] font-bold border lg:hidden">
          <div className="w-full flex justify-between">
            {userSidebar?.option.slice(0, 5).map((section) => (
              <>
                {section.id === 2 ? (
                  <div
                    onClick={() => {
                      setIsOptionOpen(!isOptionOpen);
                      if (
                        section.id === 0 ||
                        section.id === 1 ||
                        section.id === 3 ||
                        section.id === 4
                      )
                        setActiveId(2);
                    }}
                    className={`w-[63px] border-2 shadow-sm flex flex-col items-center justify-center rounded-full mt-1 ${
                      section.id === 0 ||
                      section.id === 1 ||
                      section.id === 3 ||
                      section.id === 4
                        ? ""
                        : "bg-[#FFF4B5] duration-500 border-[#FFD61F]"
                    }`}
                  >
                    <img
                      src="/images/adminImages/app.svg"
                      className="w-6 h-6"
                      alt=""
                    />
                    <p className="">More</p>
                  </div>
                ) : (
                  <div
                    key={section.id}
                    onClick={() => {
                      setActiveId(section.id);
                      setIsOptionOpen(false);
                    }}
                    className={`flex flex-col items-center justify-center p-1.5 w-[63px] 
              ${
                activeId === section.id
                  ? " border-[#FFD61F] bg-[#FFF4B5] duration-500 rounded-b-lg "
                  : ""
              } 
              ${
                activeId === section.id
                  ? "flex flex-col items-center justify-center p-1.5 w-[63px] border-t-[3px]"
                  : "border-t-[3px] border-white rounded-x-lg rounded-b-lg"
              }
            `}
                  >
                    <span className={`w-6 h-6 `}>{section.icon}</span>
                    <p
                      className={`${`text-center leading-[11px] mt-1`} ${
                        activeId === section.id
                          ? " text-black"
                          : "text-gray-500"
                      }`}
                    >
                      {section.title}
                    </p>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>

        {/* Bottom Sidebar popup */}
        {isOptionOpen && (
          <div className="text-[10px] justify-between items-end z-[19] font-bold lg:hidden h-[100vh] admin-popup">
            <div className="fixed bottom-[70px] flex items-start justify-between flex-wrap gap-3 p-3 w-full min-h-[125px] bg-white rounded-t-3xl admin-popup-animation">
              {userSidebar?.option.slice(2, 3).map((section) => (
                <div
                  className={`flex flex-col items-center justify-center p-2 w-[80px] h-16 ${
                    activeId === section.id
                      ? "border-[#FFD61F] border-t-[3px] bg-[#FFF4B5] duration-500 rounded-lg"
                      : "bg-zinc-100 rounded-lg"
                  }`}
                  onClick={() => {
                    setActiveId(section.id);
                    setIsOptionOpen(false);
                  }}
                >
                  <span className="w-6 h-6">{section.icon}</span>
                  <p className="text-center leading-[11px] mt-1">
                    {section.title}
                  </p>
                </div>
              ))}
              {userSidebar?.option.slice(5).map((section) => (
                <div
                  className={`flex flex-col items-center justify-center p-2 w-[80px] h-16 ${
                    activeId === section.id
                      ? "border-[#FFD61F] border-t-[3px] bg-[#FFF4B5] duration-500 rounded-lg"
                      : "bg-zinc-100 rounded-lg"
                  }`}
                  onClick={() => {
                    setActiveId(section.id);
                    setIsOptionOpen(false);
                  }}
                >
                  <span className="w-6 h-6">{section.icon}</span>
                  <p className="text-center leading-[11px] mt-1">
                    {section.title}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Main Content */}
        <div className="w-full lg:min-w-[75%] xl:min-w-[80%] 2xl:min-w-[86%] p-2 ">
          <div className="w-full border transparent bg-white rounded-xl shadow-md min-h-[800px] p-2 xl:p-4">
            {menuItem === "Upper" && <Upper />}
            {productItems.includes(menuItem) && (
              <ProductListing menuitem={menuItem} />
            )}
            {menuItem === "Sole" && <Sole />}
          </div>
        </div>
      </div>
    </>
  );
}

export default Admin;
