import React, { useState, useRef, useEffect } from "react";
import "./Account.css";
import BillingAddress from "./BillingAddress";
import Header from "../../components/Header/Header.jsx";

const AccountPage = () => {
  const [isBillingEditable, setBillingEditable] = useState(false);
  const [isFading, setIsFading] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(null); // Track selected address
  const [locality, setLocality] = useState([]);

  // Function to handle address selection
  const handleSelectAddress = (id) => {
    setSelectedAddressId(id);
  };

  const handleEditBilling = () => {
    setIsFading(true);
    setTimeout(() => {
      setBillingEditable(!isBillingEditable);
      setIsFading(false);
    }, 200); // Duration of the fade-out effect
  };

  const [addresses, setAddresses] = useState([
    {
      id: 1,
      country: "IN",
      postalCode: "201310",
      state: "Uttar Pradesh",
      district: "Gautam Buddha Nagar",
      locality: "Alpha Greater Noida",
      street1: "C-267",
      street2: "",
    },
  ]);

  const [newAddress, setNewAddress] = useState({
    country: "",
    postalCode: "",
    state: "",
    district: "",
    locality: "",
    street1: "",
    street2: "",
  });

  const [countries, setCountries] = useState([]);
  const [localityOptions, setLocalityOptions] = useState([]);
  const [pincode, setPincode] = useState("");

  // Fetch countries from the REST Countries API
  const fetchCountries = async () => {
    try {
      const response = await fetch("https://restcountries.com/v3.1/all");
      if (response.ok) {
        const data = await response.json();
        const countryList = data.map((country) => ({
          code: country.cca2, // ISO 3166-1 alpha-2 code
          name: country.name.common, // Common name of the country
        }));
        // Sort countries alphabetically
        countryList.sort((a, b) => a.name.localeCompare(b.name));
        setCountries(countryList);
      } else {
        console.error("Failed to fetch countries");
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  // Fetch locality and state based on Indian postal code
  const fetchPincodeData = (value) => {
    setPincode(value);
    if (/^\d{6}$/.test(value)) {
      fetch(`https://api.postalpincode.in/pincode/${value}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((PostOffice) => {
          const state = PostOffice[0]?.PostOffice[0]?.State || "";
          const district = PostOffice[0]?.PostOffice[0]?.District || ""; // Get district
          const area = PostOffice[0]?.PostOffice.map((post) => post.Name) || [];
          setNewAddress((prev) => ({
            ...prev,
            state: state,
            locality: area[0] || "", // Default to the first locality
            district: district || "", // Set district
          }));
          setLocalityOptions(area);
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    }
  };

  // Fetch locality and state for non-Indian countries
  const fetchLocationData = async (countryCode, postalCode) => {
    try {
      const response = await fetch(
        `https://api.zippopotam.us/${countryCode.toLowerCase()}/${postalCode}`
      );
      if (response.ok) {
        const data = await response.json();
        const { places } = data;
        if (places && places.length > 0) {
          setNewAddress((prevAddress) => ({
            ...prevAddress,
            locality: places[0]["place name"],
            state: places[0]["state"],
          }));
        }
      } else {
        console.error("Invalid postal code or country.");
      }
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  };

  // Call the API when the component mounts to fetch countries
  useEffect(() => {
    fetchCountries();
  }, []);

  // Call appropriate API based on country and postal code
  useEffect(() => {
    if (newAddress.country === "IN") {
      fetchPincodeData(newAddress.postalCode);
    } else if (newAddress.country && newAddress.postalCode) {
      fetchLocationData(newAddress.country, newAddress.postalCode);
    }
  }, [newAddress.country, newAddress.postalCode]);

  const [editMode, setEditMode] = useState(null); // null means add mode
  const [isModalOpen, setIsModalOpen] = useState(false); // For popup

  // Function to handle opening the modal
  const handleOpenModal = (address = null) => {
    if (address) {
      setEditMode(address.id);
      setNewAddress(address);
    } else {
      setEditMode(null);
      setNewAddress({ street: "", city: "", postalCode: "", country: "" });
    }
    setIsModalOpen(true);
  };

  // Function to handle adding/editing address
  const handleSaveAddress = () => {
    if (editMode) {
      const updatedAddresses = addresses.map((address) =>
        address.id === editMode ? { ...newAddress, id: editMode } : address
      );
      setAddresses(updatedAddresses);
    } else {
      setAddresses([...addresses, { ...newAddress, id: Date.now() }]);
    }
    setIsModalOpen(false);
  };

  // Function to handle deleting address
  const handleDeleteAddress = (id) => {
    setAddresses(addresses.filter((address) => address.id !== id));
    setIsModalOpen(false); // Close the modal after deletion
  };

  const [showOptions, setShowOptions] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowOptions(false); // Close the popup if clicking outside
    }
  };

  useEffect(() => {
    if (showOptions) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showOptions]);

  const handleButtonClick = () => {
    setShowOptions(true); // Show the popup when the profile is clicked
  };

  const handleCancelClick = () => {
    setShowOptions(false); // Close the popup when cancel is clicked
  };

  const handleMouseEnter = () => {
    setIsHovering(true); // Show edit button on hover
  };

  const handleMouseLeave = () => {
    setIsHovering(false); // Hide edit button when hover ends
  };

  return (
    <>
      <Header />
      <div className="after-header max-w-3xl mx-auto pb-4 bg-white">
        <h1 className="pt-4 text-2xl font-bold text-center text-black mb-4">
          Account Information
        </h1>

        {/* Profile Picture  */}

        <div className="flex justify-center items-center relative mb-2">
          <div
            className="w-[120px] h-[120px] md:w-[180px] md:h-[180px] p-1 border-2 rounded-full hover:border-gray-400/50 cursor-pointer bg-white hover:bg-slate-300  group transition-all duration-500 z-0 relative  "
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleButtonClick}
          >
            <div className="w-full  bg-white group-hover:bg-slate-200 h-full flex items-center justify-center p-2 rounded-full  object-cover transition-all duration-500">
              <span className="w-12 h-12 md:w-20 md:h-20 inline-block">
                <img src="/images/user.svg" alt="" />
              </span>
            </div>

            {/* Hover "Edit" button */}
            {isHovering && (
              <div className="absolute inset-0 flex justify-center items-center">
                <button className="bg-gray-600 text-white rounded-full px-2 py-2 bg-opacity-80 transition-opacity duration-500">
                  <img
                    className="p-5 fill-current"
                    src="/images/edit-white.svg"
                    alt="Edit"
                  />
                </button>
              </div>
            )}
          </div>

          {/* Popup menu for options */}
          {showOptions && (
            <div className="demo1-container">
              <div
                className=" absolute top-[160px] left-1/2 transform -translate-x-1/2 bg-white border border-gray-300 rounded-lg shadow-md z-10 max-md:p-2 md:p-3"
                ref={popupRef}
              >
                <ul className="text-center text-sm">
                  <li
                    className="px-4 py-2 text-[#212121] hover:text-[#fff] bg-[#f0f0f0] hover:bg-[#FFC94A] font-semibold cursor-pointer rounded-md  duration-300 mb-1"
                    onClick={() => alert("Change Profile Photo")}
                  >
                    Change Photo
                  </li>
                  <li
                    className="px-4 py-2 text-[#212121] hover:text-[#fff] bg-[#f0f0f0] hover:bg-[#FFC94A] font-semibold cursor-pointer rounded-md duration-300 mb-1"
                    onClick={() => alert("Remove Profile Photo")}
                  >
                    Remove Photo
                  </li>
                  <li
                    className="px-4 py-2 text-[#212121] hover:text-[#fff] bg-[#f0f0f0] hover:bg-[#ff4848] font-semibold cursor-pointer rounded-md duration-300 "
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>

        {/* Full Name */}
        <div className="mb-3">
          <label className="block text-sm font-semibold leading-4 text-[#212121] mb-1">
            Full Name
          </label>
          <input
            type="text"
            className="px-2 block w-full  py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-sm sm:leading-6 cursor-not-allowed"
            placeholder="Enter full name"
            disabled
          />
        </div>

        {/* Email Address */}
        <div className="mb-3">
          <label className="block text-sm font-semibold leading-4 text-[#212121] mb-1">
            Email Address
          </label>
          <input
            type="email"
            className="px-2 block w-full  py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-sm sm:leading-6 cursor-not-allowed"
            placeholder="Enter email address"
            disabled
          />
        </div>

        {/* Mobile Number */}
        <div className="mb-3">
          <label className="block text-sm font-semibold leading-4 text-[#212121] mb-1">
            Mobile Number
          </label>
          <input
            type="tel"
            className="px-2 block w-full  py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-sm sm:leading-6 "
            placeholder="Enter mobile number"
          />
        </div>

        {/* Shipping Address */}
        <div className="mb-3">
          <h3 className="text-lg font-bold mb-2 text-[#111111]">
            Shipping Address:
          </h3>
          <label className="block text-sm font-semibold leading-4 text-[#212121] mb-1">
            Receiver's Name
          </label>
          <input
            type="text"
            className="px-2 block w-full  py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none hover:ring-inset focus:ring-2 focus:ring-[#eebd2d] focus:outline-none focus:ring-inset text-sm sm:leading-6 "
            placeholder="Enter receiver's name"
          />

          <div className="mt-3">
            <h3 className="text-sm font-semibold leading-4 text-[#212121] mb-3">
              Saved Addresses
            </h3>
            <div className="flex flex-wrap gap-2 ">
              {addresses.map((address) => (
                <div
                  key={address.id}
                  className={`w-[48%] md:w-[24%] bg-white shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] max-md:p-2.5 md:p-3 space-y-1 relative overflow-hidden cursor-pointer duration-300 ease-in-out ${
                    selectedAddressId === address.id
                      ? "border-[#e2a731] border-2"
                      : "border-gray-400 border-2"
                  }`}
                  onClick={() => handleSelectAddress(address.id)} // Select address on card click
                >
                  {/* Edit Button */}
                  <div
                    className={`w-16 h-16 rounded-full absolute -right-5 -top-7 duration-300 ease-in-out ${
                      selectedAddressId === address.id
                        ? "bg-[#e2a731]"
                        : "bg-gray-400"
                    }`}
                  >
                    <p
                      className="absolute bottom-2.5 left-3.5 text-white text-sm"
                      onClick={(event) => {
                        event.stopPropagation(); // Prevent card click when editing
                        handleOpenModal(address); // Open modal in edit mode
                      }}
                    >
                      <img
                        className="w-5 h-5 fill-current"
                        src="/images/edit-white.svg"
                        alt="Edit"
                      />
                    </p>
                  </div>

                  <div className="fill-current w-8">
                    <svg
                      clipRule="evenodd"
                      fillRule="evenodd"
                      strokeLinejoin="round"
                      strokeMiterlimit="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      className={
                        selectedAddressId === address.id
                          ? "text-[#e2a731] duration-300 ease-in-out"
                          : "text-gray-400 duration-300 ease-in-out"
                      }
                    >
                      <g id="Icon">
                        <path d="m12 1.25c-3.723 0-6.75 3.054-6.75 6.821 0 1.276.562 2.859 1.38 4.438 1.801 3.477 4.801 6.979 4.801 6.979.142.166.35.262.569.262s.427-.096.569-.262c0 0 3-3.502 4.801-6.979.818-1.579 1.38-3.162 1.38-4.438 0-3.767-3.027-6.821-6.75-6.821zm0 4c-1.518 0-2.75 1.232-2.75 2.75s1.232 2.75 2.75 2.75 2.75-1.232 2.75-2.75-1.232-2.75-2.75-2.75z"></path>
                        <path d="m16.784 17.377c.813.244 1.483.546 1.946.9.3.228.52.451.52.723 0 .16-.091.305-.219.45-.212.239-.527.454-.917.654-1.378.705-3.606 1.146-6.114 1.146s-4.736-.441-6.114-1.146c-.39-.2-.705-.415-.917-.654-.128-.145-.219-.29-.219-.45 0-.272.22-.495.52-.723.463-.354 1.133-.656 1.946-.9.396-.119.621-.538.502-.934-.119-.397-.538-.622-.934-.503-1.238.373-2.2.884-2.778 1.449-.508.495-.756 1.049-.756 1.611 0 .702.397 1.402 1.204 1.986 1.416 1.024 4.26 1.764 7.546 1.764s6.13-.74 7.546-1.764c.807-.584 1.204-1.284 1.204-1.986 0-.562-.248-1.116-.756-1.611-.578-.565-1.54-1.076-2.778-1.449-.396-.119-.815.106-.934.503-.119.396.106.815.502.934z"></path>
                      </g>
                    </svg>
                  </div>
                  <h1 className="font-bold text-base leading-4 mt-2">
                    {address.street1}
                  </h1>
                  <p className="text-sm text-zinc-600 leading-4 m-0">
                    {address.locality}, {address.state}, {address.postalCode},{" "}
                    {address.country}
                  </p>
                </div>
              ))}

              {/* Add New Address Card */}
              <div
                onClick={() => handleOpenModal()} // Open modal for adding a new address
                className="w-[48%] md:w-[24%] p-3 flex items-center justify-center  shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] bg-white flex-shrink-0 cursor-pointer"
              >
                <h4 className="text-sm font-semibold leading-4 text-[#212121] flex justify-center flex-col items-center gap-2">
                  <img src="/images/plus.svg" className="w-7 h-7" alt="" />
                  New Address
                </h4>
              </div>
            </div>

            {/* Modal Popup */}
            {isModalOpen && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-lg shadow-lg w-[400px]">
                  <div className="flex justify-between mb-3">
                    <h3 className="text-lg font-bold text-[#111111] ">
                      {editMode ? "Edit Address" : "Add New Address"}
                    </h3>

                    <button
                      onClick={() => handleDeleteAddress(newAddress.id)} // Pass the id of the current address
                      className="px-2 py-1 text-sm bg-red-500 text-white border-[1px] border-red-600 rounded-md"
                    >
                      Delete
                    </button>
                  </div>
                  <div className="mb-3">
                    <select
                      className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 mb-2 focus:outline-none"
                      disabled={isBillingEditable}
                      value={newAddress.country}
                      required
                      onChange={(e) =>
                        setNewAddress({
                          ...newAddress,
                          country: e.target.value,
                          state: "",
                          locality: "",
                          district: "",
                        })
                      }
                    >
                      <option value="" disabled>
                        Select Country
                      </option>
                      {countries.map((country) => (
                        <option key={country.code} value={country.code}>
                          {country.name}
                        </option>
                      ))}
                    </select>

                    <input
                      type="number"
                      className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 mb-2 focus:outline-none"
                      placeholder="Postal Code"
                      disabled={!newAddress.country}
                      value={newAddress.postalCode}
                      required
                      onChange={(e) =>
                        setNewAddress({
                          ...newAddress,
                          postalCode: e.target.value,
                        })
                      }
                    />

                    <input
                      type="text"
                      className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 mb-2 focus:outline-none"
                      placeholder="State"
                      disabled={!newAddress.country}
                      value={newAddress.state}
                      required
                      onChange={(e) =>
                        setNewAddress({ ...newAddress, state: e.target.value })
                      }
                    />

                    {/* District input that shows only if India is selected */}
                    {newAddress.country === "IN" && (
                      <input
                        type="text"
                        className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 mb-2 focus:outline-none"
                        placeholder="District"
                        disabled={!newAddress.country}
                        value={newAddress.district}
                        required
                        onChange={(e) =>
                          setNewAddress({
                            ...newAddress,
                            district: e.target.value,
                          })
                        }
                      />
                    )}

                    {newAddress.country === "IN" ? (
                      <select
                        className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 mb-2 focus:outline-none"
                        name="locality"
                        id="locality"
                        disabled={!newAddress.country}
                        value={newAddress.locality}
                        required
                        onChange={(e) =>
                          setNewAddress({
                            ...newAddress,
                            locality: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Locality</option>
                        {localityOptions.map((city, index) => (
                          <option key={index} value={city}>
                            {city}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 mb-2 focus:outline-none"
                        placeholder="Enter Locality"
                        value={newAddress.locality}
                        disabled={!newAddress.country}
                        required
                        onChange={(e) =>
                          setNewAddress({
                            ...newAddress,
                            locality: e.target.value,
                          })
                        }
                      />
                    )}

                    <input
                      type="text"
                      className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 focus:outline-none mb-2"
                      placeholder="Street Address 1"
                      disabled={!newAddress.country}
                      value={newAddress.street1}
                      required
                      onChange={(e) =>
                        setNewAddress({
                          ...newAddress,
                          street1: e.target.value,
                        })
                      }
                    />

                    <input
                      type="text"
                      className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 focus:outline-none"
                      placeholder="Street Address 2"
                      disabled={!newAddress.country}
                      value={newAddress.street2}
                      onChange={(e) =>
                        setNewAddress({
                          ...newAddress,
                          street2: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="flex justify-end space-x-3 mt-4 text-sm font-medium">
                    <button
                      onClick={() => setIsModalOpen(false)}
                      className="px-4 py-2 text-gray-600 border border-gray-300 rounded-md"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSaveAddress}
                      className="px-4 py-2 bg-[#eebd2d] text-white rounded-md"
                    >
                      {editMode ? "Save Changes" : "Add Address"}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Billing Address */}

        <BillingAddress />

        {/* Business Benefits */}
        {/* <div class="relative p-4 flex items-center mb-3  overflow-hidden font-medium transition-all bg-[#FFC94A] rounded-md group">
        <span class="absolute top-0 right-0 inline-block w-3 h-3 md:w-4 md:h-4 transition-all duration-500 ease-in-out bg-[#e2a731] rounded ">
          <span class="absolute top-0 right-0 w-5 h-5 rotate-45 translate-x-1/2 -translate-y-1/2 bg-slate-200"></span>
        </span>
        <span class="absolute bottom-0 rotate-180 left-0 inline-block w-3 h-3 md:w-4 md:h-4 transition-all duration-500 ease-in-out bg-[#e2a731] rounded ">
          <span class="absolute top-0 right-0 w-5 h-5 rotate-45 translate-x-1/2 -translate-y-1/2 bg-slate-200"></span>
        </span>
        <span class="relative w-full text-left text-[#212121] transition-colors duration-200 ease-in-out group-hover:text-[#111111]">
          <h3 className="font-bold mb-3">Why Switch as Business User?</h3>
          <p>
            Enjoy tax benefits, better invoicing options, and management tools
            tailored for business users.
          </p>
        </span>
      </div> */}
        <div className="flex md:flex-row max-md:flex-col justify-between bg-gray-100 p-2 rounded-2xl mb-3 border-2 ">
          <div class="relative flex flex-col justify-between gap-4 p-3 w-full md:w-[51%] bg-gray-100  shadow-[inset_0_-16px_24px_0_rgba(255,255,255,0.25)]">
            <div class="">
              <span class="card_title text-[#414141] font-bold text-lg">
                Individual
              </span>
              <p class="font-medium text-paragraph text-xs mt-1 w-[65%]">
                Offer simplicity and control for personal financial needs.
              </p>
            </div>
            <ul class="card__list flex flex-col gap-2">
              <li class="card__list_item flex items-start gap-2">
                <span class="check flex justify-center items-center w-3 h-3 md:w-4 md:h-4 bg-gray-500 rounded-full max-md:mt-1 md:mt-0.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-gray-500 rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#414141] text-sm">
                  Retail Quantities
                </span>
              </li>
              <li class="card__list_item flex items-start gap-2">
                <span class="check flex justify-center items-center w-3 h-3 md:w-4 md:h-4 bg-gray-500 rounded-full max-md:mt-1 md:mt-0.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-gray-500 rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#414141] text-sm">
                  Made-to-order
                </span>
              </li>
              <li class="card__list_item flex items-start gap-2">
                <span class="check flex justify-center items-center w-3 h-3 md:w-4 md:h-4 bg-gray-500 rounded-full max-md:mt-1 md:mt-0.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-gray-500 rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#414141] text-sm">
                  Logistics Quotes
                </span>
              </li>
              <li class="card__list_item flex items-start gap-2">
                <span class="check flex justify-center items-center w-3 h-3 md:w-4 md:h-4 bg-gray-500 rounded-full max-md:mt-1 md:mt-0.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-gray-500 rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#414141] text-sm">
                  Loyalty Benefits
                </span>
              </li>
              <li class="card__list_item flex items-start gap-2">
                <span class="check flex justify-center items-center w-3 h-3 md:w-4 md:h-4 bg-gray-500 rounded-full max-md:mt-1 md:mt-0.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-gray-500 rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#414141] text-sm">
                  Designer Catalogue
                </span>
              </li>
            </ul>
            <button class=" w-full group relative">
              <a
                href=""
                className="relative flex items-center w-full py-2 overflow-hidden font-medium transition-all bg-[#5e5e5e] rounded-md  mt-3"
              >
                <span class="absolute bottom-0 left-0 w-full h-full transition-all duration-500 ease-in-out delay-200 -translate-x-full bg-[#4b4b4b] rounded-md group-hover:translate-x-0"></span>
                <span class="relative max-md:text-xs w-full text-center font-semibold text-[#fff] transition-colors duration-200 ease-in-out group-hover:text-[#fff]">
                  Continue as Individual
                </span>
              </a>
            </button>
          </div>
          <div class="card relative flex flex-col justify-between gap-4 p-3 w-full md:w-[49%] bg-[#ffd667] rounded-xl shadow-[inset_0_-16px_24px_0_rgba(255,255,255,0.25)] border-4 border-transparent  bg-clip-padding bg-gradient-to-t from-[#ffd667] to-[#fcc93e]">
            <div class="card_title__container">
              <span class="card_title text-[#212121] font-bold text-lg">
                Business
              </span>
              <p class="font-semibold text-paragraph text-xs mt-1 w-[85%]">
                Offer growth, flexibility, and greater financial control.
              </p>
            </div>
            <ul class="card__list flex flex-col gap-2">
              <li class="card__list_item flex items-start  gap-2">
                <span class="check flex justify-center items-center  max-md:mt-1 md:mt-0.5 w-3 h-3 md:w-4 md:h-4 bg-[#c58f22] rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-[#c58f22]  rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#212121] font-semibold text-sm">
                  MOQ in multiples of 40 pairs
                </span>
              </li>
              <li class="card__list_item flex items-start gap-2">
                <span class="check flex justify-center items-center  max-md:mt-1 md:mt-0.5 w-3 h-3 md:w-4 md:h-4 bg-[#c58f22] rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-[#c58f22]  rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#212121] font-semibold text-sm">
                  AI-powered design Studio
                </span>
              </li>
              <li class="card__list_item flex items-start gap-2">
                <span class="check flex justify-center items-center  max-md:mt-1 md:mt-0.5 w-3 h-3 md:w-4 md:h-4 bg-[#c58f22] rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-[#c58f22]  rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#212121] font-semibold text-sm">
                  Stage-by-Stage Production Updates
                </span>
              </li>
              <li class="card__list_item flex items-start gap-2">
                <span class="check flex justify-center items-center  max-md:mt-1 md:mt-0.5 w-3 h-3 md:w-4 md:h-4 bg-[#c58f22] rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-[#c58f22]  rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#212121] font-semibold text-sm">
                  Drop-shipping API Gateway
                </span>
              </li>
              <li class="card__list_item flex items-start gap-2">
                <span class="check flex justify-center items-center  max-md:mt-1 md:mt-0.5 w-3 h-3 md:w-4 md:h-4 bg-[#c58f22] rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-[#c58f22]  rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#212121] font-semibold text-sm">
                  Self Brand/Logo styling and affixation
                </span>
              </li>
              <li class="card__list_item flex items-start gap-2">
                <span class="check flex justify-center items-center  max-md:mt-1 md:mt-0.5 w-3 h-3 md:w-4 md:h-4 bg-[#c58f22] rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="#fff"
                    class="bg-[#c58f22]  rounded-full text-black"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
                <span class="list_text text-[#212121] font-semibold text-sm">
                  Accessory Customization
                </span>
              </li>
            </ul>
            <button class="w-full group">
              <a
                href="/business"
                className="relative flex items-center w-full py-2 overflow-hidden font-medium transition-all bg-[#e4a92b] rounded-lg mt-3"
              >
                <span class="absolute bottom-0 left-0 w-full h-full transition-all duration-500 ease-in-out delay-200 -translate-x-full bg-[#cf9624] rounded-md group-hover:translate-x-0"></span>
                <span class="relative  max-md:text-xs w-full text-center font-semibold text-white transition-colors duration-200 ease-in-out group-hover:text-[#111111]">
                  Switch as Business
                </span>
              </a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountPage;
