import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import "../PopUps/PopUp.css";
import CheckAuth from "../ServiceFunctions/CheckAuth.jsx";
import { GoDotFill } from "react-icons/go";

function getFirstItemLabel(type) {
  const user = sidebarData.find((user) => user.usertype === type);
  if (user && user.option[0].items && user.option[0].items[0]) {
    return user.option[0].items[0].label;
  }
  return ""; // default or fallback value if label not found
}

// Sidebar API
const sidebarData = [
  {
    usertype: "admin",
    option: [
      {
        id: 0,
        title: "Material Cost",
        icon: <img src="/images/adminImages/Material.svg" />,
        items: [
          {
            label: "Upper",
          },
          {
            label: "Sole",
          },
          {
            label: "Accessories",
          },
        ],
      },
      {
        id: 1,
        title: "Product Listing",
        icon: <img src="/images/adminImages/product listing.svg" />,
        items: [
          {
            label: "Boots",
          },
          {
            label: "Oxford",
          },
          {
            label: "Brogue",
          },
          {
            label: "Mong",
          },
          {
            label: "Shipon",
          },
          {
            label: "Sneakers",
          },
          {
            label: "Sandals",
          },
        ],
      },
      {
        id: 2,
        title: "Order History",
        icon: <img src="/images/adminImages/orderhistory.svg" />,
        items: [
          {
            label: "Delivered Shipment",
          },
          {
            label: "Ledgers & Invoice",
          },
          {
            label: "Loyalty Benefits",
          },
        ],
      },
      {
        id: 3,
        title: "Production Stages",
        icon: <img src="/images/adminImages/productionstages.svg" />,
      },
      {
        id: 4,
        title: "eCommerce API",
        icon: <img src="/images/adminImages/ecommerceapi.svg" />,
        items: [
          {
            label: "Sandbox",
          },
          {
            label: "Console Log",
          },
          {
            label: "SKU selection",
          },

          {
            label: "Inventory Manager",
          },
          {
            label: "Membership",
          },
        ],
      },
      {
        id: 5,
        title: "Connect",
        icon: <img src="/images/adminImages/connect.svg" />,
        items: [
          {
            label: "Notifications",
          },
          {
            label: "Enquiry",
          },
          {
            label: "Video Call",
          },

          {
            label: "Session Info",
          },
        ],
      },
      {
        id: 6,
        title: "Sales Report",
        icon: <img src="/images/adminImages/sales Report.svg" />,
        items: [
          {
            label: "Leads Status",
          },
          {
            label: "Employee KPI",
          },
          {
            label: "Revenue Tracker",
          },

          {
            label: "Budget Allocation",
          },
          {
            label: "Calender",
          },
        ],
      },
    ],
  },
  {
    usertype: "supplier",
    option: [
      {
        id: 0,
        title: "Product Listing",
        icon: <img src="/images/adminImages/product listing.svg" />,
        items: [
          {
            label: "Boots",
          },
          {
            label: "Oxford",
          },
          {
            label: "Brogue",
          },
          {
            label: "Mong",
          },
          {
            label: "Shipon",
          },
          {
            label: "Sneakers",
          },
          {
            label: "Sandals",
          },
        ],
      },
      {
        id: 1,
        title: "Purchase Order",
        icon: <img src="/images/adminImages/Purchase order.svg" />,
        items: [
          {
            label: "Spec Sheet",
          },
          {
            label: "Upload Bill",
          },
          {
            label: "Supply Chain",
          },
          {
            label: "Sampling",
          },
        ],
      },
      {
        id: 2,
        title: "Production Stages",
        icon: <img src="/images/adminImages/productionstages.svg" />,
      },
      {
        id: 3,
        title: "Order History",
        icon: <img src="/images/adminImages/orderhistory.svg" />,
        items: [
          {
            label: "Delivered Shipment",
          },
          {
            label: "Ledgers & Invoice",
          },
          {
            label: "Loyalty Benefits",
          },
        ],
      },

      {
        id: 4,
        title: "Tech Pack",
        icon: <img src="/images/adminImages/connect.svg" />,
        items: [
          {
            label: "Box Label",
          },
          {
            label: "Tags",
          },
          {
            label: "Branding",
          },
        ],
      },
      {
        id: 5,
        title: "Photoshoot",
        icon: <img src="/images/adminImages/photoshoot.svg" />,
        items: [
          {
            label: "Angles & Setup",
          },
          {
            label: "Creative & Video",
          },
          {
            label: "Resource Library",
          },
        ],
      },
      {
        id: 6,
        title: "Connect",
        icon: <img src="/images/adminImages/connect.svg" />,
        items: [
          {
            label: "Notifications",
          },
          {
            label: "Enquiry",
          },
          {
            label: "Video Call",
          },

          {
            label: "Session Info",
          },
        ],
      },
    ],
  },
  {
    usertype: "individual",
    option: [
      {
        id: 0,
        title: "Customize 3D",
        icon: <img src="/images/adminImages/Customize 3D.svg" />,
        items: [
          {
            label: "Boots",
          },
          {
            label: "Oxford",
          },
          {
            label: "Brogue",
          },
          {
            label: "Monk",
          },
          {
            label: "Shipon",
          },
          {
            label: "Sneakers",
          },
          {
            label: "Sandals",
          },
        ],
      },
      {
        id: 1,
        title: "AI studio",
        icon: <img src="/images/adminImages/aistudio.svg" />,
      },
      {
        id: 2,
        title: "Production Stages",
        icon: <img src="/images/adminImages/productionstages.svg" />,
      },
      {
        id: 3,
        title: "Order History",
        icon: <img src="/images/adminImages/orderhistory.svg" />,
        items: [
          {
            label: "Delivered Shipment",
          },
          {
            label: "Ledgers & Invoice",
          },
          {
            label: "Loyalty Benefits",
          },
        ],
      },
      {
        id: 4,
        title: "Catalogue",
        icon: <img src="/images/adminImages/accessories.svg" />,
      },
      {
        id: 5,
        title: "Accessories",
        icon: <img src="/images/adminImages/accessories.svg" />,
      },
      {
        id: 6,
        title: "Shipping",
        icon: <img src="/images/adminImages/shipping.svg" />,
        items: [
          {
            label: "Request-for-Quote",
          },
          {
            label: "Logistics payment",
          },
          {
            label: "Shipment tracking",
          },
        ],
      },
      {
        id: 7,
        title: "Connect",
        icon: <img src="/images/adminImages/connect.svg" />,
        items: [
          {
            label: "Notifications",
          },
          {
            label: "Enquiry",
          },
          {
            label: "Video Call",
          },
          {
            label: "Session Info",
          },
        ],
      },
    ],
  },
  {
    usertype: "businessUser",
    option: [
      {
        id: 0,
        title: "Customize 3D",
        icon: <img src="/images/adminImages/Customize 3D.svg" />,
        items: [
          {
            label: "Boots",
          },
          {
            label: "Oxford",
          },
          {
            label: "Brogue",
          },
          {
            label: "Monk",
          },
          {
            label: "Slipon",
          },
          {
            label: "Sneakers",
          },
          {
            label: "Sandals",
          },
        ],
      },
      {
        id: 1,
        title: "AI studio",
        icon: <img src="/images/adminImages/aistudio.svg" />,
      },
      {
        id: 2,
        title: "eCommerce API",
        icon: <img src="/images/adminImages/ecommerceapi.svg" />,
        items: [
          {
            label: "Sandbox",
          },
          {
            label: "Console Log",
          },
          {
            label: "SKU selection",
          },

          {
            label: "Inventory Manager",
          },
          {
            label: "Membership",
          },
        ],
      },
      {
        id: 3,
        title: "Production Stages",
        icon: <img src="/images/adminImages/productionstages.svg" />,
      },
      {
        id: 4,
        title: "Order History",
        icon: <img src="/images/adminImages/orderhistory.svg" />,
        items: [
          {
            label: "Delivered Shipment",
          },
          {
            label: "Ledgers & Invoice",
          },
          {
            label: "Loyalty Benefits",
          },
        ],
      },
      {
        id: 5,
        title: "Catalogue",
        icon: <img src="/images/adminImages/accessories.svg" />,
      },
      {
        id: 6,
        title: "Accessories",
        icon: <img src="/images/adminImages/accessories.svg" />,
      },
      {
        id: 7,
        title: "Photoshoot",
        icon: <img src="/images/adminImages/photoshoot.svg" />,
        items: [
          {
            label: "Angles & setup",
          },
          {
            label: "Creative & Video",
          },
          {
            label: "Resource Library",
          },
        ],
      },
      {
        id: 8,
        title: "Shipping",
        icon: <img src="/images/adminImages/shipping.svg" />,
        items: [
          {
            label: "Request-for-Quote",
          },
          {
            label: "Logistics payment",
          },
          {
            label: "Shipment tracking",
          },
        ],
      },
      {
        id: 9,
        title: "Connect",
        icon: <img src="/images/adminImages/connect.svg" />,
        items: [
          {
            label: "Notifications",
          },
          {
            label: "Enquiry",
          },
          {
            label: "Video Call",
          },

          {
            label: "Session Info",
          },
        ],
      },
    ],
  },
];

function SideBar({ onSelectMenuItem }) {
  const { type } = CheckAuth();
  const [menuItem, setMenuItem] = useState("");
  const [isOpen, setIsOpen] = useState(true); // Sidebar open/close state
  const [aboutOpen, aboutIsOpen] = useState(false);
  const [openSection, setOpenSection] = useState(0); // Track open section
  const [openSectionEffect, setOpenSectionEffect] = useState(0); // Track open section
  const [activeId, setActiveId] = useState(0); //use for bottom sidebar
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      if (!isOpen) setOpenSection(null);
    }
  };

  useEffect(() => {
    if (openSection !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openSection]);

  useEffect(() => {
    const defaultMenuItem = getFirstItemLabel(type);
    setMenuItem(defaultMenuItem);
    if (onSelectMenuItem) {
      onSelectMenuItem(defaultMenuItem);
    }
  }, [type]);

  // About us API
  const options = ["Shoemaking", "Guide", "Blogs"];

  const toggleSidebar = () => {
    setIsOpen((prev) => !prev); // Toggle sidebar state
  };

  // Filter sidebar data based on user type
  const userSidebar = sidebarData.find(
    (data) => data.usertype === (type !== "" ? type : "individual")
  );

  const SidebarSection = ({ section, isActive, onClick }) => {
    const hasLabel = !!section.label;
    return (
      <div
        onClick={onClick}
        className={`flex flex-col items-center justify-center p-2 w-[65px] h-16 ${
          isActive
            ? "border-[#FFD61F] border-t-[3px] bg-[#FFF4B5] duration-500 rounded-b-lg"
            : "border-t-[3px] border-[#ffffff] rounded-lg"
        } ${hasLabel ? "text-red-500" : ""}`} // Apply different styling if `label` exists
      >
        <span className="w-6 h-6">{section.icon}</span>
        <p className="text-center leading-[11px] mt-1">
          {hasLabel ? section.label : section.title}{" "}
        </p>
      </div>
    );
  };
  const SidebarSection2 = ({ section, isActive, onClick }) => {
    const hasLabel = !!section.label;
    return (
      <div
        onClick={onClick}
        className={`flex flex-col items-center justify-center p-2 w-[65px] h-16 ${
          isActive
            ? "border-[#FFD61F] border-t-[3px] bg-[#FFF4B5] duration-500 rounded-lg"
            : "border-t-[3px] border-[#ffffff] rounded-lg bg-zinc-100"
        } ${hasLabel ? "text-red-500" : ""}`} // Apply different styling if `label` exists
      >
        <span className="w-6 h-6">{section.icon}</span>
        <p className="text-center leading-[11px] mt-1">
          {hasLabel ? section.label : section.title}{" "}
        </p>
      </div>
    );
  };

  return (
    <>
      {/* Sub Nav bar */}
      {userSidebar?.option.some((data) => data.id === activeId) && (
        <div
          key={activeId} // Changing key forces component to re-render and restart animation
          className="fixed top-[57px] md:top-[80px] z-10 w-screen sidebar-content animate-slide lg:hidden"
        >
          {userSidebar?.option
            .filter((data) => data.id === activeId)
            .map((section) => (
              <div
                key={section.id}
                className="text-xs flex overflow-x-scroll px-2 "
              >
                {section.items?.map((item, itemIndex) => (
                  <p
                    key={itemIndex}
                    onClick={() => {
                      onSelectMenuItem(item.label);
                    }}
                    className={`px-2 py-0.5 text-white ml-2 my-2 whitespace-nowrap flex items-center  ${
                      activeId === section.id ? "w-fit" : ""
                    }`}
                  >
                    <span className="w-4">
                      <GoDotFill />
                    </span>
                    {item.label}
                  </p>
                ))}
              </div>
            ))}
        </div>
      )}

      {/* Sidebar Lg */}
      <div
        className={`p-2 z-10 transition-all duration-500 ease-in-out hidden lg:inline-flex h-[calc(100vh-6rem)] relative ${
          isOpen ? "lg:w-[25%] xl:w-[21%] 2xl:w-[20%] biggersize" : "w-[95px]"
        }`}
      >
        <div
          className={`w-full p-3 text-base text-black flex flex-col justify-between gap-4 relative rounded-xl bg-[#ffffff8b] shadow transition-all duration-500 ease-in-out ${
            isOpen
              ? "opacity-100 overflow-y-scroll no-scrollbar"
              : "pointer-events-auto"
          }`}
        >
          {/* Render Sidebar Options */}
          <div className="flex flex-col gap-3" ref={dropdownRef}>
            {userSidebar?.option.map((section, sectionIndex) => {
              const hasLabels = section.items && section.items.length > 0;
              return (
                <div key={sectionIndex} className="rounded-lg">
                  <h2 className="relative">
                    <div
                      className={`flex items-center justify-between w-full text-sm p-2 cursor-pointer ${
                        openSectionEffect === sectionIndex
                          ? "text-black bg-slate-100 shadow-sm"
                          : "text-gray-600"
                      } ${
                        isOpen
                          ? "rounded-md hover:shadow-sm"
                          : "rounded-md hover:shadow-sm"
                      }`}
                      onClick={() => {
                        setOpenSectionEffect(sectionIndex);
                        const exist = section.items?.find(
                          (item) => item.label === menuItem
                        );
                        if (exist) {
                          onSelectMenuItem(
                            hasLabels === undefined ? section.title : menuItem
                          );
                          setMenuItem(
                            hasLabels === undefined ? section.title : menuItem
                          );
                        } else {
                          onSelectMenuItem(
                            hasLabels === undefined
                              ? section.title
                              : section.items[0].label
                          );
                          setMenuItem(
                            hasLabels === undefined
                              ? section.title
                              : section.items[0].label
                          );
                        }
                        if (sectionIndex === openSection) {
                          setOpenSection(null);
                        } else {
                          setOpenSection(sectionIndex);
                        }
                      }}
                    >
                      <div className="flex items-center gap-3">
                        {/* Icon Always Visible */}
                        <span className={`w-6 h-6 xl:w-7 xl:h-7`}>
                          {section.icon}
                        </span>

                        {/* Title Visibility Based on Sidebar State */}
                        <span
                          className={`transition-all duration-500 font-semibold ${
                            isOpen ? "inline-flex" : "hidden"
                          }`}
                        >
                          {section.title}
                        </span>
                      </div>
                      {/* Toggle Icon for Sections with Labels */}
                      {hasLabels && (
                        <span
                          className={`transform duration-500 ${
                            isOpen ? "inline-flex" : "hidden"
                          } ${
                            openSection === sectionIndex ? "rotate-180" : ""
                          }`}
                        >
                          <IoIosArrowDown />
                        </span>
                      )}
                    </div>

                    {/* Conditionally Render Labels if Available */}
                    {hasLabels && (
                      <div
                        className={`overflow-hidden flex flex-col gap-2 px-2 ${
                          openSection === sectionIndex
                            ? "max-h-screen py-2"
                            : "max-h-0"
                        } ${
                          isOpen
                            ? "transition-all ease-in-out duration-500"
                            : "absolute top-0 left-12 z-50 shadow-md bg-white rounded-lg w-[180px]"
                        }
                  `}
                      >
                        {section.items.map((item, itemIndex) => (
                          <button
                            key={itemIndex}
                            className={`flex items-center p-2 duration-200 cursor-pointer rounded-r-md w-full gap-2 text-sm border-l-2 border-[#FFD61F] ${
                              menuItem === item.label
                                ? "bg-[#FFF4B5]"
                                : "hover:bg-[#FFF4B5]"
                            }`}
                            onClick={() => {
                              setMenuItem(item.label);
                              onSelectMenuItem(item.label);
                              if (!isOpen) setOpenSection(null);
                            }}
                          >
                            {item.label}
                          </button>
                        ))}
                      </div>
                    )}
                  </h2>
                </div>
              );
            })}
          </div>

          {/* About Option */}
          <div className="relative">
            {/* Toggle Button */}
            <button
              onClick={() => aboutIsOpen((prev) => !prev)}
              className={`flex items-center justify-between p-2 duration-200 cursor-pointer rounded-md w-full gap-2 text-base bg-slate-50 shadow-sm`}
            >
              <span className="flex items-center gap-3">
                <img
                  src="/images/adminImages/about.svg"
                  className="w-6 h-6 xl:w-7 xl:h-7"
                  alt="About"
                />
                <span className={`${isOpen ? "inline-flex" : "hidden"}`}>
                  About us
                </span>
              </span>
              <span
                className={`transform duration-500  ${
                  isOpen ? "inline-flex -rotate-90" : "hidden"
                }`}
              >
                <IoIosArrowDown />
              </span>
            </button>

            {/* Dropdown Content with Smooth Transition */}
            <div
              className={`${
                isOpen
                  ? "flex flex-col gap-2 overflow-hidden bg-white absolute -top-[150px] p-2 rounded-md shadow-md w-fit z-[200]"
                  : "flex flex-col gap-2 bg-white absolute -top-[100px] left-12 p-2 rounded-md shadow-md w-fit z-[20000]"
              } ${
                aboutOpen
                  ? "inline-flex origin-top-right transition-all duration-500 ease-in-out scale-100"
                  : "hidden transition-all duration-500 ease-in-out scale-0"
              }`}
            >
              {options.map((option, index) => (
                <button
                  key={index}
                  className="flex items-center p-2 duration-200 cursor-pointer rounded-r-md w-full gap-2 text-sm min-w-[180px] border-l-2 border-[#FFD61F] hover:bg-[#FFF4B5]"
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Toggle Arrow */}
        <span
          className={`absolute top-[50%] -translate-y-[50%] p-1 text-3xl text-black -right-5 bg-[#FFD61F] hover:bg-[#FEC23D] hover:text-white duration-200 cursor-pointer hover:shadow-md z-[20] ${
            !isOpen
              ? "rounded-full -right-5 duration-300"
              : "rotate-180 -right-5 rounded-full duration-300"
          }`}
          onClick={toggleSidebar}
        >
          <svg
            viewBox="0 0 24 24"
            className="w-7 h-7"
            xmlns="http://www.w3.org/2000/svg"
            id="fi_2722998"
          >
            <g id="_19" data-name="19">
              <path d="m12 19a1 1 0 0 1 -.71-1.71l5.3-5.29-5.3-5.29a1 1 0 0 1 1.41-1.41l6 6a1 1 0 0 1 0 1.41l-6 6a1 1 0 0 1 -.7.29z"></path>
              <path d="m6 19a1 1 0 0 1 -.71-1.71l5.3-5.29-5.3-5.29a1 1 0 0 1 1.42-1.42l6 6a1 1 0 0 1 0 1.41l-6 6a1 1 0 0 1 -.71.3z"></path>
            </g>
          </svg>
        </span>
      </div>

      {/* Bottom Sidebar popup */}
      {isOptionOpen && (
        <div className="text-[10px] justify-between items-end z-[19] font-bold lg:hidden h-[100vh] admin-popup">
          <div className="fixed bottom-[70px] flex flex-col items-start justify-between flex-wrap gap-3 p-3 w-full min-h-[125px] bg-white rounded-t-3xl admin-popup-animation">
            <div className="flex items-start justify-between flex-wrap gap-3">
              {userSidebar?.option.slice(2, 3).map((section) => (
                <SidebarSection2
                  key={section.id}
                  section={section}
                  isActive={activeId === section.id}
                  onClick={() => {
                    const hasLabels = section.items && section.items.length > 0;
                    onSelectMenuItem(
                      hasLabels === undefined
                        ? section.title
                        : section.items[0].label
                    );
                    setMenuItem(
                      hasLabels === undefined
                        ? section.title
                        : section.items[0].label
                    );
                    setActiveId(section.id);
                    setIsOptionOpen(false);
                  }}
                />
              ))}

              {/* Second set of options - Conditionally render with SidebarSection */}
              {userSidebar?.option.slice(5).map((section) => (
                <SidebarSection2
                  key={section.id}
                  section={section}
                  isActive={activeId === section.id}
                  onClick={() => {
                    const hasLabels = section.items && section.items.length > 0;
                    onSelectMenuItem(
                      hasLabels === undefined
                        ? section.title
                        : section.items[0].label
                    );
                    setMenuItem(
                      hasLabels === undefined
                        ? section.title
                        : section.items[0].label
                    );
                    setActiveId(section.id);
                    setIsOptionOpen(false);
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Bottom sidebar */}
      <div className="fixed bottom-0 left-0 right-0 bg-white px-2.5 pb-2.5 w-full text-[10px] justify-between items-end z-[20] font-bold border lg:hidden">
        <div className="w-full flex justify-between">
          {userSidebar?.option.slice(0, 5).map((section) => (
            <>
              {section.id === 2 ? (
                <div
                  onClick={() => {
                    setIsOptionOpen(!isOptionOpen);
                    if (
                      section.id === 0 ||
                      section.id === 1 ||
                      section.id === 3 ||
                      section.id === 4
                    )
                      setActiveId(2);
                  }}
                  className={`w-[53px] h-[53px] border-2 shadow-sm flex flex-col items-center justify-center rounded-full mt-2 ${
                    section.id === 0 ||
                    section.id === 1 ||
                    section.id === 3 ||
                    section.id === 4
                      ? ""
                      : "bg-[#FFF4B5] duration-500 border-[#FFD61F]"
                  }`}
                >
                  <img
                    src="/images/adminImages/menu.svg"
                    className="w-6 h-6"
                    alt=""
                  />
                </div>
              ) : (
                <SidebarSection
                  key={section.id}
                  section={section}
                  isActive={activeId === section.id}
                  onClick={() => {
                    const hasLabels = section.items && section.items.length > 0;
                    onSelectMenuItem(
                      hasLabels === undefined
                        ? section.title
                        : section.items[0].label
                    );
                    setMenuItem(
                      hasLabels === undefined
                        ? section.title
                        : section.items[0].label
                    );
                    setActiveId(section.id);
                    setIsOptionOpen(false);
                  }}
                />
              )}
            </>
          ))}
        </div>
      </div>
    </>
  );
}

export default SideBar;
