import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import { FaShoePrints } from "react-icons/fa6";
import { BsBox2 } from "react-icons/bs";
import { TbTruckReturn } from "react-icons/tb";
import { GiHammerNails } from "react-icons/gi";

function ProductPage2() {
  const { Article, Color } = useParams();
  const [shoes, setShoes] = useState(null);
  const [available, setAvailable] = useState(false);
  const [showImage, setShowImage] = useState(null);
  const [selectedColor, setSelectedColor] = useState(Color);
  const [selectedSole, setSelectedSole] = useState(null);
  const [selectedItems, setSelectedItems] = useState(0);
  const [selectedLeather, setSelectedLeather] = useState(null);
  const [selectedFinishing, setSelectedFinishing] = useState(null);
  const [selectedConstruction, setSelectedConstruction] = useState(null);
  const [selectedWelt, setSelectedWelt] = useState(null);

  // Get the finishing options for the selected leather
  const getFinishingOptions = () => {
    if (!selectedLeather || !shoes.Leather[selectedLeather]) {
      return {};
    }
    return shoes.Leather[selectedLeather].Finishing || {};
  };

  const finishingOptions = getFinishingOptions();

  useEffect(() => {
    fetch(`http://127.0.0.1:8000/api/product-article/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        article: Article,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setShoes(data.product);
        if (data.product?.Images?.[selectedColor]?.Side1) {
          setShowImage(data.product.Images[selectedColor].Side1);
          setAvailable(true); // Set available to true when the image is found
        } else {
          setAvailable(false); // Set available to false when the image is not found
          setShowImage(null); // Clear image
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }, [Article, selectedColor]);

  const imageKeys = shoes?.Images?.[selectedColor]
    ? Object.keys(shoes.Images[selectedColor])
    : [];

  const getUniqueColors = (keyPath) => {
    const uniqueColors = new Set();
    const shoesArray = Array.isArray(shoes) ? shoes : [shoes];
    shoesArray?.forEach((shoe) => {
      const keys = keyPath?.split(".");
      let value = shoe;

      keys?.forEach((key) => {
        if (value && value[key]) {
          value = value[key];
        } else {
          value = null;
        }
      });
      if (value && typeof value === "object") {
        Object.values(value)?.forEach((subValue) => {
          if (subValue && typeof subValue === "object") {
            Object.keys(subValue.Color)?.forEach((color) => {
              uniqueColors.add(color);
            });
          }
        });
      }
    });
    return Array.from(uniqueColors);
  };

  const colors = getUniqueColors("Leather");
  return (
    <>
      <Header />
      <div className="mx-20 px-20 p-4 min-h-screen">
        <div className="flex justify-between pt-6 items-start">
          <div className="w-[47%]">
            <h3 className="text-xl pt-4 mb-2 border-b-2 border-b-black">
              {shoes?.Category} ({selectedColor})
            </h3>
            <div className="flex justify-between items-start">
              <div className="w-[90%] flex justify-center items-center">
                {available && showImage ? (
                  <img
                    src={`${showImage}`}
                    alt={shoes?.Category}
                    className="transition-all duration-500 ease-in-out h-[500px] w-[500px]"
                  />
                ) : (
                  <h6 className="text-sm text-red-500">Not Available</h6>
                )}
              </div>
              <div className="w-[10%]">
                {available &&
                  imageKeys?.map((key) => (
                    <img
                      key={key}
                      src={`${shoes?.Images?.[selectedColor]?.[key]}`}
                      alt={`${shoes?.Category} - ${key}`}
                      className={`${
                        showImage === shoes.Images[selectedColor][key]
                          ? "opacity-100"
                          : "opacity-45"
                      } transition-all duration-500 ease-in-out h-full w-full p-1 border m-1`}
                      onClick={() =>
                        setShowImage(shoes.Images[selectedColor][key])
                      }
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col w-[47%] justify-between items-center">
            <h5 className="border flex text-sm font-light rounded-lg w-full border-black items-center justify-center p-4">
              <FaShoePrints size={20} />
              <span className="mx-2">Customize your shoes below</span>{" "}
              <FaShoePrints size={20} />
            </h5>
            <div className="flex w-full h-[500px] overflow-y-scroll ">
              <div className="w-[20%] border-e border-e-black flex flex-col justify-between">
                <div className="flex-col items-start">
                  <div className="border-b border-b-black">
                    <button
                      className="p-3 text-start hover:bg-slate-100 w-full"
                      onClick={() => {
                        setSelectedItems(0);
                      }}
                    >
                      Colors
                    </button>
                  </div>
                  <div className="border-b border-b-black">
                    <button
                      className="p-3 text-start hover:bg-slate-100 w-full"
                      onClick={() => {
                        setSelectedItems(1);
                      }}
                    >
                      {" "}
                      Leathers
                    </button>
                  </div>
                  <div className="border-b border-b-black">
                    <button
                      className="p-3 text-start hover:bg-slate-100 w-full"
                      onClick={() => {
                        setSelectedItems(2);
                      }}
                    >
                      {" "}
                      Soles
                    </button>
                  </div>
                  <div className="border-b border-b-black">
                    <button
                      className="p-3 text-start hover:bg-slate-100 w-full"
                      onClick={() => {
                        setSelectedItems(3);
                      }}
                    >
                      Size
                    </button>
                  </div>
                  <div className="border-b border-b-black">
                    <button
                      className="p-3 text-start hover:bg-slate-100 w-full"
                      onClick={() => {
                        setSelectedItems(4);
                      }}
                    >
                      Quantity
                    </button>
                  </div>
                </div>
                <div className="border-t border-t-black border-b p-4 text-red-400 border-b-black">
                  &#8377; 2500
                </div>
              </div>
              <div className="w-[80%]">
                {/* color */}
                {selectedItems === 0 && (
                  <div className="flex w-full items-center justify-start p-4">
                    {colors?.map((color) => (
                      <span
                        key={color}
                        className={`h-10 w-10 mx-3 rounded-full cursor-pointer transition-all inline-block ${
                          color.toLowerCase() === "brown"
                            ? "bg-[#4b231c]"
                            : color.toLowerCase() === "blue"
                            ? "bg-[#022b6c]"
                            : color.toLowerCase() === "tan"
                            ? "bg-[#885035]"
                            : `bg-${color.toLowerCase()}`
                        } ${
                          selectedColor === color
                            ? ` border-2 border-blue-500 scale-110`
                            : ` border border-gray-300 hover:scale-110`
                        }`}
                        onClick={() => {
                          if (shoes?.Images?.[color]) {
                            setSelectedColor(color);
                            setShowImage(shoes.Images[color].Side1);
                            setAvailable(true);
                          } else {
                            setSelectedColor(color);
                            setAvailable(false);
                            setShowImage(null);
                          }
                        }}
                      ></span>
                    ))}
                  </div>
                )}
                {/* leather */}

                {selectedItems === 1 && (
                  <>
                    <div className="grid grid-cols-2 gap-4 p-4">
                      {shoes?.Leather &&
                        Object.keys(shoes.Leather)
                          .filter((item) => item !== "Consumption")
                          ?.map((leather) => (
                            <div
                              key={leather}
                              className="cursor-pointer text-sm flex items-center"
                              onClick={() => {
                                if (shoes.Leather[leather]) {
                                  setSelectedLeather(leather);
                                  setSelectedFinishing(null); // Reset finishing selection
                                } else {
                                  setSelectedLeather(leather);
                                  setSelectedFinishing(null);
                                }
                              }}
                            >
                              <span
                                className={`h-10 w-10 mx-3 rounded-full cursor-pointer transition-all inline-block ${
                                  selectedLeather === leather
                                    ? `border-2 border-blue-500 scale-110`
                                    : `border border-gray-300 hover:scale-110`
                                }`}
                              ></span>
                              {leather}
                            </div>
                          ))}
                    </div>

                    {selectedLeather && (
                      <>
                        <h5 className="text-sm font-light rounded-lg w-full p-4 pb-0">
                          Choose Finishing:
                        </h5>
                        <div className="grid grid-cols-2 gap-4 p-4">
                          {Object.keys(finishingOptions)?.map((finishing) => (
                            <label
                              key={finishing}
                              className="cursor-pointer text-sm flex items-center ml-4"
                            >
                              <input
                                type="radio"
                                name="finishing"
                                value={finishing}
                                checked={selectedFinishing === finishing}
                                onChange={(e) =>
                                  setSelectedFinishing(e.target.value)
                                }
                              />
                              <span className="ml-2">{finishing}</span>
                            </label>
                          ))}
                        </div>
                      </>
                    )}
                  </>
                )}

                {/* Sole */}
                {selectedItems === 2 && (
                  <>
                    <div className="grid grid-cols-2 gap-4 p-4">
                      {shoes?.Sole &&
                        Object.keys(shoes.Sole)?.map((sole) => (
                          <label
                            key={sole}
                            className="cursor-pointer text-sm flex items-center ml-4"
                          >
                            <input
                              type="radio"
                              name="sole"
                              value={sole}
                              checked={selectedSole === sole}
                              onChange={(e) => {
                                setSelectedSole(e.target.value);
                                if (shoes.Sole[sole]) {
                                  setSelectedSole(sole);
                                  setSelectedConstruction(null); // Reset Construction selection
                                } else {
                                  setSelectedSole(sole);
                                  setSelectedConstruction(null);
                                }
                              }}
                            />
                            <span className="ml-2">{sole}</span>
                          </label>
                        ))}
                    </div>

                    {selectedSole && (
                      <div className="">
                        <h5 className="text-sm font-light rounded-lg w-full p-4 pb-0">
                          Choose Construction:
                        </h5>
                        <div className="grid grid-cols-2 gap-4 p-4">
                          {shoes?.Sole &&
                            Object.keys(shoes.Sole[selectedSole])
                              .filter((item) => item !== "Price_INR")
                              ?.map((construction) => (
                                <label
                                  key={construction}
                                  className="cursor-pointer text-sm flex items-center ml-4"
                                >
                                  <input
                                    type="radio"
                                    name="construction"
                                    value={construction}
                                    checked={
                                      selectedConstruction === construction
                                    }
                                    onChange={(e) => {
                                      setSelectedConstruction(e.target.value);
                                      if (
                                        shoes.Sole[selectedSole][construction]
                                      ) {
                                        setSelectedConstruction(construction);
                                        setSelectedWelt(null);
                                      } else {
                                        setSelectedConstruction(construction);
                                        setSelectedWelt(null);
                                      }
                                    }}
                                  />
                                  <span className="ml-2">{construction}</span>
                                </label>
                              ))}
                        </div>
                      </div>
                    )}

                    {selectedConstruction && (
                      <div className="">
                        <h5 className="text-sm font-light rounded-lg w-full p-4 pb-0">
                          Choose Welt:
                        </h5>
                        <div className="grid grid-cols-2 gap-4 p-4">
                          {shoes?.Sole &&
                            Object.keys(
                              shoes.Sole[selectedSole][selectedConstruction]
                            )?.map((welt) => (
                              <label
                                key={welt}
                                className="cursor-pointer text-sm flex items-center ml-4"
                              >
                                <input
                                  type="radio"
                                  name="welt"
                                  value={welt}
                                  checked={selectedWelt === welt}
                                  onChange={(e) => {
                                    setSelectedWelt(e.target.value);
                                    if (
                                      shoes.Sole[selectedSole][
                                        selectedConstruction
                                      ][welt]
                                    ) {
                                      setSelectedWelt(welt);
                                    } else {
                                      setSelectedWelt(welt);
                                    }
                                  }}
                                />
                                <span className="ml-2">{welt}</span>
                              </label>
                            ))}
                        </div>
                      </div>
                    )}
                  </>
                )}
                {selectedItems === 3 && (
                  <>
                    <div className="grid grid-cols-3 gap-4 p-4">
                      <div className="border rounded-md h-20 w-20 border-black flex items-center justify-center">
                        6
                      </div>
                      <div className="border rounded-md h-20 w-20 border-black flex items-center justify-center">
                        7
                      </div>
                      <div className="border rounded-md h-20 w-20 border-black flex items-center justify-center">
                        8
                      </div>
                      <div className="border rounded-md h-20 w-20 border-black flex items-center justify-center">
                        10
                      </div>
                    </div>
                  </>
                )}
                {selectedItems === 4 && (
                  <>
                    <div className="p-4">
                      <label htmlFor="quantity">Quantity: </label>
                      <input
                        type="number"
                        name=""
                        className="border border-black rounded-md px-2"
                        id="quantity"
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-4 p-4">
                      <div className="border rounded-md border-black flex items-center justify-center">
                        50
                      </div>
                      <div className="border rounded-md border-black flex items-center justify-center">
                        100
                      </div>
                      <div className="border rounded-md border-black flex items-center justify-center">
                        200
                      </div>
                      <div className="border rounded-md border-black flex items-center justify-center">
                        500
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="border-t border-t-gray-300 border-b border-b-gray-300 my-10 py-10 flex justify-around items-center">
          <div className="flex flex-col gap-0 items-center justify-center">
            <BsBox2 size={30} color="#000" />
            <span>Free Shipping</span>
          </div>
          <div className="flex flex-col gap-0 items-center justify-center">
            <TbTruckReturn size={30} color="#000" />
            <span>Easy Returns</span>
          </div>
          <div className="flex flex-col gap-0 items-center justify-center">
            <GiHammerNails size={30} color="#000" />
            <span>Made in India</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductPage2;
