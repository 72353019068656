import React, { useState, useEffect } from "react";

const BillingAddress = () => {
  const [localityOptions, setLocalityOptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [pincode, setPincode] = useState("");

  const [billingAddress, setBillingAddress] = useState({
    country: "",
    postalCode: "",
    state: "",
    district: "",
    locality: "",
    street1: "",
    street2: "",
  });

  // Fetch countries from the REST Countries API
  const fetchCountries = async () => {
    try {
      const response = await fetch("https://restcountries.com/v3.1/all");
      if (response.ok) {
        const data = await response.json();
        const countryList = data.map((country) => ({
          code: country.cca2, // ISO 3166-1 alpha-2 code
          name: country.name.common, // Common name of the country
        }));
        // Sort countries alphabetically
        countryList.sort((a, b) => a.name.localeCompare(b.name));
        setCountries(countryList);
      } else {
        console.error("Failed to fetch countries");
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  // Fetch locality and state based on Indian postal code
  const fetchPincodeData = (value) => {
    setPincode(value);
    if (/^\d{6}$/.test(value)) {
      fetch(`https://api.postalpincode.in/pincode/${value}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((PostOffice) => {
          const state = PostOffice[0]?.PostOffice[0]?.State || "";
          const district = PostOffice[0]?.PostOffice[0]?.District || ""; // Get district
          const area = PostOffice[0]?.PostOffice.map((post) => post.Name) || [];
          setBillingAddress((prev) => ({
            ...prev,
            state: state,
            locality: area[0] || "", // Default to the first locality
            district: district || "", // Set district
          }));
          setLocalityOptions(area);
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    }
  };

  // Fetch locality and state for non-Indian countries
  const fetchLocationData = async (countryCode, postalCode) => {
    try {
      const response = await fetch(
        `https://api.zippopotam.us/${countryCode.toLowerCase()}/${postalCode}`
      );
      if (response.ok) {
        const data = await response.json();
        const { places } = data;
        if (places && places.length > 0) {
          setBillingAddress((prevAddress) => ({
            ...prevAddress,
            locality: places[0]["place name"],
            state: places[0]["state"],
          }));
        }
      } else {
        console.error("Invalid postal code or country.");
      }
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  };

  // Call the API when the component mounts to fetch countries
  useEffect(() => {
    fetchCountries();
  }, []);

  // Call appropriate API based on country and postal code
  useEffect(() => {
    if (billingAddress.country === "IN") {
      fetchPincodeData(billingAddress.postalCode);
    } else if (billingAddress.country && billingAddress.postalCode) {
      fetchLocationData(billingAddress.country, billingAddress.postalCode);
    }
  }, [billingAddress.country, billingAddress.postalCode]);



  return (
    <form className="mb-3">
      <h3 className="text-lg font-bold text-[#111111]">Billing Address:</h3>

      <select
        className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 my-2 focus:outline-none"
        value={billingAddress.country}
        required
        onChange={(e) =>
          setBillingAddress({
            ...billingAddress,
            country: e.target.value,
            state: "",
            locality: "",
            district: "",
          })
        }
      >
        <option value="" disabled>
          Select Country
        </option>
        {countries.map((country) => (
          <option key={country.code} value={country.code}>
            {country.name}
          </option>
        ))}
      </select>

      <input
        type="number"
        className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 mb-2 focus:outline-none"
        placeholder="Postal Code"
        required
        disabled={!billingAddress.country}
        value={billingAddress.postalCode}
        onChange={(e) =>
          setBillingAddress({
            ...billingAddress,
            postalCode: e.target.value,
          })
        }
      />

      <input
        type="text"
        className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 mb-2 focus:outline-none"
        placeholder="State"
        required
        disabled={!billingAddress.country}
        value={billingAddress.state}
        onChange={(e) =>
          setBillingAddress({ ...billingAddress, state: e.target.value })
        }
      />

      {billingAddress.country === "IN" && (
        <input
          type="text"
          className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 mb-2 focus:outline-none"
          placeholder="District"
          required
          disabled={!billingAddress.country}
          value={billingAddress.district}
          onChange={(e) =>
            setBillingAddress({
              ...billingAddress,
              district: e.target.value,
            })
          }
        />
      )}

      {billingAddress.country === "IN" ? (
        <select
          className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 mb-2 focus:outline-none"
          name="locality"
          id="locality"
          required
          disabled={!billingAddress.country}
          value={billingAddress.locality}
          onChange={(e) =>
            setBillingAddress({
              ...billingAddress,
              locality: e.target.value,
            })
          }
        >
          <option value="">Select Locality</option>
          {localityOptions.map((city, index) => (
            <option key={index} value={city}>
              {city}
            </option>
          ))}
        </select>
      ) : (
        <input
          type="text"
          className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 mb-2 focus:outline-none"
          placeholder="Enter Locality"
          required
          disabled={!billingAddress.country}
          value={billingAddress.locality}
          onChange={(e) =>
            setBillingAddress({
              ...billingAddress,
              locality: e.target.value,
            })
          }
        />
      )}

      <input
        type="text"
        className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 focus:outline-none mb-2"
        placeholder="Street Address 1"
        required
        disabled={!billingAddress.country}
        value={billingAddress.street1}
        onChange={(e) =>
          setBillingAddress({ ...billingAddress, street1: e.target.value })
        }
      />
      <input
        type="text"
        className="px-2 block w-full py-1.5 text-[#212121] ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 hover:ring-2 hover:ring-[#eebd2d] hover:outline-none focus:ring-2 focus:ring-[#eebd2d] text-sm sm:leading-6 focus:outline-none mb-2"
        placeholder="Street Address 2"
        value={billingAddress.street2}
        disabled={!billingAddress.country}
        onChange={(e) =>
          setBillingAddress({ ...billingAddress, street2: e.target.value })
        }
      />

      <button
        type="submit"
        className="bg-[#FFC94A] hover:bg-[#eebd2d] duration-300  text-white py-2 px-4 text-sm font-semibold rounded mt-2"
      >
        Save Address
      </button>
    </form>
  );
};

export default BillingAddress;
