import { useState } from "react";
import Images from "./Images";
import LeatherSelection from "./LeatherSelection";
import Sole from "./Product-sole";
import Others from "./Others";
import "./ProductListing.css";

const ProductListing = ({ menuitem }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleNextStep = () => {
    setCompletedSteps((prev) => [...new Set([...prev, currentStep])]);
    setIsTransitioning(true); // Start transition
    setTimeout(() => {
      setCurrentStep((prevStep) => (prevStep < 4 ? prevStep + 1 : prevStep));
      setIsTransitioning(false); // End transition after animation duration
    }, 300); // Match this timeout to the CSS animation duration
  };

  const goToStep = (step) => {
    if (step <= currentStep || completedSteps.includes(step - 1)) {
      setIsTransitioning(true); // Start transition
      setTimeout(() => {
        setCurrentStep(step);
        setIsTransitioning(false); // End transition after animation duration
      }, 300); // Match this timeout to the CSS animation duration
    }
  };

  const renderStepContent = () => {
    const stepContent = {
      1: <Images onNext={handleNextStep} style={menuitem} />,
      2: <LeatherSelection onNext={handleNextStep} style={menuitem} />,
      3: <Sole onNext={handleNextStep} style={menuitem} />,
      4: <Others onNext={handleNextStep} style={menuitem} />,
    };
    return stepContent[currentStep];
  };

  return (
    <div className="max-md:p-2 md:p-4 xl:p-6 2xl:p-8 bg-gray-50 h-max rounded-xl  max-w-[85rem]">
      {/* Stepper */}
      <div className="flex items-center justify-center max-md:mb-7 md:mb-10 max-md:scale-[70%] scale-[100%]">
        {["Images", "Leather", "Sole", "Others"].map((step, index) => (
          <div
            key={index}
            className="flex items-center cursor-pointer relative"
            onClick={() => goToStep(index + 1)}
          >
            {/* Step Circle */}
            <div
              className={`relative flex items-center justify-center w-8 h-8 rounded-full ${
                completedSteps.includes(index + 1)
                  ? " text-white"
                  : currentStep === index + 1
                  ? " text-white "
                  : " text-gray-400"
              }`}
            >
              {completedSteps.includes(index + 1) ? (
                <svg
                  id="fi_10629607"
                  enable-background="new 0 0 128 128"
                  height="26"
                  viewBox="0 0 128 128"
                  width="26 "
                  xmlns="http://www.w3.org/2000/svg"
                  // fill="#FFC94A"
                  fill="#000"
                >
                  <g>
                    <path d="m124 64c0 5.12-6.29 9.34-7.55 14.06-1.3 4.88 1.99 11.68-.48 15.95-2.51 4.34-10.06 4.86-13.58 8.38s-4.04 11.07-8.38 13.58c-4.27 2.47-11.07-.82-15.95.48-4.72 1.26-8.94 7.55-14.06 7.55s-9.34-6.29-14.06-7.55c-4.88-1.3-11.68 1.99-15.95-.48-4.34-2.51-4.86-10.06-8.38-13.58s-11.07-4.04-13.58-8.38c-2.47-4.27.82-11.07-.48-15.95-1.26-4.72-7.55-8.94-7.55-14.06s6.29-9.34 7.55-14.06c1.3-4.88-1.99-11.68.48-15.95 2.51-4.34 10.06-4.86 13.58-8.38s4.04-11.07 8.38-13.58c4.27-2.47 11.07.82 15.95-.48 4.72-1.26 8.94-7.55 14.06-7.55s9.34 6.29 14.06 7.55c4.88 1.3 11.68-1.99 15.95.48 4.34 2.51 4.86 10.06 8.38 13.58s11.07 4.04 13.58 8.38c2.47 4.27-.82 11.07.48 15.95 1.26 4.72 7.55 8.94 7.55 14.06z"></path>
                    <path
                      d="m81.34 46.12-22.84 22.84-11.84-11.83c-2.57-2.57-6.74-2.57-9.31 0-2.57 2.57-2.57 6.74 0 9.31l16.61 16.61c2.5 2.5 6.56 2.5 9.06 0l27.62-27.62c2.57-2.57 2.57-6.74 0-9.31-2.57-2.57-6.73-2.57-9.3 0z"
                      fill="#fffcee"
                    ></path>
                  </g>
                </svg>
              ) : (
                <span
                  className={`${
                    currentStep === index + 1 ? "text-white" : "text-gray-400"
                  }`}
                >
                  <img className="w-5 h-5 " src="/images/rec (1).svg" alt="" />
                </span>
              )}
            </div>

            {/* Connector Line */}
            {index < 3 && (
              <div
                className={`h-[1.5px] w-20 md:w-44 ${
                  completedSteps.includes(index + 1)
                    ? "bg-black"
                    : "bg-gray-300"
                }`}
              ></div>
            )}

            {/* Step Label */}
            <div className="flex absolute top-8 -left-3  items-center justify-center w-10 ml-2">
              <span
                className={`text-sm  text-center ${
                  currentStep === index + 1
                    ? "text-black font-semibold"
                    : "text-gray-400"
                }`}
              >
                {step}
              </span>
            </div>
          </div>
        ))}
      </div>

      {/* Step Content with Animation */}
      <div
        className={`transition-content ${
          isTransitioning ? "fade-out" : "fade-in"
        }`}
      >
        {renderStepContent()}
      </div>
    </div>
  );
};

export default ProductListing;
