import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
import ProductPage from "../pages/Products/ProductPage";
import ProductPage2 from "../pages/Products/ProductPage2";
import AdminDashboard from "../admin/Admin.jsx";
import ChangePassword from "../pages/ChangePassword/ChangePassword.jsx";
import ForgetPassword from "../pages/ChangePassword/ForgetPassword.jsx";
import Account from "../pages/Account/Account.jsx";
import Business from "../pages/Account/Business.jsx";
import Dashboard from "../pages/Dashboard/Dashboard.jsx";

function AllRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:user/dashboard" element={<Dashboard />} />
        <Route path="/products_page/:Category" element={<ProductPage />} />
        <Route path="/products/:Article/:Color" element={<ProductPage2 />} />
        <Route path="/adminDashboard" element={<AdminDashboard />} />
        <Route path="/forgetPassword" element={<ForgetPassword />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route path="/account" element={<Account />} />
        <Route path="/business" element={<Business />} />
      </Routes>
    </>
  );
}

export default AllRoutes;
