import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import Header from "../../components/Header/Header.jsx";
import Welcome from "../../components/Welcome/Welcome.jsx";
import { FaExternalLinkAlt } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth.jsx";

function Home() {
  const { isAuthenticated } = CheckAuth();
  const [curr, setCurr] = useState(0);

  const slides = [
    {
      id: 0,
      vid: "./videos/vd.mp4",
    },
    {
      id: 1,
      vid: "./videos/vd2.mp4",
    },
    {
      id: 2,
      vid: "./videos/vd3.mp4",
    },
    {
      id: 3,
      vid: "./videos/vd4.mp4",
    },
    {
      id: 4,
      vid: "./videos/vd5.mp4",
    },
    {
      id: 5,
      vid: "./videos/vd6.mp4",
    },
    {
      id: 6,
      vid: "./videos/vd7.mp4",
    },
  ];

  const slides2 = [
    {
      id: 0,
      vid: "./videos/raw vid/vd.mp4",
    },
    {
      id: 1,
      vid: "./videos/raw vid/vd2.mp4",
    },
    {
      id: 2,
      vid: "./videos/raw vid/vd3.mp4",
    },
    {
      id: 3,
      vid: "./videos/raw vid/vd4.mp4",
    },
    // {
    //   id: 4,
    //   vid: "./videos/raw vid/vd5.mp4",
    // },
    {
      id: 5,
      vid: "./videos/raw vid/vd6.mp4",
    },
    {
      id: 6,
      vid: "./videos/raw vid/vd7.mp4",
    },
  ];

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurr((prev) => (prev + 1) % slides.length);
    }, 5000); // Change slide every 3 seconds

    return () => clearInterval(slideInterval);
  }, [slides.length]);

  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      {/* What can we do for you */}
      <div className="w-3/4 mx-auto mt-14 p-8">
        <h3 className="text-black font-semibold text-3xl text-center">
          What can we do for you?
        </h3>
        <p className="text-[#8c8c8c]text-[#8c8c8c] font-semibold text-lg text-center pt-8">
          Our Made-to-Order program includes all the tools and assets you need
          to launch your own MTO online customization store, but also provides
          the production, managing and logistics services to fulfill each order
          from our workshop in La Mancha, Spain
        </p>
        <div className="flex justify-start mt-10">
          <div className="w-1/3">
            <div className="px-4">
              <div className="py-4">
                <svg
                  version="1.1"
                  id="fi_922699"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 469.34 469.34"
                  style={{
                    enableBackground: "new 0 0 469.34 469.34",
                    height: "50px",
                  }}
                  xmlSpace="preserve"
                >
                  <path
                    style={{ fill: "#F0EAF9" }}
                    d="M42.667,0.003h383.996c23.564,0,42.667,19.103,42.667,42.667v341.336
	c0,23.564-19.103,42.667-42.667,42.667H42.667C19.103,426.673,0,407.57,0,384.006V42.67C0,19.106,19.103,0.003,42.667,0.003z"
                  ></path>
                  <path
                    style={{ fill: "#E1D5F3" }}
                    d="M21.333,85.34h426.664c11.782,0,21.333,9.551,21.333,21.333v0.001
	c0,11.782-9.551,21.333-21.333,21.333H21.333C9.551,128.007,0,118.456,0,106.674v-0.001C0,94.891,9.551,85.34,21.333,85.34z"
                  ></path>
                  <path
                    style={{ fill: "#5628B4" }}
                    d="M42.667,0.003h384c23.564,0,42.667,19.103,42.667,42.667c0,0,0,0,0,0v64l0,0H0l0,0v-64
	C0,19.106,19.103,0.003,42.667,0.003L42.667,0.003L42.667,0.003z"
                  ></path>
                  <path
                    style={{ fill: "#6F48BF" }}
                    d="M42.667,0.003h384.004c11.782,0,21.333,9.551,21.333,21.333v0.001
	c0,11.782-9.551,21.333-21.333,21.333H42.667c-11.782,0-21.333-9.551-21.333-21.333v-0.001C21.334,9.554,30.885,0.003,42.667,0.003z
	"
                  ></path>
                  <path
                    style={{ fill: "#421198" }}
                    d="M32.001,85.34h405.336c5.891,0,10.667,4.776,10.667,10.667v0c0,5.891-4.776,10.667-10.667,10.667
	H32.001c-5.891,0-10.667-4.776-10.667-10.667v0C21.334,90.116,26.11,85.34,32.001,85.34z"
                  ></path>
                  <path
                    style={{ fill: "#D9C9EF" }}
                    d="M458.667,149.337c-5.891,0-10.667,4.775-10.667,10.666c0,0,0,0,0,0.001v224
	c0,11.782-9.551,21.333-21.333,21.333H309.334c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667h117.333
	c23.564,0,42.666-19.103,42.667-42.667v-224C469.334,154.112,464.558,149.337,458.667,149.337
	C458.667,149.337,458.667,149.337,458.667,149.337L458.667,149.337z"
                  ></path>
                  <path
                    style={{ fill: "#DFD2F2" }}
                    d="M10.667,192L10.667,192c5.891,0,10.667,4.776,10.667,10.667v170.666
	c0,5.891-4.776,10.667-10.667,10.667l0,0C4.776,384,0,379.224,0,373.333V202.667C0,196.776,4.776,192,10.667,192z"
                  ></path>
                  <circle
                    style={{ fill: "#F7B236" }}
                    cx="53.337"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <circle
                    style={{ fill: "#B39DDD" }}
                    cx="95.997"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <circle
                    style={{ fill: "#D80E70" }}
                    cx="138.667"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <g>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M405.334,298.67H192c-11.782,0-21.333-9.551-21.333-21.333V170.673
		c0-11.782,9.551-21.333,21.333-21.333h213.334c11.782,0,21.333,9.551,21.333,21.333v106.664
		C426.667,289.119,417.116,298.67,405.334,298.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M106.664,234.67H64c-11.782,0-21.333-9.551-21.333-21.333v-42.664
		c0-11.782,9.551-21.333,21.333-21.333h42.664c11.782,0,21.333,9.551,21.333,21.333v42.664
		C127.997,225.119,118.446,234.67,106.664,234.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M106.664,384H64c-11.782,0-21.333-9.551-21.333-21.333v-42.664c0-11.782,9.551-21.333,21.333-21.333
		h42.664c11.782,0,21.333,9.551,21.333,21.333v42.664C127.997,374.449,118.446,384,106.664,384z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M181.334,320h63.996c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-63.996c-5.891,0-10.667-4.776-10.667-10.667l0,0C170.667,324.776,175.443,320,181.334,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M394.664,320h21.333c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-21.333c-5.891,0-10.667-4.776-10.667-10.667l0,0C383.997,324.776,388.773,320,394.664,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M288.004,320H352c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-63.996c-5.891,0-10.667-4.776-10.667-10.667l0,0C277.337,324.776,282.113,320,288.004,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M181.334,362.67h85.336c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-85.336c-5.891,0-10.667-4.776-10.667-10.667l0,0C170.667,367.446,175.443,362.67,181.334,362.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M309.334,362.67h21.333c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-21.333c-5.891,0-10.667-4.776-10.667-10.667l0,0C298.667,367.446,303.443,362.67,309.334,362.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M117.33,277.34H53.334c-5.891,0-10.667-4.776-10.667-10.667l0,0c0-5.891,4.776-10.667,10.667-10.667
		h63.996c5.891,0,10.667,4.776,10.667,10.667l0,0C127.997,272.564,123.221,277.34,117.33,277.34z"
                    ></path>
                  </g>
                  <path
                    style={{ fill: "#F7B236" }}
                    d="M234.667,320.003h-21.331c-58.91,0-106.667,47.756-106.667,106.667c0,0.001,0,0.001,0,0.002v0.565
	c0.076,11.653-10.209,20.767-21.862,20.767h-10.14c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667l0,0
	h74.667C208.244,469.337,256,421.58,256,362.67v-42.667H234.667z"
                  ></path>
                  <path
                    style={{ fill: "#F58B04" }}
                    d="M256,362.67v-42.667h-21.333c0,58.91-47.756,106.667-106.667,106.667H106.67v0.566
	c0.076,11.652-10.21,20.767-21.862,20.767H74.667c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667h74.667
	C208.244,469.336,256,421.58,256,362.67L256,362.67z"
                  ></path>
                  <path
                    style={{ fill: "#D80E70" }}
                    d="M462.184,113.82c-6.537-6.537-16.207-8.82-24.977-5.896h0
	c-75.189,26.567-137.135,81.272-172.798,152.597L256,277.337v42.667h42.667l16.816-8.408
	c71.325-35.663,126.03-97.61,152.597-172.798v0C471.004,130.026,468.721,120.357,462.184,113.82L462.184,113.82z"
                  ></path>
                  <ellipse
                    transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 431.8294 605.5635)"
                    style={{ fill: "#E976AF" }}
                    cx="341.331"
                    cy="213.347"
                    rx="21.333"
                    ry="56.757"
                  ></ellipse>
                  <path
                    style={{ fill: "#A60068" }}
                    d="M437.206,107.923c-3.693,1.305-7.198,2.986-10.826,4.424C423.602,207.998,351.052,287.119,256,298.16
	v21.844h42.667l16.816-8.409c71.325-35.663,126.03-97.61,152.598-172.798c4.262-12.789-2.651-26.612-15.44-30.874
	C447.631,106.253,442.216,106.253,437.206,107.923L437.206,107.923z"
                  ></path>
                  <path
                    style={{ fill: "#5628B4" }}
                    d="M234.667,320.003L256,341.337l42.667-21.334L256,277.337L234.667,320.003z"
                  ></path>
                  <path
                    style={{ fill: "#420F9B" }}
                    d="M246.199,331.535l9.801,9.801l42.667-21.333l-20.468-20.468c-8.837-8.837-23.163-8.837-32,0
	c-1.72,1.72-3.151,3.705-4.239,5.881v0C237.605,314.128,239.312,324.649,246.199,331.535L246.199,331.535z"
                  ></path>
                  <ellipse
                    transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 10.0251 751.4755)"
                    style={{ fill: "#FBDA9E" }}
                    cx="160.648"
                    cy="373.661"
                    rx="16.617"
                    ry="42.44"
                  ></ellipse>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </div>
              <p className="text-[#8c8c8c] font-semibold text-md pt-6">
                Our own
                <span className="text-[#006cff]"> 3D designing tool</span> helps
                you fully customize your footwear.
              </p>
            </div>
          </div>
          <div className="w-1/3">
            <div className="px-4">
              <div className="py-4">
                <svg
                  version="1.1"
                  id="fi_922699"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 469.34 469.34"
                  style={{
                    enableBackground: "new 0 0 469.34 469.34",
                    height: "50px",
                  }}
                  xmlSpace="preserve"
                >
                  <path
                    style={{ fill: "#F0EAF9" }}
                    d="M42.667,0.003h383.996c23.564,0,42.667,19.103,42.667,42.667v341.336
	c0,23.564-19.103,42.667-42.667,42.667H42.667C19.103,426.673,0,407.57,0,384.006V42.67C0,19.106,19.103,0.003,42.667,0.003z"
                  ></path>
                  <path
                    style={{ fill: "#E1D5F3" }}
                    d="M21.333,85.34h426.664c11.782,0,21.333,9.551,21.333,21.333v0.001
	c0,11.782-9.551,21.333-21.333,21.333H21.333C9.551,128.007,0,118.456,0,106.674v-0.001C0,94.891,9.551,85.34,21.333,85.34z"
                  ></path>
                  <path
                    style={{ fill: "#5628B4" }}
                    d="M42.667,0.003h384c23.564,0,42.667,19.103,42.667,42.667c0,0,0,0,0,0v64l0,0H0l0,0v-64
	C0,19.106,19.103,0.003,42.667,0.003L42.667,0.003L42.667,0.003z"
                  ></path>
                  <path
                    style={{ fill: "#6F48BF" }}
                    d="M42.667,0.003h384.004c11.782,0,21.333,9.551,21.333,21.333v0.001
	c0,11.782-9.551,21.333-21.333,21.333H42.667c-11.782,0-21.333-9.551-21.333-21.333v-0.001C21.334,9.554,30.885,0.003,42.667,0.003z
	"
                  ></path>
                  <path
                    style={{ fill: "#421198" }}
                    d="M32.001,85.34h405.336c5.891,0,10.667,4.776,10.667,10.667v0c0,5.891-4.776,10.667-10.667,10.667
	H32.001c-5.891,0-10.667-4.776-10.667-10.667v0C21.334,90.116,26.11,85.34,32.001,85.34z"
                  ></path>
                  <path
                    style={{ fill: "#D9C9EF" }}
                    d="M458.667,149.337c-5.891,0-10.667,4.775-10.667,10.666c0,0,0,0,0,0.001v224
	c0,11.782-9.551,21.333-21.333,21.333H309.334c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667h117.333
	c23.564,0,42.666-19.103,42.667-42.667v-224C469.334,154.112,464.558,149.337,458.667,149.337
	C458.667,149.337,458.667,149.337,458.667,149.337L458.667,149.337z"
                  ></path>
                  <path
                    style={{ fill: "#DFD2F2" }}
                    d="M10.667,192L10.667,192c5.891,0,10.667,4.776,10.667,10.667v170.666
	c0,5.891-4.776,10.667-10.667,10.667l0,0C4.776,384,0,379.224,0,373.333V202.667C0,196.776,4.776,192,10.667,192z"
                  ></path>
                  <circle
                    style={{ fill: "#F7B236" }}
                    cx="53.337"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <circle
                    style={{ fill: "#B39DDD" }}
                    cx="95.997"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <circle
                    style={{ fill: "#D80E70" }}
                    cx="138.667"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <g>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M405.334,298.67H192c-11.782,0-21.333-9.551-21.333-21.333V170.673
		c0-11.782,9.551-21.333,21.333-21.333h213.334c11.782,0,21.333,9.551,21.333,21.333v106.664
		C426.667,289.119,417.116,298.67,405.334,298.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M106.664,234.67H64c-11.782,0-21.333-9.551-21.333-21.333v-42.664
		c0-11.782,9.551-21.333,21.333-21.333h42.664c11.782,0,21.333,9.551,21.333,21.333v42.664
		C127.997,225.119,118.446,234.67,106.664,234.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M106.664,384H64c-11.782,0-21.333-9.551-21.333-21.333v-42.664c0-11.782,9.551-21.333,21.333-21.333
		h42.664c11.782,0,21.333,9.551,21.333,21.333v42.664C127.997,374.449,118.446,384,106.664,384z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M181.334,320h63.996c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-63.996c-5.891,0-10.667-4.776-10.667-10.667l0,0C170.667,324.776,175.443,320,181.334,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M394.664,320h21.333c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-21.333c-5.891,0-10.667-4.776-10.667-10.667l0,0C383.997,324.776,388.773,320,394.664,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M288.004,320H352c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-63.996c-5.891,0-10.667-4.776-10.667-10.667l0,0C277.337,324.776,282.113,320,288.004,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M181.334,362.67h85.336c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-85.336c-5.891,0-10.667-4.776-10.667-10.667l0,0C170.667,367.446,175.443,362.67,181.334,362.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M309.334,362.67h21.333c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-21.333c-5.891,0-10.667-4.776-10.667-10.667l0,0C298.667,367.446,303.443,362.67,309.334,362.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M117.33,277.34H53.334c-5.891,0-10.667-4.776-10.667-10.667l0,0c0-5.891,4.776-10.667,10.667-10.667
		h63.996c5.891,0,10.667,4.776,10.667,10.667l0,0C127.997,272.564,123.221,277.34,117.33,277.34z"
                    ></path>
                  </g>
                  <path
                    style={{ fill: "#F7B236" }}
                    d="M234.667,320.003h-21.331c-58.91,0-106.667,47.756-106.667,106.667c0,0.001,0,0.001,0,0.002v0.565
	c0.076,11.653-10.209,20.767-21.862,20.767h-10.14c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667l0,0
	h74.667C208.244,469.337,256,421.58,256,362.67v-42.667H234.667z"
                  ></path>
                  <path
                    style={{ fill: "#F58B04" }}
                    d="M256,362.67v-42.667h-21.333c0,58.91-47.756,106.667-106.667,106.667H106.67v0.566
	c0.076,11.652-10.21,20.767-21.862,20.767H74.667c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667h74.667
	C208.244,469.336,256,421.58,256,362.67L256,362.67z"
                  ></path>
                  <path
                    style={{ fill: "#D80E70" }}
                    d="M462.184,113.82c-6.537-6.537-16.207-8.82-24.977-5.896h0
	c-75.189,26.567-137.135,81.272-172.798,152.597L256,277.337v42.667h42.667l16.816-8.408
	c71.325-35.663,126.03-97.61,152.597-172.798v0C471.004,130.026,468.721,120.357,462.184,113.82L462.184,113.82z"
                  ></path>
                  <ellipse
                    transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 431.8294 605.5635)"
                    style={{ fill: "#E976AF" }}
                    cx="341.331"
                    cy="213.347"
                    rx="21.333"
                    ry="56.757"
                  ></ellipse>
                  <path
                    style={{ fill: "#A60068" }}
                    d="M437.206,107.923c-3.693,1.305-7.198,2.986-10.826,4.424C423.602,207.998,351.052,287.119,256,298.16
	v21.844h42.667l16.816-8.409c71.325-35.663,126.03-97.61,152.598-172.798c4.262-12.789-2.651-26.612-15.44-30.874
	C447.631,106.253,442.216,106.253,437.206,107.923L437.206,107.923z"
                  ></path>
                  <path
                    style={{ fill: "#5628B4" }}
                    d="M234.667,320.003L256,341.337l42.667-21.334L256,277.337L234.667,320.003z"
                  ></path>
                  <path
                    style={{ fill: "#420F9B" }}
                    d="M246.199,331.535l9.801,9.801l42.667-21.333l-20.468-20.468c-8.837-8.837-23.163-8.837-32,0
	c-1.72,1.72-3.151,3.705-4.239,5.881v0C237.605,314.128,239.312,324.649,246.199,331.535L246.199,331.535z"
                  ></path>
                  <ellipse
                    transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 10.0251 751.4755)"
                    style={{ fill: "#FBDA9E" }}
                    cx="160.648"
                    cy="373.661"
                    rx="16.617"
                    ry="42.44"
                  ></ellipse>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </div>
              <p className="text-[#8c8c8c] font-semibold text-md pt-6">
                we provide
                <span className="text-[#006cff]"> discounts</span> for bulk
                orders, so you can save more when you buy in larger quantities.
              </p>
            </div>
          </div>
          <div className="w-1/3">
            <div className="px-4">
              <div className="py-4">
                <svg
                  version="1.1"
                  id="fi_922699"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 469.34 469.34"
                  style={{
                    enableBackground: "new 0 0 469.34 469.34",
                    height: "50px",
                  }}
                  xmlSpace="preserve"
                >
                  <path
                    style={{ fill: "#F0EAF9" }}
                    d="M42.667,0.003h383.996c23.564,0,42.667,19.103,42.667,42.667v341.336
	c0,23.564-19.103,42.667-42.667,42.667H42.667C19.103,426.673,0,407.57,0,384.006V42.67C0,19.106,19.103,0.003,42.667,0.003z"
                  ></path>
                  <path
                    style={{ fill: "#E1D5F3" }}
                    d="M21.333,85.34h426.664c11.782,0,21.333,9.551,21.333,21.333v0.001
	c0,11.782-9.551,21.333-21.333,21.333H21.333C9.551,128.007,0,118.456,0,106.674v-0.001C0,94.891,9.551,85.34,21.333,85.34z"
                  ></path>
                  <path
                    style={{ fill: "#5628B4" }}
                    d="M42.667,0.003h384c23.564,0,42.667,19.103,42.667,42.667c0,0,0,0,0,0v64l0,0H0l0,0v-64
	C0,19.106,19.103,0.003,42.667,0.003L42.667,0.003L42.667,0.003z"
                  ></path>
                  <path
                    style={{ fill: "#6F48BF" }}
                    d="M42.667,0.003h384.004c11.782,0,21.333,9.551,21.333,21.333v0.001
	c0,11.782-9.551,21.333-21.333,21.333H42.667c-11.782,0-21.333-9.551-21.333-21.333v-0.001C21.334,9.554,30.885,0.003,42.667,0.003z
	"
                  ></path>
                  <path
                    style={{ fill: "#421198" }}
                    d="M32.001,85.34h405.336c5.891,0,10.667,4.776,10.667,10.667v0c0,5.891-4.776,10.667-10.667,10.667
	H32.001c-5.891,0-10.667-4.776-10.667-10.667v0C21.334,90.116,26.11,85.34,32.001,85.34z"
                  ></path>
                  <path
                    style={{ fill: "#D9C9EF" }}
                    d="M458.667,149.337c-5.891,0-10.667,4.775-10.667,10.666c0,0,0,0,0,0.001v224
	c0,11.782-9.551,21.333-21.333,21.333H309.334c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667h117.333
	c23.564,0,42.666-19.103,42.667-42.667v-224C469.334,154.112,464.558,149.337,458.667,149.337
	C458.667,149.337,458.667,149.337,458.667,149.337L458.667,149.337z"
                  ></path>
                  <path
                    style={{ fill: "#DFD2F2" }}
                    d="M10.667,192L10.667,192c5.891,0,10.667,4.776,10.667,10.667v170.666
	c0,5.891-4.776,10.667-10.667,10.667l0,0C4.776,384,0,379.224,0,373.333V202.667C0,196.776,4.776,192,10.667,192z"
                  ></path>
                  <circle
                    style={{ fill: "#F7B236" }}
                    cx="53.337"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <circle
                    style={{ fill: "#B39DDD" }}
                    cx="95.997"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <circle
                    style={{ fill: "#D80E70" }}
                    cx="138.667"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <g>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M405.334,298.67H192c-11.782,0-21.333-9.551-21.333-21.333V170.673
		c0-11.782,9.551-21.333,21.333-21.333h213.334c11.782,0,21.333,9.551,21.333,21.333v106.664
		C426.667,289.119,417.116,298.67,405.334,298.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M106.664,234.67H64c-11.782,0-21.333-9.551-21.333-21.333v-42.664
		c0-11.782,9.551-21.333,21.333-21.333h42.664c11.782,0,21.333,9.551,21.333,21.333v42.664
		C127.997,225.119,118.446,234.67,106.664,234.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M106.664,384H64c-11.782,0-21.333-9.551-21.333-21.333v-42.664c0-11.782,9.551-21.333,21.333-21.333
		h42.664c11.782,0,21.333,9.551,21.333,21.333v42.664C127.997,374.449,118.446,384,106.664,384z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M181.334,320h63.996c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-63.996c-5.891,0-10.667-4.776-10.667-10.667l0,0C170.667,324.776,175.443,320,181.334,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M394.664,320h21.333c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-21.333c-5.891,0-10.667-4.776-10.667-10.667l0,0C383.997,324.776,388.773,320,394.664,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M288.004,320H352c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-63.996c-5.891,0-10.667-4.776-10.667-10.667l0,0C277.337,324.776,282.113,320,288.004,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M181.334,362.67h85.336c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-85.336c-5.891,0-10.667-4.776-10.667-10.667l0,0C170.667,367.446,175.443,362.67,181.334,362.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M309.334,362.67h21.333c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-21.333c-5.891,0-10.667-4.776-10.667-10.667l0,0C298.667,367.446,303.443,362.67,309.334,362.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M117.33,277.34H53.334c-5.891,0-10.667-4.776-10.667-10.667l0,0c0-5.891,4.776-10.667,10.667-10.667
		h63.996c5.891,0,10.667,4.776,10.667,10.667l0,0C127.997,272.564,123.221,277.34,117.33,277.34z"
                    ></path>
                  </g>
                  <path
                    style={{ fill: "#F7B236" }}
                    d="M234.667,320.003h-21.331c-58.91,0-106.667,47.756-106.667,106.667c0,0.001,0,0.001,0,0.002v0.565
	c0.076,11.653-10.209,20.767-21.862,20.767h-10.14c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667l0,0
	h74.667C208.244,469.337,256,421.58,256,362.67v-42.667H234.667z"
                  ></path>
                  <path
                    style={{ fill: "#F58B04" }}
                    d="M256,362.67v-42.667h-21.333c0,58.91-47.756,106.667-106.667,106.667H106.67v0.566
	c0.076,11.652-10.21,20.767-21.862,20.767H74.667c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667h74.667
	C208.244,469.336,256,421.58,256,362.67L256,362.67z"
                  ></path>
                  <path
                    style={{ fill: "#D80E70" }}
                    d="M462.184,113.82c-6.537-6.537-16.207-8.82-24.977-5.896h0
	c-75.189,26.567-137.135,81.272-172.798,152.597L256,277.337v42.667h42.667l16.816-8.408
	c71.325-35.663,126.03-97.61,152.597-172.798v0C471.004,130.026,468.721,120.357,462.184,113.82L462.184,113.82z"
                  ></path>
                  <ellipse
                    transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 431.8294 605.5635)"
                    style={{ fill: "#E976AF" }}
                    cx="341.331"
                    cy="213.347"
                    rx="21.333"
                    ry="56.757"
                  ></ellipse>
                  <path
                    style={{ fill: "#A60068" }}
                    d="M437.206,107.923c-3.693,1.305-7.198,2.986-10.826,4.424C423.602,207.998,351.052,287.119,256,298.16
	v21.844h42.667l16.816-8.409c71.325-35.663,126.03-97.61,152.598-172.798c4.262-12.789-2.651-26.612-15.44-30.874
	C447.631,106.253,442.216,106.253,437.206,107.923L437.206,107.923z"
                  ></path>
                  <path
                    style={{ fill: "#5628B4" }}
                    d="M234.667,320.003L256,341.337l42.667-21.334L256,277.337L234.667,320.003z"
                  ></path>
                  <path
                    style={{ fill: "#420F9B" }}
                    d="M246.199,331.535l9.801,9.801l42.667-21.333l-20.468-20.468c-8.837-8.837-23.163-8.837-32,0
	c-1.72,1.72-3.151,3.705-4.239,5.881v0C237.605,314.128,239.312,324.649,246.199,331.535L246.199,331.535z"
                  ></path>
                  <ellipse
                    transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 10.0251 751.4755)"
                    style={{ fill: "#FBDA9E" }}
                    cx="160.648"
                    cy="373.661"
                    rx="16.617"
                    ry="42.44"
                  ></ellipse>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </div>
              <p className="text-[#8c8c8c] font-semibold text-md pt-6">
                You can{" "}
                <span className="text-[#006cff]"> track your order </span>at{" "}
                <span className="text-[#006cff]">
                  {" "}
                  each stage of production{" "}
                </span>
                until you receive it at your doorstep
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-start mt-10">
          <div className="w-1/3">
            <div className="px-4">
              <div className="py-4">
                <svg
                  version="1.1"
                  id="fi_922699"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 469.34 469.34"
                  style={{
                    enableBackground: "new 0 0 469.34 469.34",
                    height: "50px",
                  }}
                  xmlSpace="preserve"
                >
                  <path
                    style={{ fill: "#F0EAF9" }}
                    d="M42.667,0.003h383.996c23.564,0,42.667,19.103,42.667,42.667v341.336
	c0,23.564-19.103,42.667-42.667,42.667H42.667C19.103,426.673,0,407.57,0,384.006V42.67C0,19.106,19.103,0.003,42.667,0.003z"
                  ></path>
                  <path
                    style={{ fill: "#E1D5F3" }}
                    d="M21.333,85.34h426.664c11.782,0,21.333,9.551,21.333,21.333v0.001
	c0,11.782-9.551,21.333-21.333,21.333H21.333C9.551,128.007,0,118.456,0,106.674v-0.001C0,94.891,9.551,85.34,21.333,85.34z"
                  ></path>
                  <path
                    style={{ fill: "#5628B4" }}
                    d="M42.667,0.003h384c23.564,0,42.667,19.103,42.667,42.667c0,0,0,0,0,0v64l0,0H0l0,0v-64
	C0,19.106,19.103,0.003,42.667,0.003L42.667,0.003L42.667,0.003z"
                  ></path>
                  <path
                    style={{ fill: "#6F48BF" }}
                    d="M42.667,0.003h384.004c11.782,0,21.333,9.551,21.333,21.333v0.001
	c0,11.782-9.551,21.333-21.333,21.333H42.667c-11.782,0-21.333-9.551-21.333-21.333v-0.001C21.334,9.554,30.885,0.003,42.667,0.003z
	"
                  ></path>
                  <path
                    style={{ fill: "#421198" }}
                    d="M32.001,85.34h405.336c5.891,0,10.667,4.776,10.667,10.667v0c0,5.891-4.776,10.667-10.667,10.667
	H32.001c-5.891,0-10.667-4.776-10.667-10.667v0C21.334,90.116,26.11,85.34,32.001,85.34z"
                  ></path>
                  <path
                    style={{ fill: "#D9C9EF" }}
                    d="M458.667,149.337c-5.891,0-10.667,4.775-10.667,10.666c0,0,0,0,0,0.001v224
	c0,11.782-9.551,21.333-21.333,21.333H309.334c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667h117.333
	c23.564,0,42.666-19.103,42.667-42.667v-224C469.334,154.112,464.558,149.337,458.667,149.337
	C458.667,149.337,458.667,149.337,458.667,149.337L458.667,149.337z"
                  ></path>
                  <path
                    style={{ fill: "#DFD2F2" }}
                    d="M10.667,192L10.667,192c5.891,0,10.667,4.776,10.667,10.667v170.666
	c0,5.891-4.776,10.667-10.667,10.667l0,0C4.776,384,0,379.224,0,373.333V202.667C0,196.776,4.776,192,10.667,192z"
                  ></path>
                  <circle
                    style={{ fill: "#F7B236" }}
                    cx="53.337"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <circle
                    style={{ fill: "#B39DDD" }}
                    cx="95.997"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <circle
                    style={{ fill: "#D80E70" }}
                    cx="138.667"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <g>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M405.334,298.67H192c-11.782,0-21.333-9.551-21.333-21.333V170.673
		c0-11.782,9.551-21.333,21.333-21.333h213.334c11.782,0,21.333,9.551,21.333,21.333v106.664
		C426.667,289.119,417.116,298.67,405.334,298.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M106.664,234.67H64c-11.782,0-21.333-9.551-21.333-21.333v-42.664
		c0-11.782,9.551-21.333,21.333-21.333h42.664c11.782,0,21.333,9.551,21.333,21.333v42.664
		C127.997,225.119,118.446,234.67,106.664,234.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M106.664,384H64c-11.782,0-21.333-9.551-21.333-21.333v-42.664c0-11.782,9.551-21.333,21.333-21.333
		h42.664c11.782,0,21.333,9.551,21.333,21.333v42.664C127.997,374.449,118.446,384,106.664,384z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M181.334,320h63.996c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-63.996c-5.891,0-10.667-4.776-10.667-10.667l0,0C170.667,324.776,175.443,320,181.334,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M394.664,320h21.333c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-21.333c-5.891,0-10.667-4.776-10.667-10.667l0,0C383.997,324.776,388.773,320,394.664,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M288.004,320H352c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-63.996c-5.891,0-10.667-4.776-10.667-10.667l0,0C277.337,324.776,282.113,320,288.004,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M181.334,362.67h85.336c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-85.336c-5.891,0-10.667-4.776-10.667-10.667l0,0C170.667,367.446,175.443,362.67,181.334,362.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M309.334,362.67h21.333c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-21.333c-5.891,0-10.667-4.776-10.667-10.667l0,0C298.667,367.446,303.443,362.67,309.334,362.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M117.33,277.34H53.334c-5.891,0-10.667-4.776-10.667-10.667l0,0c0-5.891,4.776-10.667,10.667-10.667
		h63.996c5.891,0,10.667,4.776,10.667,10.667l0,0C127.997,272.564,123.221,277.34,117.33,277.34z"
                    ></path>
                  </g>
                  <path
                    style={{ fill: "#F7B236" }}
                    d="M234.667,320.003h-21.331c-58.91,0-106.667,47.756-106.667,106.667c0,0.001,0,0.001,0,0.002v0.565
	c0.076,11.653-10.209,20.767-21.862,20.767h-10.14c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667l0,0
	h74.667C208.244,469.337,256,421.58,256,362.67v-42.667H234.667z"
                  ></path>
                  <path
                    style={{ fill: "#F58B04" }}
                    d="M256,362.67v-42.667h-21.333c0,58.91-47.756,106.667-106.667,106.667H106.67v0.566
	c0.076,11.652-10.21,20.767-21.862,20.767H74.667c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667h74.667
	C208.244,469.336,256,421.58,256,362.67L256,362.67z"
                  ></path>
                  <path
                    style={{ fill: "#D80E70" }}
                    d="M462.184,113.82c-6.537-6.537-16.207-8.82-24.977-5.896h0
	c-75.189,26.567-137.135,81.272-172.798,152.597L256,277.337v42.667h42.667l16.816-8.408
	c71.325-35.663,126.03-97.61,152.597-172.798v0C471.004,130.026,468.721,120.357,462.184,113.82L462.184,113.82z"
                  ></path>
                  <ellipse
                    transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 431.8294 605.5635)"
                    style={{ fill: "#E976AF" }}
                    cx="341.331"
                    cy="213.347"
                    rx="21.333"
                    ry="56.757"
                  ></ellipse>
                  <path
                    style={{ fill: "#A60068" }}
                    d="M437.206,107.923c-3.693,1.305-7.198,2.986-10.826,4.424C423.602,207.998,351.052,287.119,256,298.16
	v21.844h42.667l16.816-8.409c71.325-35.663,126.03-97.61,152.598-172.798c4.262-12.789-2.651-26.612-15.44-30.874
	C447.631,106.253,442.216,106.253,437.206,107.923L437.206,107.923z"
                  ></path>
                  <path
                    style={{ fill: "#5628B4" }}
                    d="M234.667,320.003L256,341.337l42.667-21.334L256,277.337L234.667,320.003z"
                  ></path>
                  <path
                    style={{ fill: "#420F9B" }}
                    d="M246.199,331.535l9.801,9.801l42.667-21.333l-20.468-20.468c-8.837-8.837-23.163-8.837-32,0
	c-1.72,1.72-3.151,3.705-4.239,5.881v0C237.605,314.128,239.312,324.649,246.199,331.535L246.199,331.535z"
                  ></path>
                  <ellipse
                    transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 10.0251 751.4755)"
                    style={{ fill: "#FBDA9E" }}
                    cx="160.648"
                    cy="373.661"
                    rx="16.617"
                    ry="42.44"
                  ></ellipse>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </div>
              <p className="text-[#8c8c8c] font-semibold text-md pt-6">
                We provide{" "}
                <span className="text-[#006cff]">worldwide delivery</span> with
                an estimated delivery date when you confirm your order.
              </p>
            </div>
          </div>
          <div className="w-1/3">
            <div className="px-4">
              <div className="py-4">
                <svg
                  version="1.1"
                  id="fi_922699"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 469.34 469.34"
                  style={{
                    enableBackground: "new 0 0 469.34 469.34",
                    height: "50px",
                  }}
                  xmlSpace="preserve"
                >
                  <path
                    style={{ fill: "#F0EAF9" }}
                    d="M42.667,0.003h383.996c23.564,0,42.667,19.103,42.667,42.667v341.336
	c0,23.564-19.103,42.667-42.667,42.667H42.667C19.103,426.673,0,407.57,0,384.006V42.67C0,19.106,19.103,0.003,42.667,0.003z"
                  ></path>
                  <path
                    style={{ fill: "#E1D5F3" }}
                    d="M21.333,85.34h426.664c11.782,0,21.333,9.551,21.333,21.333v0.001
	c0,11.782-9.551,21.333-21.333,21.333H21.333C9.551,128.007,0,118.456,0,106.674v-0.001C0,94.891,9.551,85.34,21.333,85.34z"
                  ></path>
                  <path
                    style={{ fill: "#5628B4" }}
                    d="M42.667,0.003h384c23.564,0,42.667,19.103,42.667,42.667c0,0,0,0,0,0v64l0,0H0l0,0v-64
	C0,19.106,19.103,0.003,42.667,0.003L42.667,0.003L42.667,0.003z"
                  ></path>
                  <path
                    style={{ fill: "#6F48BF" }}
                    d="M42.667,0.003h384.004c11.782,0,21.333,9.551,21.333,21.333v0.001
	c0,11.782-9.551,21.333-21.333,21.333H42.667c-11.782,0-21.333-9.551-21.333-21.333v-0.001C21.334,9.554,30.885,0.003,42.667,0.003z
	"
                  ></path>
                  <path
                    style={{ fill: "#421198" }}
                    d="M32.001,85.34h405.336c5.891,0,10.667,4.776,10.667,10.667v0c0,5.891-4.776,10.667-10.667,10.667
	H32.001c-5.891,0-10.667-4.776-10.667-10.667v0C21.334,90.116,26.11,85.34,32.001,85.34z"
                  ></path>
                  <path
                    style={{ fill: "#D9C9EF" }}
                    d="M458.667,149.337c-5.891,0-10.667,4.775-10.667,10.666c0,0,0,0,0,0.001v224
	c0,11.782-9.551,21.333-21.333,21.333H309.334c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667h117.333
	c23.564,0,42.666-19.103,42.667-42.667v-224C469.334,154.112,464.558,149.337,458.667,149.337
	C458.667,149.337,458.667,149.337,458.667,149.337L458.667,149.337z"
                  ></path>
                  <path
                    style={{ fill: "#DFD2F2" }}
                    d="M10.667,192L10.667,192c5.891,0,10.667,4.776,10.667,10.667v170.666
	c0,5.891-4.776,10.667-10.667,10.667l0,0C4.776,384,0,379.224,0,373.333V202.667C0,196.776,4.776,192,10.667,192z"
                  ></path>
                  <circle
                    style={{ fill: "#F7B236" }}
                    cx="53.337"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <circle
                    style={{ fill: "#B39DDD" }}
                    cx="95.997"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <circle
                    style={{ fill: "#D80E70" }}
                    cx="138.667"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <g>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M405.334,298.67H192c-11.782,0-21.333-9.551-21.333-21.333V170.673
		c0-11.782,9.551-21.333,21.333-21.333h213.334c11.782,0,21.333,9.551,21.333,21.333v106.664
		C426.667,289.119,417.116,298.67,405.334,298.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M106.664,234.67H64c-11.782,0-21.333-9.551-21.333-21.333v-42.664
		c0-11.782,9.551-21.333,21.333-21.333h42.664c11.782,0,21.333,9.551,21.333,21.333v42.664
		C127.997,225.119,118.446,234.67,106.664,234.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M106.664,384H64c-11.782,0-21.333-9.551-21.333-21.333v-42.664c0-11.782,9.551-21.333,21.333-21.333
		h42.664c11.782,0,21.333,9.551,21.333,21.333v42.664C127.997,374.449,118.446,384,106.664,384z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M181.334,320h63.996c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-63.996c-5.891,0-10.667-4.776-10.667-10.667l0,0C170.667,324.776,175.443,320,181.334,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M394.664,320h21.333c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-21.333c-5.891,0-10.667-4.776-10.667-10.667l0,0C383.997,324.776,388.773,320,394.664,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M288.004,320H352c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-63.996c-5.891,0-10.667-4.776-10.667-10.667l0,0C277.337,324.776,282.113,320,288.004,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M181.334,362.67h85.336c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-85.336c-5.891,0-10.667-4.776-10.667-10.667l0,0C170.667,367.446,175.443,362.67,181.334,362.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M309.334,362.67h21.333c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-21.333c-5.891,0-10.667-4.776-10.667-10.667l0,0C298.667,367.446,303.443,362.67,309.334,362.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M117.33,277.34H53.334c-5.891,0-10.667-4.776-10.667-10.667l0,0c0-5.891,4.776-10.667,10.667-10.667
		h63.996c5.891,0,10.667,4.776,10.667,10.667l0,0C127.997,272.564,123.221,277.34,117.33,277.34z"
                    ></path>
                  </g>
                  <path
                    style={{ fill: "#F7B236" }}
                    d="M234.667,320.003h-21.331c-58.91,0-106.667,47.756-106.667,106.667c0,0.001,0,0.001,0,0.002v0.565
	c0.076,11.653-10.209,20.767-21.862,20.767h-10.14c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667l0,0
	h74.667C208.244,469.337,256,421.58,256,362.67v-42.667H234.667z"
                  ></path>
                  <path
                    style={{ fill: "#F58B04" }}
                    d="M256,362.67v-42.667h-21.333c0,58.91-47.756,106.667-106.667,106.667H106.67v0.566
	c0.076,11.652-10.21,20.767-21.862,20.767H74.667c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667h74.667
	C208.244,469.336,256,421.58,256,362.67L256,362.67z"
                  ></path>
                  <path
                    style={{ fill: "#D80E70" }}
                    d="M462.184,113.82c-6.537-6.537-16.207-8.82-24.977-5.896h0
	c-75.189,26.567-137.135,81.272-172.798,152.597L256,277.337v42.667h42.667l16.816-8.408
	c71.325-35.663,126.03-97.61,152.597-172.798v0C471.004,130.026,468.721,120.357,462.184,113.82L462.184,113.82z"
                  ></path>
                  <ellipse
                    transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 431.8294 605.5635)"
                    style={{ fill: "#E976AF" }}
                    cx="341.331"
                    cy="213.347"
                    rx="21.333"
                    ry="56.757"
                  ></ellipse>
                  <path
                    style={{ fill: "#A60068" }}
                    d="M437.206,107.923c-3.693,1.305-7.198,2.986-10.826,4.424C423.602,207.998,351.052,287.119,256,298.16
	v21.844h42.667l16.816-8.409c71.325-35.663,126.03-97.61,152.598-172.798c4.262-12.789-2.651-26.612-15.44-30.874
	C447.631,106.253,442.216,106.253,437.206,107.923L437.206,107.923z"
                  ></path>
                  <path
                    style={{ fill: "#5628B4" }}
                    d="M234.667,320.003L256,341.337l42.667-21.334L256,277.337L234.667,320.003z"
                  ></path>
                  <path
                    style={{ fill: "#420F9B" }}
                    d="M246.199,331.535l9.801,9.801l42.667-21.333l-20.468-20.468c-8.837-8.837-23.163-8.837-32,0
	c-1.72,1.72-3.151,3.705-4.239,5.881v0C237.605,314.128,239.312,324.649,246.199,331.535L246.199,331.535z"
                  ></path>
                  <ellipse
                    transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 10.0251 751.4755)"
                    style={{ fill: "#FBDA9E" }}
                    cx="160.648"
                    cy="373.661"
                    rx="16.617"
                    ry="42.44"
                  ></ellipse>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </div>
              <p className="text-[#8c8c8c] font-semibold text-md pt-6">
                We provide a
                <span className="text-[#006cff]">branded footwear </span> kit
                with all the necessary items to keep your footwear flawless.
              </p>
            </div>
          </div>
          <div className="w-1/3">
            <div className="px-4">
              <div className="py-4">
                <svg
                  version="1.1"
                  id="fi_922699"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 469.34 469.34"
                  style={{
                    enableBackground: "new 0 0 469.34 469.34",
                    height: "50px",
                  }}
                  xmlSpace="preserve"
                >
                  <path
                    style={{ fill: "#F0EAF9" }}
                    d="M42.667,0.003h383.996c23.564,0,42.667,19.103,42.667,42.667v341.336
	c0,23.564-19.103,42.667-42.667,42.667H42.667C19.103,426.673,0,407.57,0,384.006V42.67C0,19.106,19.103,0.003,42.667,0.003z"
                  ></path>
                  <path
                    style={{ fill: "#E1D5F3" }}
                    d="M21.333,85.34h426.664c11.782,0,21.333,9.551,21.333,21.333v0.001
	c0,11.782-9.551,21.333-21.333,21.333H21.333C9.551,128.007,0,118.456,0,106.674v-0.001C0,94.891,9.551,85.34,21.333,85.34z"
                  ></path>
                  <path
                    style={{ fill: "#5628B4" }}
                    d="M42.667,0.003h384c23.564,0,42.667,19.103,42.667,42.667c0,0,0,0,0,0v64l0,0H0l0,0v-64
	C0,19.106,19.103,0.003,42.667,0.003L42.667,0.003L42.667,0.003z"
                  ></path>
                  <path
                    style={{ fill: "#6F48BF" }}
                    d="M42.667,0.003h384.004c11.782,0,21.333,9.551,21.333,21.333v0.001
	c0,11.782-9.551,21.333-21.333,21.333H42.667c-11.782,0-21.333-9.551-21.333-21.333v-0.001C21.334,9.554,30.885,0.003,42.667,0.003z
	"
                  ></path>
                  <path
                    style={{ fill: "#421198" }}
                    d="M32.001,85.34h405.336c5.891,0,10.667,4.776,10.667,10.667v0c0,5.891-4.776,10.667-10.667,10.667
	H32.001c-5.891,0-10.667-4.776-10.667-10.667v0C21.334,90.116,26.11,85.34,32.001,85.34z"
                  ></path>
                  <path
                    style={{ fill: "#D9C9EF" }}
                    d="M458.667,149.337c-5.891,0-10.667,4.775-10.667,10.666c0,0,0,0,0,0.001v224
	c0,11.782-9.551,21.333-21.333,21.333H309.334c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667h117.333
	c23.564,0,42.666-19.103,42.667-42.667v-224C469.334,154.112,464.558,149.337,458.667,149.337
	C458.667,149.337,458.667,149.337,458.667,149.337L458.667,149.337z"
                  ></path>
                  <path
                    style={{ fill: "#DFD2F2" }}
                    d="M10.667,192L10.667,192c5.891,0,10.667,4.776,10.667,10.667v170.666
	c0,5.891-4.776,10.667-10.667,10.667l0,0C4.776,384,0,379.224,0,373.333V202.667C0,196.776,4.776,192,10.667,192z"
                  ></path>
                  <circle
                    style={{ fill: "#F7B236" }}
                    cx="53.337"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <circle
                    style={{ fill: "#B39DDD" }}
                    cx="95.997"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <circle
                    style={{ fill: "#D80E70" }}
                    cx="138.667"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <g>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M405.334,298.67H192c-11.782,0-21.333-9.551-21.333-21.333V170.673
		c0-11.782,9.551-21.333,21.333-21.333h213.334c11.782,0,21.333,9.551,21.333,21.333v106.664
		C426.667,289.119,417.116,298.67,405.334,298.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M106.664,234.67H64c-11.782,0-21.333-9.551-21.333-21.333v-42.664
		c0-11.782,9.551-21.333,21.333-21.333h42.664c11.782,0,21.333,9.551,21.333,21.333v42.664
		C127.997,225.119,118.446,234.67,106.664,234.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M106.664,384H64c-11.782,0-21.333-9.551-21.333-21.333v-42.664c0-11.782,9.551-21.333,21.333-21.333
		h42.664c11.782,0,21.333,9.551,21.333,21.333v42.664C127.997,374.449,118.446,384,106.664,384z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M181.334,320h63.996c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-63.996c-5.891,0-10.667-4.776-10.667-10.667l0,0C170.667,324.776,175.443,320,181.334,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M394.664,320h21.333c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-21.333c-5.891,0-10.667-4.776-10.667-10.667l0,0C383.997,324.776,388.773,320,394.664,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M288.004,320H352c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-63.996c-5.891,0-10.667-4.776-10.667-10.667l0,0C277.337,324.776,282.113,320,288.004,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M181.334,362.67h85.336c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-85.336c-5.891,0-10.667-4.776-10.667-10.667l0,0C170.667,367.446,175.443,362.67,181.334,362.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M309.334,362.67h21.333c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-21.333c-5.891,0-10.667-4.776-10.667-10.667l0,0C298.667,367.446,303.443,362.67,309.334,362.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M117.33,277.34H53.334c-5.891,0-10.667-4.776-10.667-10.667l0,0c0-5.891,4.776-10.667,10.667-10.667
		h63.996c5.891,0,10.667,4.776,10.667,10.667l0,0C127.997,272.564,123.221,277.34,117.33,277.34z"
                    ></path>
                  </g>
                  <path
                    style={{ fill: "#F7B236" }}
                    d="M234.667,320.003h-21.331c-58.91,0-106.667,47.756-106.667,106.667c0,0.001,0,0.001,0,0.002v0.565
	c0.076,11.653-10.209,20.767-21.862,20.767h-10.14c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667l0,0
	h74.667C208.244,469.337,256,421.58,256,362.67v-42.667H234.667z"
                  ></path>
                  <path
                    style={{ fill: "#F58B04" }}
                    d="M256,362.67v-42.667h-21.333c0,58.91-47.756,106.667-106.667,106.667H106.67v0.566
	c0.076,11.652-10.21,20.767-21.862,20.767H74.667c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667h74.667
	C208.244,469.336,256,421.58,256,362.67L256,362.67z"
                  ></path>
                  <path
                    style={{ fill: "#D80E70" }}
                    d="M462.184,113.82c-6.537-6.537-16.207-8.82-24.977-5.896h0
	c-75.189,26.567-137.135,81.272-172.798,152.597L256,277.337v42.667h42.667l16.816-8.408
	c71.325-35.663,126.03-97.61,152.597-172.798v0C471.004,130.026,468.721,120.357,462.184,113.82L462.184,113.82z"
                  ></path>
                  <ellipse
                    transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 431.8294 605.5635)"
                    style={{ fill: "#E976AF" }}
                    cx="341.331"
                    cy="213.347"
                    rx="21.333"
                    ry="56.757"
                  ></ellipse>
                  <path
                    style={{ fill: "#A60068" }}
                    d="M437.206,107.923c-3.693,1.305-7.198,2.986-10.826,4.424C423.602,207.998,351.052,287.119,256,298.16
	v21.844h42.667l16.816-8.409c71.325-35.663,126.03-97.61,152.598-172.798c4.262-12.789-2.651-26.612-15.44-30.874
	C447.631,106.253,442.216,106.253,437.206,107.923L437.206,107.923z"
                  ></path>
                  <path
                    style={{ fill: "#5628B4" }}
                    d="M234.667,320.003L256,341.337l42.667-21.334L256,277.337L234.667,320.003z"
                  ></path>
                  <path
                    style={{ fill: "#420F9B" }}
                    d="M246.199,331.535l9.801,9.801l42.667-21.333l-20.468-20.468c-8.837-8.837-23.163-8.837-32,0
	c-1.72,1.72-3.151,3.705-4.239,5.881v0C237.605,314.128,239.312,324.649,246.199,331.535L246.199,331.535z"
                  ></path>
                  <ellipse
                    transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 10.0251 751.4755)"
                    style={{ fill: "#FBDA9E" }}
                    cx="160.648"
                    cy="373.661"
                    rx="16.617"
                    ry="42.44"
                  ></ellipse>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </div>
              <p className="text-[#8c8c8c] font-semibold text-md pt-6">
                We offer{" "}
                <span className="text-[#006cff]">professional photography</span>{" "}
                and <span className="text-[#006cff]">video production</span> for
                your orders.
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-start mt-10">
          <div className="w-1/3">
            <div className="px-4">
              <div className="py-4">
                <svg
                  version="1.1"
                  id="fi_922699"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 469.34 469.34"
                  style={{
                    enableBackground: "new 0 0 469.34 469.34",
                    height: "50px",
                  }}
                  xmlSpace="preserve"
                >
                  <path
                    style={{ fill: "#F0EAF9" }}
                    d="M42.667,0.003h383.996c23.564,0,42.667,19.103,42.667,42.667v341.336
	c0,23.564-19.103,42.667-42.667,42.667H42.667C19.103,426.673,0,407.57,0,384.006V42.67C0,19.106,19.103,0.003,42.667,0.003z"
                  ></path>
                  <path
                    style={{ fill: "#E1D5F3" }}
                    d="M21.333,85.34h426.664c11.782,0,21.333,9.551,21.333,21.333v0.001
	c0,11.782-9.551,21.333-21.333,21.333H21.333C9.551,128.007,0,118.456,0,106.674v-0.001C0,94.891,9.551,85.34,21.333,85.34z"
                  ></path>
                  <path
                    style={{ fill: "#5628B4" }}
                    d="M42.667,0.003h384c23.564,0,42.667,19.103,42.667,42.667c0,0,0,0,0,0v64l0,0H0l0,0v-64
	C0,19.106,19.103,0.003,42.667,0.003L42.667,0.003L42.667,0.003z"
                  ></path>
                  <path
                    style={{ fill: "#6F48BF" }}
                    d="M42.667,0.003h384.004c11.782,0,21.333,9.551,21.333,21.333v0.001
	c0,11.782-9.551,21.333-21.333,21.333H42.667c-11.782,0-21.333-9.551-21.333-21.333v-0.001C21.334,9.554,30.885,0.003,42.667,0.003z
	"
                  ></path>
                  <path
                    style={{ fill: "#421198" }}
                    d="M32.001,85.34h405.336c5.891,0,10.667,4.776,10.667,10.667v0c0,5.891-4.776,10.667-10.667,10.667
	H32.001c-5.891,0-10.667-4.776-10.667-10.667v0C21.334,90.116,26.11,85.34,32.001,85.34z"
                  ></path>
                  <path
                    style={{ fill: "#D9C9EF" }}
                    d="M458.667,149.337c-5.891,0-10.667,4.775-10.667,10.666c0,0,0,0,0,0.001v224
	c0,11.782-9.551,21.333-21.333,21.333H309.334c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667h117.333
	c23.564,0,42.666-19.103,42.667-42.667v-224C469.334,154.112,464.558,149.337,458.667,149.337
	C458.667,149.337,458.667,149.337,458.667,149.337L458.667,149.337z"
                  ></path>
                  <path
                    style={{ fill: "#DFD2F2" }}
                    d="M10.667,192L10.667,192c5.891,0,10.667,4.776,10.667,10.667v170.666
	c0,5.891-4.776,10.667-10.667,10.667l0,0C4.776,384,0,379.224,0,373.333V202.667C0,196.776,4.776,192,10.667,192z"
                  ></path>
                  <circle
                    style={{ fill: "#F7B236" }}
                    cx="53.337"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <circle
                    style={{ fill: "#B39DDD" }}
                    cx="95.997"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <circle
                    style={{ fill: "#D80E70" }}
                    cx="138.667"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <g>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M405.334,298.67H192c-11.782,0-21.333-9.551-21.333-21.333V170.673
		c0-11.782,9.551-21.333,21.333-21.333h213.334c11.782,0,21.333,9.551,21.333,21.333v106.664
		C426.667,289.119,417.116,298.67,405.334,298.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M106.664,234.67H64c-11.782,0-21.333-9.551-21.333-21.333v-42.664
		c0-11.782,9.551-21.333,21.333-21.333h42.664c11.782,0,21.333,9.551,21.333,21.333v42.664
		C127.997,225.119,118.446,234.67,106.664,234.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M106.664,384H64c-11.782,0-21.333-9.551-21.333-21.333v-42.664c0-11.782,9.551-21.333,21.333-21.333
		h42.664c11.782,0,21.333,9.551,21.333,21.333v42.664C127.997,374.449,118.446,384,106.664,384z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M181.334,320h63.996c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-63.996c-5.891,0-10.667-4.776-10.667-10.667l0,0C170.667,324.776,175.443,320,181.334,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M394.664,320h21.333c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-21.333c-5.891,0-10.667-4.776-10.667-10.667l0,0C383.997,324.776,388.773,320,394.664,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M288.004,320H352c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-63.996c-5.891,0-10.667-4.776-10.667-10.667l0,0C277.337,324.776,282.113,320,288.004,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M181.334,362.67h85.336c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-85.336c-5.891,0-10.667-4.776-10.667-10.667l0,0C170.667,367.446,175.443,362.67,181.334,362.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M309.334,362.67h21.333c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-21.333c-5.891,0-10.667-4.776-10.667-10.667l0,0C298.667,367.446,303.443,362.67,309.334,362.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M117.33,277.34H53.334c-5.891,0-10.667-4.776-10.667-10.667l0,0c0-5.891,4.776-10.667,10.667-10.667
		h63.996c5.891,0,10.667,4.776,10.667,10.667l0,0C127.997,272.564,123.221,277.34,117.33,277.34z"
                    ></path>
                  </g>
                  <path
                    style={{ fill: "#F7B236" }}
                    d="M234.667,320.003h-21.331c-58.91,0-106.667,47.756-106.667,106.667c0,0.001,0,0.001,0,0.002v0.565
	c0.076,11.653-10.209,20.767-21.862,20.767h-10.14c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667l0,0
	h74.667C208.244,469.337,256,421.58,256,362.67v-42.667H234.667z"
                  ></path>
                  <path
                    style={{ fill: "#F58B04" }}
                    d="M256,362.67v-42.667h-21.333c0,58.91-47.756,106.667-106.667,106.667H106.67v0.566
	c0.076,11.652-10.21,20.767-21.862,20.767H74.667c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667h74.667
	C208.244,469.336,256,421.58,256,362.67L256,362.67z"
                  ></path>
                  <path
                    style={{ fill: "#D80E70" }}
                    d="M462.184,113.82c-6.537-6.537-16.207-8.82-24.977-5.896h0
	c-75.189,26.567-137.135,81.272-172.798,152.597L256,277.337v42.667h42.667l16.816-8.408
	c71.325-35.663,126.03-97.61,152.597-172.798v0C471.004,130.026,468.721,120.357,462.184,113.82L462.184,113.82z"
                  ></path>
                  <ellipse
                    transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 431.8294 605.5635)"
                    style={{ fill: "#E976AF" }}
                    cx="341.331"
                    cy="213.347"
                    rx="21.333"
                    ry="56.757"
                  ></ellipse>
                  <path
                    style={{ fill: "#A60068" }}
                    d="M437.206,107.923c-3.693,1.305-7.198,2.986-10.826,4.424C423.602,207.998,351.052,287.119,256,298.16
	v21.844h42.667l16.816-8.409c71.325-35.663,126.03-97.61,152.598-172.798c4.262-12.789-2.651-26.612-15.44-30.874
	C447.631,106.253,442.216,106.253,437.206,107.923L437.206,107.923z"
                  ></path>
                  <path
                    style={{ fill: "#5628B4" }}
                    d="M234.667,320.003L256,341.337l42.667-21.334L256,277.337L234.667,320.003z"
                  ></path>
                  <path
                    style={{ fill: "#420F9B" }}
                    d="M246.199,331.535l9.801,9.801l42.667-21.333l-20.468-20.468c-8.837-8.837-23.163-8.837-32,0
	c-1.72,1.72-3.151,3.705-4.239,5.881v0C237.605,314.128,239.312,324.649,246.199,331.535L246.199,331.535z"
                  ></path>
                  <ellipse
                    transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 10.0251 751.4755)"
                    style={{ fill: "#FBDA9E" }}
                    cx="160.648"
                    cy="373.661"
                    rx="16.617"
                    ry="42.44"
                  ></ellipse>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </div>
              <p className="text-[#8c8c8c] font-semibold text-md pt-6">
                We provide the facility to upload your favourite designs and{" "}
                <span className="text-[#006cff]">analyze the cost benefit</span>{" "}
                with our{" "}
                <span className="text-[#006cff]">AI cost analysis tool.</span>
              </p>
            </div>
          </div>
          <div className="w-1/3">
            <div className="px-4">
              <div className="py-4">
                <svg
                  version="1.1"
                  id="fi_922699"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 469.34 469.34"
                  style={{
                    enableBackground: "new 0 0 469.34 469.34",
                    height: "50px",
                  }}
                  xmlSpace="preserve"
                >
                  <path
                    style={{ fill: "#F0EAF9" }}
                    d="M42.667,0.003h383.996c23.564,0,42.667,19.103,42.667,42.667v341.336
	c0,23.564-19.103,42.667-42.667,42.667H42.667C19.103,426.673,0,407.57,0,384.006V42.67C0,19.106,19.103,0.003,42.667,0.003z"
                  ></path>
                  <path
                    style={{ fill: "#E1D5F3" }}
                    d="M21.333,85.34h426.664c11.782,0,21.333,9.551,21.333,21.333v0.001
	c0,11.782-9.551,21.333-21.333,21.333H21.333C9.551,128.007,0,118.456,0,106.674v-0.001C0,94.891,9.551,85.34,21.333,85.34z"
                  ></path>
                  <path
                    style={{ fill: "#5628B4" }}
                    d="M42.667,0.003h384c23.564,0,42.667,19.103,42.667,42.667c0,0,0,0,0,0v64l0,0H0l0,0v-64
	C0,19.106,19.103,0.003,42.667,0.003L42.667,0.003L42.667,0.003z"
                  ></path>
                  <path
                    style={{ fill: "#6F48BF" }}
                    d="M42.667,0.003h384.004c11.782,0,21.333,9.551,21.333,21.333v0.001
	c0,11.782-9.551,21.333-21.333,21.333H42.667c-11.782,0-21.333-9.551-21.333-21.333v-0.001C21.334,9.554,30.885,0.003,42.667,0.003z
	"
                  ></path>
                  <path
                    style={{ fill: "#421198" }}
                    d="M32.001,85.34h405.336c5.891,0,10.667,4.776,10.667,10.667v0c0,5.891-4.776,10.667-10.667,10.667
	H32.001c-5.891,0-10.667-4.776-10.667-10.667v0C21.334,90.116,26.11,85.34,32.001,85.34z"
                  ></path>
                  <path
                    style={{ fill: "#D9C9EF" }}
                    d="M458.667,149.337c-5.891,0-10.667,4.775-10.667,10.666c0,0,0,0,0,0.001v224
	c0,11.782-9.551,21.333-21.333,21.333H309.334c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667h117.333
	c23.564,0,42.666-19.103,42.667-42.667v-224C469.334,154.112,464.558,149.337,458.667,149.337
	C458.667,149.337,458.667,149.337,458.667,149.337L458.667,149.337z"
                  ></path>
                  <path
                    style={{ fill: "#DFD2F2" }}
                    d="M10.667,192L10.667,192c5.891,0,10.667,4.776,10.667,10.667v170.666
	c0,5.891-4.776,10.667-10.667,10.667l0,0C4.776,384,0,379.224,0,373.333V202.667C0,196.776,4.776,192,10.667,192z"
                  ></path>
                  <circle
                    style={{ fill: "#F7B236" }}
                    cx="53.337"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <circle
                    style={{ fill: "#B39DDD" }}
                    cx="95.997"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <circle
                    style={{ fill: "#D80E70" }}
                    cx="138.667"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <g>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M405.334,298.67H192c-11.782,0-21.333-9.551-21.333-21.333V170.673
		c0-11.782,9.551-21.333,21.333-21.333h213.334c11.782,0,21.333,9.551,21.333,21.333v106.664
		C426.667,289.119,417.116,298.67,405.334,298.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M106.664,234.67H64c-11.782,0-21.333-9.551-21.333-21.333v-42.664
		c0-11.782,9.551-21.333,21.333-21.333h42.664c11.782,0,21.333,9.551,21.333,21.333v42.664
		C127.997,225.119,118.446,234.67,106.664,234.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M106.664,384H64c-11.782,0-21.333-9.551-21.333-21.333v-42.664c0-11.782,9.551-21.333,21.333-21.333
		h42.664c11.782,0,21.333,9.551,21.333,21.333v42.664C127.997,374.449,118.446,384,106.664,384z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M181.334,320h63.996c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-63.996c-5.891,0-10.667-4.776-10.667-10.667l0,0C170.667,324.776,175.443,320,181.334,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M394.664,320h21.333c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-21.333c-5.891,0-10.667-4.776-10.667-10.667l0,0C383.997,324.776,388.773,320,394.664,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M288.004,320H352c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-63.996c-5.891,0-10.667-4.776-10.667-10.667l0,0C277.337,324.776,282.113,320,288.004,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M181.334,362.67h85.336c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-85.336c-5.891,0-10.667-4.776-10.667-10.667l0,0C170.667,367.446,175.443,362.67,181.334,362.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M309.334,362.67h21.333c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-21.333c-5.891,0-10.667-4.776-10.667-10.667l0,0C298.667,367.446,303.443,362.67,309.334,362.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M117.33,277.34H53.334c-5.891,0-10.667-4.776-10.667-10.667l0,0c0-5.891,4.776-10.667,10.667-10.667
		h63.996c5.891,0,10.667,4.776,10.667,10.667l0,0C127.997,272.564,123.221,277.34,117.33,277.34z"
                    ></path>
                  </g>
                  <path
                    style={{ fill: "#F7B236" }}
                    d="M234.667,320.003h-21.331c-58.91,0-106.667,47.756-106.667,106.667c0,0.001,0,0.001,0,0.002v0.565
	c0.076,11.653-10.209,20.767-21.862,20.767h-10.14c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667l0,0
	h74.667C208.244,469.337,256,421.58,256,362.67v-42.667H234.667z"
                  ></path>
                  <path
                    style={{ fill: "#F58B04" }}
                    d="M256,362.67v-42.667h-21.333c0,58.91-47.756,106.667-106.667,106.667H106.67v0.566
	c0.076,11.652-10.21,20.767-21.862,20.767H74.667c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667h74.667
	C208.244,469.336,256,421.58,256,362.67L256,362.67z"
                  ></path>
                  <path
                    style={{ fill: "#D80E70" }}
                    d="M462.184,113.82c-6.537-6.537-16.207-8.82-24.977-5.896h0
	c-75.189,26.567-137.135,81.272-172.798,152.597L256,277.337v42.667h42.667l16.816-8.408
	c71.325-35.663,126.03-97.61,152.597-172.798v0C471.004,130.026,468.721,120.357,462.184,113.82L462.184,113.82z"
                  ></path>
                  <ellipse
                    transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 431.8294 605.5635)"
                    style={{ fill: "#E976AF" }}
                    cx="341.331"
                    cy="213.347"
                    rx="21.333"
                    ry="56.757"
                  ></ellipse>
                  <path
                    style={{ fill: "#A60068" }}
                    d="M437.206,107.923c-3.693,1.305-7.198,2.986-10.826,4.424C423.602,207.998,351.052,287.119,256,298.16
	v21.844h42.667l16.816-8.409c71.325-35.663,126.03-97.61,152.598-172.798c4.262-12.789-2.651-26.612-15.44-30.874
	C447.631,106.253,442.216,106.253,437.206,107.923L437.206,107.923z"
                  ></path>
                  <path
                    style={{ fill: "#5628B4" }}
                    d="M234.667,320.003L256,341.337l42.667-21.334L256,277.337L234.667,320.003z"
                  ></path>
                  <path
                    style={{ fill: "#420F9B" }}
                    d="M246.199,331.535l9.801,9.801l42.667-21.333l-20.468-20.468c-8.837-8.837-23.163-8.837-32,0
	c-1.72,1.72-3.151,3.705-4.239,5.881v0C237.605,314.128,239.312,324.649,246.199,331.535L246.199,331.535z"
                  ></path>
                  <ellipse
                    transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 10.0251 751.4755)"
                    style={{ fill: "#FBDA9E" }}
                    cx="160.648"
                    cy="373.661"
                    rx="16.617"
                    ry="42.44"
                  ></ellipse>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </div>
              <p className="text-[#8c8c8c] font-semibold text-md pt-6">
                We offer
                <span className="text-[#006cff]">
                  flexible production options,
                </span>{" "}
                including branded orders that carry your label or unlabeled
                products for your private labeling needs.
              </p>
            </div>
          </div>
          <div className="w-1/3">
            <div className="px-4">
              <div className="py-4">
                <svg
                  version="1.1"
                  id="fi_922699"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 469.34 469.34"
                  style={{
                    enableBackground: "new 0 0 469.34 469.34",
                    height: "50px",
                  }}
                  xmlSpace="preserve"
                >
                  <path
                    style={{ fill: "#F0EAF9" }}
                    d="M42.667,0.003h383.996c23.564,0,42.667,19.103,42.667,42.667v341.336
	c0,23.564-19.103,42.667-42.667,42.667H42.667C19.103,426.673,0,407.57,0,384.006V42.67C0,19.106,19.103,0.003,42.667,0.003z"
                  ></path>
                  <path
                    style={{ fill: "#E1D5F3" }}
                    d="M21.333,85.34h426.664c11.782,0,21.333,9.551,21.333,21.333v0.001
	c0,11.782-9.551,21.333-21.333,21.333H21.333C9.551,128.007,0,118.456,0,106.674v-0.001C0,94.891,9.551,85.34,21.333,85.34z"
                  ></path>
                  <path
                    style={{ fill: "#5628B4" }}
                    d="M42.667,0.003h384c23.564,0,42.667,19.103,42.667,42.667c0,0,0,0,0,0v64l0,0H0l0,0v-64
	C0,19.106,19.103,0.003,42.667,0.003L42.667,0.003L42.667,0.003z"
                  ></path>
                  <path
                    style={{ fill: "#6F48BF" }}
                    d="M42.667,0.003h384.004c11.782,0,21.333,9.551,21.333,21.333v0.001
	c0,11.782-9.551,21.333-21.333,21.333H42.667c-11.782,0-21.333-9.551-21.333-21.333v-0.001C21.334,9.554,30.885,0.003,42.667,0.003z
	"
                  ></path>
                  <path
                    style={{ fill: "#421198" }}
                    d="M32.001,85.34h405.336c5.891,0,10.667,4.776,10.667,10.667v0c0,5.891-4.776,10.667-10.667,10.667
	H32.001c-5.891,0-10.667-4.776-10.667-10.667v0C21.334,90.116,26.11,85.34,32.001,85.34z"
                  ></path>
                  <path
                    style={{ fill: "#D9C9EF" }}
                    d="M458.667,149.337c-5.891,0-10.667,4.775-10.667,10.666c0,0,0,0,0,0.001v224
	c0,11.782-9.551,21.333-21.333,21.333H309.334c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667h117.333
	c23.564,0,42.666-19.103,42.667-42.667v-224C469.334,154.112,464.558,149.337,458.667,149.337
	C458.667,149.337,458.667,149.337,458.667,149.337L458.667,149.337z"
                  ></path>
                  <path
                    style={{ fill: "#DFD2F2" }}
                    d="M10.667,192L10.667,192c5.891,0,10.667,4.776,10.667,10.667v170.666
	c0,5.891-4.776,10.667-10.667,10.667l0,0C4.776,384,0,379.224,0,373.333V202.667C0,196.776,4.776,192,10.667,192z"
                  ></path>
                  <circle
                    style={{ fill: "#F7B236" }}
                    cx="53.337"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <circle
                    style={{ fill: "#B39DDD" }}
                    cx="95.997"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <circle
                    style={{ fill: "#D80E70" }}
                    cx="138.667"
                    cy="53.34"
                    r="10.667"
                  ></circle>
                  <g>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M405.334,298.67H192c-11.782,0-21.333-9.551-21.333-21.333V170.673
		c0-11.782,9.551-21.333,21.333-21.333h213.334c11.782,0,21.333,9.551,21.333,21.333v106.664
		C426.667,289.119,417.116,298.67,405.334,298.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M106.664,234.67H64c-11.782,0-21.333-9.551-21.333-21.333v-42.664
		c0-11.782,9.551-21.333,21.333-21.333h42.664c11.782,0,21.333,9.551,21.333,21.333v42.664
		C127.997,225.119,118.446,234.67,106.664,234.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M106.664,384H64c-11.782,0-21.333-9.551-21.333-21.333v-42.664c0-11.782,9.551-21.333,21.333-21.333
		h42.664c11.782,0,21.333,9.551,21.333,21.333v42.664C127.997,374.449,118.446,384,106.664,384z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M181.334,320h63.996c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-63.996c-5.891,0-10.667-4.776-10.667-10.667l0,0C170.667,324.776,175.443,320,181.334,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M394.664,320h21.333c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-21.333c-5.891,0-10.667-4.776-10.667-10.667l0,0C383.997,324.776,388.773,320,394.664,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M288.004,320H352c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-63.996c-5.891,0-10.667-4.776-10.667-10.667l0,0C277.337,324.776,282.113,320,288.004,320z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M181.334,362.67h85.336c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-85.336c-5.891,0-10.667-4.776-10.667-10.667l0,0C170.667,367.446,175.443,362.67,181.334,362.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M309.334,362.67h21.333c5.891,0,10.667,4.776,10.667,10.667l0,0c0,5.891-4.776,10.667-10.667,10.667
		h-21.333c-5.891,0-10.667-4.776-10.667-10.667l0,0C298.667,367.446,303.443,362.67,309.334,362.67z"
                    ></path>
                    <path
                      style={{ fill: "#B39DDD" }}
                      d="M117.33,277.34H53.334c-5.891,0-10.667-4.776-10.667-10.667l0,0c0-5.891,4.776-10.667,10.667-10.667
		h63.996c5.891,0,10.667,4.776,10.667,10.667l0,0C127.997,272.564,123.221,277.34,117.33,277.34z"
                    ></path>
                  </g>
                  <path
                    style={{ fill: "#F7B236" }}
                    d="M234.667,320.003h-21.331c-58.91,0-106.667,47.756-106.667,106.667c0,0.001,0,0.001,0,0.002v0.565
	c0.076,11.653-10.209,20.767-21.862,20.767h-10.14c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667l0,0
	h74.667C208.244,469.337,256,421.58,256,362.67v-42.667H234.667z"
                  ></path>
                  <path
                    style={{ fill: "#F58B04" }}
                    d="M256,362.67v-42.667h-21.333c0,58.91-47.756,106.667-106.667,106.667H106.67v0.566
	c0.076,11.652-10.21,20.767-21.862,20.767H74.667c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667h74.667
	C208.244,469.336,256,421.58,256,362.67L256,362.67z"
                  ></path>
                  <path
                    style={{ fill: "#D80E70" }}
                    d="M462.184,113.82c-6.537-6.537-16.207-8.82-24.977-5.896h0
	c-75.189,26.567-137.135,81.272-172.798,152.597L256,277.337v42.667h42.667l16.816-8.408
	c71.325-35.663,126.03-97.61,152.597-172.798v0C471.004,130.026,468.721,120.357,462.184,113.82L462.184,113.82z"
                  ></path>
                  <ellipse
                    transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 431.8294 605.5635)"
                    style={{ fill: "#E976AF" }}
                    cx="341.331"
                    cy="213.347"
                    rx="21.333"
                    ry="56.757"
                  ></ellipse>
                  <path
                    style={{ fill: "#A60068" }}
                    d="M437.206,107.923c-3.693,1.305-7.198,2.986-10.826,4.424C423.602,207.998,351.052,287.119,256,298.16
	v21.844h42.667l16.816-8.409c71.325-35.663,126.03-97.61,152.598-172.798c4.262-12.789-2.651-26.612-15.44-30.874
	C447.631,106.253,442.216,106.253,437.206,107.923L437.206,107.923z"
                  ></path>
                  <path
                    style={{ fill: "#5628B4" }}
                    d="M234.667,320.003L256,341.337l42.667-21.334L256,277.337L234.667,320.003z"
                  ></path>
                  <path
                    style={{ fill: "#420F9B" }}
                    d="M246.199,331.535l9.801,9.801l42.667-21.333l-20.468-20.468c-8.837-8.837-23.163-8.837-32,0
	c-1.72,1.72-3.151,3.705-4.239,5.881v0C237.605,314.128,239.312,324.649,246.199,331.535L246.199,331.535z"
                  ></path>
                  <ellipse
                    transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 10.0251 751.4755)"
                    style={{ fill: "#FBDA9E" }}
                    cx="160.648"
                    cy="373.661"
                    rx="16.617"
                    ry="42.44"
                  ></ellipse>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </div>
              <p className="text-[#8c8c8c] font-semibold text-md pt-6">
                You can choose to have
                <span className="text-[#006cff]">
                  customized branded packaging
                </span>{" "}
                or use our standard packaging.
              </p>
            </div>
          </div>
        </div>
        <h6 className="flex justify-end mt-10">
          <a className="font-bold text-sm w-1/4 px-4" href="/">
            LEARN MORE
          </a>
        </h6>
      </div>

      {/* videos */}
      <div className=" bg-black py-32 mt-14">
        <div className="flex justify-center">
          <video
            key="1"
            // src="./videos/vd-big.mp4"
            alt="Slide Video"
            className="h-[600px]"
            autoPlay
            loop
            muted
          />
        </div>

        <div className="flex justify-center items-center my-6">
          <div className="text-center">
            <button className="border font-semibold text-sm mt-6 bg-gradient-to-r from-[#0cb4ce] to-[#5947ff] text-white rounded-full px-10 py-3">
              CREATE YOUR FREE PLATFORM NOW
            </button>
            <p className="text-white flex pt-2">
              <TiTick size={22} color="#006cff" />
              No credit card
              <TiTick size={22} color="#006cff" />
              1 minute setup
              <TiTick size={22} color="#006cff" />
              Fully functional
            </p>
          </div>
        </div>
      </div>

      <div className="bg-black">
        <div className="container w-3/4 mx-auto overflow-hidden mt-2 bg-black">
          <div
            className="flex transition-transform ease-out duration-500"
            style={{
              transform: `translateX(-${curr * 320}px)`,
              // transform: `translateX(${curr}px)`,
              width: `${slides.length * 100}%`,
            }}
          >
            {slides2?.map((slide, index) => (
              <div key={index} className="flex m-2 overflow-hidden">
                <video
                  key="1"
                  src={slide?.vid}
                  alt="Slide Video"
                  className="hover:opacity-50 hover:scale-125 transition-all duration-75 h-[500px]"
                  autoPlay
                  loop
                  muted
                />
              </div>
            ))}
          </div>
          <div className="relative -top-[300px] inset-0 flex items-center justify-between p-4">
            <button
              onClick={() => {
                setCurr((prev) => (prev === 0 ? slides2.length - 1 : prev - 1));
              }}
              className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
            >
              <ChevronLeft />
            </button>
            <button
              onClick={() => {
                setCurr((next) => (next === slides2.length - 1 ? 0 : next + 1));
              }}
              className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
            >
              <ChevronRight />
            </button>
          </div>
          <div className="flex items-center justify-center">
            {slides2?.map((slide, index) => (
              <div
                key={index}
                className={`${
                  slide.id === curr
                    ? "bg-slate-400 h-3 w-3"
                    : "bg-slate-600 h-2 w-2"
                } mx-1 border rounded-full`}
              ></div>
            ))}
          </div>
        </div>
      </div>

      {/* 3 GIF Sections */}
      <div className="flex justify-between items-center mx-6">
        <div className="w-1/3 p-8 mt-32">
          <h6 className="text-black text-3xl text-center">
            Setup your account
          </h6>
          <p className="text-center text-[#8c8c8c] mt-6">
            It takes less than 1 minute to{" "}
            <span className="text-[#006cff]">setup your account</span>. No
            credit card or commitment required. You will get a{" "}
            <span className="text-[#006cff]">fully functional</span> platform,
            entirely customized with your{" "}
            <span className="text-[#006cff]">brand logos</span>.
          </p>
          <img
            src="https://mtofactory.com/wp-content/uploads/2019/11/create2_v2.gif"
            width="700"
            height="490"
            alt=""
          />
        </div>
        <div className="w-1/3 p-8 mt-32">
          <h6 className="text-black text-3xl text-center">
            Setup your account
          </h6>
          <p className="text-center text-[#8c8c8c] mt-6">
            It takes less than 1 minute to setup your account. No credit card or
            commitment required. You will get a fully functional platform,
            entirely customized with your brand logos.
          </p>
          <img
            src="https://mtofactory.com/wp-content/uploads/2019/11/design_step4.gif"
            width="700"
            height="490"
            alt=""
          />
        </div>
        <div className="w-1/3 p-8 mt-32">
          <h6 className="text-black text-3xl text-center">
            Setup your account
          </h6>
          <p className="text-center text-[#8c8c8c] mt-6">
            It takes less than 1 minute to setup your account. No credit card or
            commitment required. You will get a fully functional platform,
            entirely customized with your brand logos.
          </p>
          <img
            src="https://mtofactory.com/wp-content/uploads/2019/11/CHECKOUT3.2019-11-27-13_47_21.gif"
            width="700"
            height="490"
            alt=""
          />
        </div>
      </div>

      {/* 3 Icon sections */}
      <div className="flex justify-between items-center">
        <div className="w-1/3 p-8 mt-32 bg-black">
          <div className="flex justify-center mt-28">
            <img
              src="https://mtofactory.com/wp-content/uploads/2019/11/conveyor-1.png"
              width="70"
              height="70"
              alt=""
            />
          </div>
          <h6 className="text-white text-3xl text-center my-6">
            Infrastructure
          </h6>
          <p className="text-center text-white mb-28">
            We grant you access to our streamlined and scalable production and
            logistics infrastructure, 100% made in Spain and capable to
            manufacture and deliver thousands of pairs a month
          </p>
        </div>
        <div className="w-1/3 p-8 mt-32 welcome-background">
          <div className="flex justify-center mt-28">
            <img
              src="https://mtofactory.com/wp-content/uploads/2019/11/outsourcing-1.png"
              width="70"
              height="70"
              alt=""
            />
          </div>
          <h6 className="text-white text-3xl text-center my-6">Suppliers</h6>
          <p className="text-center text-white mb-28">
            We take care of all the supplier-hassle for you. We source every
            material, accessory, and custom part, and negotiate volume-based
            prices with them, without overhead costs.
          </p>
        </div>
        <div className="w-1/3 p-8 mt-32 bg-slate-100">
          <div className="flex justify-center mt-28">
            <img
              src="https://mtofactory.com/wp-content/uploads/2019/11/warehouse.png"
              width="70"
              height="70"
              alt=""
            />
          </div>
          <h6 className="text-black text-3xl text-center my-6">
            Stock Keeping
          </h6>
          <p className="text-center text-black mb-28">
            We grant you access to our streamlined and scalable production and
            logistics infrastructure, 100% made in Spain and capable to
            manufacture and deliver thousands of pairs a month
          </p>
        </div>
      </div>

      {/* ready? */}
      <div className="text-center bg-slate-100 py-40">
        <h6 className="text-black text-4xl my-6">Ready? Get Started</h6>
        <p className="text-[#8c8c8c] text-lg">
          Create an account, upload your logos, and start designing your own
          shoes!
        </p>
        <div className="flex justify-center">
          <button className="font-semibold text-sm mt-11 bg-gradient-to-r from-[#0cb4ce] to-[#5947ff] text-white rounded-full px-10 py-3 flex">
            <FaExternalLinkAlt />
            &nbsp;&nbsp;CREATE FREE ACCOUNT
          </button>
        </div>
      </div>
    </>
  );
}

export default Home;
