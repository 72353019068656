import React from "react";
import "./Loaders.css";

function Loader2() {
  return (
    <>
      <div className="loader-container2">
        <img src="/images/btn-white.gif" alt="" />
        <h6 className="font-size2 mt-2">Loading...</h6>
      </div>
    </>
  );
}

export default Loader2;
