import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import SideBar from "../../components/Sidebar/SideBar";
import CheckAuth from "../../components/ServiceFunctions/CheckAuth";
import ProductListing from "../../admin/ProductListing/ProductListing";
import Upper from "../../admin/MaterialCost/Upper";
import Sole from "../../admin/MaterialCost/Sole";

function Dashboard() {
  const { isAuthenticated, type } = CheckAuth();
  const navigate = useNavigate();
  const [menuItem, setMenuItem] = useState("");
  const [activeImage, setActiveImage] = useState(1);
  const [textVisible, setTextVisible] = useState(true);

  useEffect(() => {
    setTextVisible(false);
    const timer = setTimeout(() => {
      setTextVisible(true);
    }, 500); // Match the duration of the fade-out (adjust as needed)

    return () => clearTimeout(timer);
  }, [activeImage]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveImage((prev) => (prev === 3 ? 1 : prev + 1));
    }, 3000); // Change every 8 seconds
    return () => clearInterval(interval);
  }, []);

  const handleImageClick = (imageIndex) => {
    setActiveImage(imageIndex);
  };

  const textForImage = {
    1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet",
    2: "Consectetur adipiscing elit. Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet, ",
    3: "Dolor sit amet, consectetur adipiscing elit. Lorem ipsum Lorem ipsum dolor sit amet",
  };

  const titleForImage = {
    1: "Customize 3D",
    2: "eCommerce API",
    3: "Production Stages",
  };

  const productItems = [
    "Boots",
    "Oxford",
    "Brogue",
    "Monk",
    "Shipon",
    "Sneakers",
    "Sandals",
  ];

  return (
    <div>
      <Header />
      <div className="after-header h-screen">
        <div className="after-subheader bg-grey-800 flex items-start justify-between relative bg-[#F5F9FF] overflow-x-hidden">
          <SideBar
            onSelectMenuItem={(i) => {
              setMenuItem(i);
            }}
          />

          {/* Main Content */}
          <div className="w-full lg:min-w-[70%] 2xl:min-w-[70%] p-2 ">
            <div className="w-full border transparent bg-white rounded-xl shadow-md p-4 h-full lg:h-[calc(100vh-7rem)]">
              {type !== "" ? (
                <>
                  ---{menuItem}---
                  {menuItem === "Upper" && <Upper />}
                  {productItems.includes(menuItem) && (
                    <ProductListing menuitem={menuItem} />
                  )}
                  {menuItem === "Sole" && <Sole />}
                </>
              ) : (
                <>
                  <div className="flex justify-center items-center h-full overflow-hidden">
                    <div className="flex flex-col justify-center items-center h-full overflow-hidden md:scale-90 2xl:scale-100">
                      <div
                        className={`max-w-2xl ${
                          textVisible ? "fade-in " : "fade-out "
                        }`}
                      >
                        <p className="text-lg md:text-2xl text-yellow-400 text-center font-bold pb-2">
                          {titleForImage[activeImage]}
                        </p>
                        <p className="text-sm md:text-lg font-semibold text-center ">
                          {textForImage[activeImage]}
                        </p>
                      </div>
                      <div className="bg-white border-b-8 border-yellow-400 w-full overflow-hidden 2xl:px-20">
                        <div className="object-cover w-full h-96 md:h-full flex justify-center gap-x-10 overflow-hidden">
                          {/* image 1 */}
                          <img
                            className={`object-cover  w-48 xl:w-64 max-md:left-48 max-md:relative transition-all duration-500 ease-in-out cursor-pointer ${
                              activeImage === 1
                                ? "translate-y-12 opacity-100 z-20 "
                                : "translate-y-40 opacity-50 md:opacity-40 z-0 "
                            }`}
                            src="/images/1.png"
                            alt=""
                            onClick={() => handleImageClick(1)}
                          />
                          {/* image 2 */}
                          <img
                            className={`object-cover w-48 xl:w-64 max-md:relative transition-all duration-500 ease-in-out cursor-pointer ${
                              activeImage === 2
                                ? "translate-y-12 opacity-100  z-20"
                                : "translate-y-40 md:opacity-40 z-10"
                            }`}
                            src="/images/2.png"
                            alt=""
                            onClick={() => handleImageClick(2)}
                          />
                          {/* image 3 */}
                          <img
                            className={`object-cover w-48 xl:w-64 max-md:right-48 max-md:relative transition-all duration-500 ease-in-out cursor-pointer ${
                              activeImage === 3
                                ? "translate-y-12 opacity-100   z-20"
                                : "translate-y-40 opacity-50 md:opacity-40  z-0"
                            }`}
                            src="/images/3.png"
                            alt=""
                            onClick={() => handleImageClick(3)}
                          />
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          navigate("#", {
                            state: "register",
                          });
                        }}
                        class="button-register max-md:mt-6 md:mt-10 border-4 border-gray-300 hover:border-yellow-200 duration-300 max-md:scale-75"
                      >
                        <span class="button__icon-wrapper">
                          <svg
                            viewBox="0 0 14 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="button__icon-svg"
                            width="10"
                          >
                            <path
                              d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                              fill="currentColor"
                            ></path>
                          </svg>

                          <svg
                            viewBox="0 0 14 15"
                            fill="none"
                            width="10"
                            xmlns="http://www.w3.org/2000/svg"
                            class="button__icon-svg button__icon-svg--copy"
                          >
                            <path
                              d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                        Get Started
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
