import React, { useEffect, useRef } from "react";
import secureLocalStorage from "react-secure-storage";
import { FaInstagram } from "react-icons/fa";

function Footer() {
  const inactiveTimeRef = useRef(0);
  const intervalRef = useRef(null);

  // logout function
  const logout = () => {
    const refreshToken = getCookie("refresh_token");
    secureLocalStorage.clear();
    if (refreshToken) {
      fetch(`http://127.0.0.1:8000/api/logout/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refresh_token: refreshToken,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to logout");
          }
          return response.json();
        })
        .then((response) => {
          document.cookie =
            "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie =
            "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          if (response.error === "Logged out successfully") {
            const currentUrl = new URL(window.location.href);
            if (currentUrl.pathname === "/") {
              window.location.reload();
            } else {
              window.location.href = "/";
            }
          } else {
            alert(response.error);
            window.location.href = "/userLost";
          }
        })
        .catch((error) => {
          // console.error("Error during logout:", error);
        });
    } else {
      alert("refresh token not provided or connection failure");
      window.location.href = "/accessDenied";
    }
  };
  // logout function

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  };

  const isTokenExpired = (token) => {
    try {
      const payload = JSON.parse(atob(token.split(".")[1]));
      const expiry = payload.exp * 1000;
      return Date.now() > expiry;
    } catch (error) {
      return true;
    }
  };

  const fetchProtectedData = () => {
    const accessToken = getCookie("access_token");
    const refreshToken = getCookie("refresh_token");

    if (!accessToken || !refreshToken) {
      return;
    }
    if (isTokenExpired(accessToken) === false) {
      fetch(`https://api-scdndestates.com/api/refresh_access_token/`, {
        method: "POST",
        body: JSON.stringify({
          refresh_token: refreshToken,
          access_token: accessToken,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch protected data");
          }
          return response.json();
        })
        .then((data) => {
          setTokens(data.new_access_token);
        })
        .catch((error) => {
          // console.error("Error fetching protected data:", error);
        });
    }
  };

  const setTokens = (accessToken) => {
    const access_token_expires = new Date();

    // Set access token to expire after 2 minutes
    access_token_expires.setTime(
      access_token_expires.getTime() + 60 * 60 * 1000
    );

    document.cookie = `access_token=${accessToken};expires=${access_token_expires.toUTCString()};path=/`;
    secureLocalStorage.setItem("access_token_time", access_token_expires);
  };

  // refreshing access token

  // refreshing access token
  const checkAndRefreshToken = () => {
    const storedTokenTime = secureLocalStorage.getItem("access_token_time");
    const currentTime = new Date();
    let timeDifference = 0;

    if (storedTokenTime) {
      timeDifference = currentTime - new Date(storedTokenTime) + 55 * 60 * 1000;
      if (timeDifference >= 50 * 60 * 1000 && timeDifference < 55 * 60 * 1000) {
        fetchProtectedData();
      } else if (timeDifference >= 55 * 60 * 1000) {
        logout();
      }
    }
  };

  useEffect(() => {
    // Set up the interval to check for refresh the token
    intervalRef.current = setInterval(() => {
      checkAndRefreshToken();
      inactiveTimeRef.current += 3000;
    }, 3000000); // Check every 5 min

    // Cleanup function
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <>
      <div className="bg-black py-8 flex justify-around text-white">
        <span className="text-sm">
          © 2024 BUWCH Lifestyles. All rights reserved
        </span>
        <span>
          <FaInstagram size={20} />
        </span>
      </div>
    </>
  );
}

export default Footer;
